<template>
    <div class="space-y-4 grid grid-cols-1 gap-x-6 sm:grid-cols-6 mb-4 ">
        <div class="sm:col-span-3 mt-4" >
            <div class="my-3 border-b border-slate-200 dark:border-slate-700">
                <ul class="text-sm font-medium flex flex-nowrap -mx-4 sm:-mx-6 lg:-mx-8 overflow-x-scroll no-scrollbar">
                    <li
                    v-for="(section, index) in sections"
                    :key="section.id"
                    @click="selectedSection = section.id, selectedSectionIndex = index, this.$emit('update:section', section.id)"
                    class="pb-3 mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8"
                    >
                    <a
                        class="whitespace-nowrap dark:hover:text-slate-300"
                        :class="selectedSection == section.id ? 'text-indigo-500 hover:text-indigo-600' : 'text-slate-500 hover:text-slate-600'"
                    >
                        {{ section.name }}
                    </a>
                    </li>
                </ul>
            </div>
            <div v-for="(value, key) in sections[selectedSectionIndex].inputConfig" :key="sections[selectedSectionIndex].inputConfig[key]?.label" :class="sections[selectedSectionIndex].inputConfig[key]?.show || false ? sections[selectedSectionIndex].inputConfig[key].class : 'hidden'">
                <label class="block text-sm font-medium mb-1">
                    {{ $t(sections[selectedSectionIndex].inputConfig[key]?.label || key) }}:
                </label>
                <input v-if="sections[selectedSectionIndex].inputConfig[key].type !== 'options'"
                    :type="sections[selectedSectionIndex].inputConfig[key].type"
                    :step="sections[selectedSectionIndex].inputConfig[key].step"
                    :min="0"
                    :placeholder="sections[selectedSectionIndex].inputConfig[key].placeholder"
                    class="form-input w-full"
                    v-model="formData[selectedSection][key]" :disabled="sections[selectedSectionIndex].inputConfig[key].disabled"
                />
                <DropdownFull v-else
                :disable-inputs="sections[selectedSectionIndex].inputConfig[key].disabled"
                :propname="sections[selectedSectionIndex].inputConfig[key]?.propname"
                :dropdownOptions="sections[selectedSectionIndex].inputConfig[key].options" 
                :init-value="sections[selectedSectionIndex].inputConfig[key].options.findIndex(c => c[sections[selectedSectionIndex].inputConfig[key].searchKey] == formData[selectedSection][key])"
                :model="formData[selectedSection][key]" @update:model="updateDropDown($event, key, sections[selectedSectionIndex].inputConfig[key].searchKey)" />
            </div>
        </div>
    </div>
</template>

<script>
    import {statesApiMarket} from '@/utils/statesApiMarket.js';
    import DropdownFull from '@/components/DropdownFull.vue';
    export default {
        name: 'SearchProspectForm',
        components: {
            DropdownFull,
        },
        props:{
            selectedSection_: String,
            formData_: {
                type: Object,
            },
        },
        data() {
            const sexes = [
                {'name': 'Masculino', value: 'M'},
                {'name': 'Femenino', value: 'F'},
            ]
            return {
                selectedSectionIndex: 0,
                formData: this.formData_,
                sections: [
                    {
                        id: 'searchByCurp',
                        name: 'CURP',
                        inputConfig: {
                            curp: { type: 'text', step: null, placeholder: 'ROGE820204MTSDRR08', label: 'labels.curp', show: true, class: 'sm:col-span-6 mt-4', options: null, propname: null, searchKey: null, disabled: false },
                        }
                    },
                    {
                        id: 'searchByNss',
                        name: 'NSS',
                        inputConfig: {
                            nss: { type: 'text', step: null, placeholder: '09008277585', label: 'labels.nss', show: true, class: 'sm:col-span-6 mt-4', options: null, propname: null, searchKey: null, disabled: false },
                        }
                    },
                    {
                        id: 'searchByData',
                        name: 'DATOS',
                        inputConfig: {
                            name: { type: 'text', step: null, placeholder: 'john', label: 'labels.name', show: true, class: 'sm:col-span-6 mt-4', options: null, propname: null, searchKey: null, disabled: false },
                            fatherLastName: { type: 'text', step: null, placeholder: 'doe', label: 'labels.fatherLastName', show: true, class: 'sm:col-span-6 mt-4', options: null, propname: null, searchKey: null, disabled: false },
                            motherLastName: { type: 'text', step: null, placeholder: 'row', label: 'labels.motherLastName', show: true, class: 'sm:col-span-6 mt-4', options: null, propname: null, searchKey: null, disabled: false },
                            birthDay: { type: 'number', step: 1, placeholder: '21', label: 'labels.birthDay', show: true, class: 'sm:col-span-6 mt-4', options: null, propname: null, searchKey: null, disabled: false },
                            birthMonth: { type: 'number', step: 1, placeholder: '12', label: 'labels.birthMonth', show: true, class: 'sm:col-span-6 mt-4', options: null, propname: null, searchKey: null, disabled: false },
                            birthYear: { type: 'number', step: 1, placeholder: '1954', label: 'labels.birthYear', show: true, class: 'sm:col-span-6 mt-4', options: null, propname: null, searchKey: null, disabled: false },
                            birthState: { type: 'options', step: null, placeholder: null, label: 'labels.birthState', show: true, class: 'sm:col-span-4 mt-4', options: statesApiMarket, propname: 'name', searchKey: 'value', disabled: this.disableInputs },
                            sex: { type: 'options', step: null, placeholder: null, label: 'labels.sex', show: true, class: 'sm:col-span-4 mt-4', options: sexes, propname: 'name', searchKey: 'value', disabled: this.disableInputs },
                        }
                    }
                ],
                selectedSection: this.selectedSection_,
            }
        },
        methods: {
            updateDropDown(updated, key, searchKey) {
                console.log(this.formData)
                this.formData[this.selectedSection][key] = updated[searchKey];
                // this.$emit('update:model', this.formData);
            },
        },
    }
</script>