<template>
  <div class="col-span-full sm:col-span-6 xl:col-span-4 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
    <div class="flex flex-col h-full">
      <!-- Card top -->
      <div class="grow p-5">
        <div class="flex justify-between items-start">
          <!-- Image + name -->
          <header>
            <div class="flex mb-2">
              <div class="w-4 h-4 rounded-full" :class="item.isActive ? 'bg-green-500': 'bg-red-500'"></div>
              <router-link class="relative inline-flex items-start mr-5" :to="'#0'">
                <div class="absolute top-0 right-0 -mr-2 bg-white dark:bg-slate-700 rounded-full shadow" aria-hidden="true">
                  <svg class="w-8 h-8 fill-current text-amber-500" viewBox="0 0 32 32">
                    <path d="M21 14.077a.75.75 0 01-.75-.75 1.5 1.5 0 00-1.5-1.5.75.75 0 110-1.5 1.5 1.5 0 001.5-1.5.75.75 0 111.5 0 1.5 1.5 0 001.5 1.5.75.75 0 010 1.5 1.5 1.5 0 00-1.5 1.5.75.75 0 01-.75.75zM14 24.077a1 1 0 01-1-1 4 4 0 00-4-4 1 1 0 110-2 4 4 0 004-4 1 1 0 012 0 4 4 0 004 4 1 1 0 010 2 4 4 0 00-4 4 1 1 0 01-1 1z" />
                  </svg>
                </div>
                <AvatarLetter :name="item.username" :color="item.color" :size="64"/>
              </router-link>
              <div class="mt-1 pr-1">
                <router-link class="inline-flex text-slate-800 dark:text-slate-100 hover:text-slate-900 dark:hover:text-white" :to="'#0'">
                  <h2 class="text-xl leading-snug justify-center font-semibold">{{item.username}}</h2>
                </router-link>
                <div class="flex items-center"><span class="text-sm font-medium text-slate-400 -mt-0.5 mr-1">-&gt;</span>
                  {{ item.roles }}
                </div>
              </div>
            </div>
          </header>
          <!-- Menu button -->
          <EditMenu v-if="canDelete" align="right" class="relative inline-flex shrink-0">
            <li>
              <button class="font-medium text-sm text-rose-500 hover:text-rose-600 flex py-1 px-3" @click="deleteUser(item.id)">Eliminar</button>
            </li>
          </EditMenu>
        </div>
        <!-- Bio -->
        <div class="mt-2">
          <div class="text-sm">{{ item.email }}</div>
        </div>
      </div>
      <!-- Card footer -->
      <div class="border-t border-slate-200 dark:border-slate-700">
        <div class="flex divide-x divide-slate-200 dark:divide-slate-700">
          
          <button v-if="canEdit" @click="editUser(item)" class="block flex-1 text-center text-sm text-slate-600 hover:text-slate-800 dark:text-slate-300 dark:hover:text-slate-200 font-medium px-3 py-4 group">
            <div class="flex items-center justify-center">
              <svg class="w-4 h-4 fill-current text-slate-400 dark:text-slate-600 group-hover:text-slate-500 shrink-0 mr-2" viewBox="0 0 16 16">
                <path d="M11.7.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM4.6 14H2v-2.6l6-6L10.6 8l-6 6zM12 6.6L9.4 4 11 2.4 13.6 5 12 6.6z" />
              </svg>
              <span>Editar Usuario</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';

import EditMenu from '@/components/DropdownEditMenu.vue'
import AvatarLetter from '@/components/AvatarLetter.vue'

export default {
  name: 'UsersTilesCard',
  data() {
    return {
    }
  },
  props: {
    item: Object,
    canEdit: {
      type: Boolean,
      default: function(){
        return false
      }
    },
    canDelete: {
      type: Boolean,
      default: function(){
        return false
      }
    }
  },
  components: {
    EditMenu,
    AvatarLetter
  },
  methods: {
    editUser(user) {
      this.$emit('edit-user', user);
    },
    deleteUser(id) {
      this.user = JSON.parse(localStorage.getItem('me')) || {};
      if(this.user.id == id){
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'No puedes eliminarte a ti mismo'
        });
        return
      }
      this.$emit('delete-user', id);
    },
  }
}
</script>