<template>
    <span v-if="showStartButton && product.extras?.length == 0" class="flex items-center my-2">
        <span v-if="product.precandidate">Este producto solo es para recordar que el cliente esta en espera de cumplir los requisitos para ser candidato</span>
        <button v-if="permissions.canStartProduct && !product.precandidate" class="btn border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300 mr-2" 
            @click.stop.prevent="startProduct(product)">
            <span v-if="!isLoading">{{ $t('texts.startProduct') }}</span>
            <span v-else>
                <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
            </span>
        </button>
        <DeleteButton v-if="permissions.canDeleteProduct" :clickHandler="() => deleteElement(product.id)" />
    </span>
    <span class="mb-2" v-else-if="product.extras?.length == 0">{{ $t('texts.cantStartProduct') }}</span>
</template>

<script>
    import DeleteButton from '@/components/buttons/DeleteButton.vue'
    export default{
        name: 'StartServiceButton',
        components: {
            DeleteButton,
        },
        props: {
            permissions: Object,
            product: Object,
            isLoading: Boolean,
            startProduct: Function,
            deleteElement: Function,
            showStartButton: Boolean,
        },
    }
</script>