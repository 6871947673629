<template>
    <input
        class="form-input w-full mb-4"
        id="autocomplete"
        v-model="address"
        type="text"
        placeholder="Enter an address"
    />
    <GMapMap :key="componentey" class=""
        :center="center"
        :zoom="15"
        map-type-id="terrain"
        style="width: 70vw; height: 500px"
        @click="placeMarker"
    >
        <GMapMarker
            :key="marker.id"
            v-for="marker in markers"
            :position="marker.position"
        />
    </GMapMap>
    <div class="flex">
        <p class="mr-4">Latitude: {{ coordinates.lat }}</p>
        <p>Longitude: {{ coordinates.lng }}</p>
    </div>
</template>

<script>
    export default {
        name: 'MapInput',
        props: {
            modelValue: {
                type: String,
                default: ''
            },
            initialCoordinates: {
                type: Object,
                default: () => ({ lat: null, lng: null })
            }
        },
        watch: {
            modelValue(newValue) {
                this.address = newValue;
            },
            address(newValue) {
                this.$emit('update:modelValue', newValue);
            },
            initialCoordinates: {
                handler(newCoords) {
                    if (newCoords.lat !== this.coordinates.lat || newCoords.lng !== this.coordinates.lng) {
                        this.coordinates = { ...newCoords }; // Update the local copy if the prop changes
                        this.$emit('update:coordinates', this.coordinates);
                    }
                },
                deep: true,
            }
        },
        data() {
            return {
                componentey: 0,
                coordinates: { ...this.initialCoordinates }, 
                address: this.modelValue,
                center: {lat: 20.666809, lng: -103.385032},
                markers: [
                    {
                        id: 'dfsldjl3r',
                        position: {
                            lat: 20.666809, lng: -103.385032
                        },
                    }
                ],
                geocoder: null,
            }
        },
        mounted() {
            this.$gmapApiPromiseLazy().then(() => {
                this.geocoder = new google.maps.Geocoder();
                // Initialize Google Places Autocomplete when the component is mounted
                this.initAutocomplete();
            });
        },
        methods: {
            placeMarker(event) {
                const lat = event.latLng.lat();
                const lng = event.latLng.lng();
                
                // Update the marker position and reassign markers array for reactivity
                this.markers = [
                    {
                    id: new Date().getTime().toString(), // Unique ID for marker
                    position: { lat, lng }
                    }
                ];

                // Update the coordinates and map center
                if (this.coordinates.lat !== lat || this.coordinates.lng !== lng) {
                    this.coordinates = { lat, lng };
                    this.center = { lat, lng };
                    this.$emit('update:coordinates', this.coordinates);
                    this.reverseGeocode(lat, lng);
                }
            },
            reverseGeocode(lat, lng) {
                const latlng = { lat, lng };
                
                this.geocoder.geocode({ location: latlng }, (results, status) => {
                    if (status === 'OK' && results[0]) {
                        this.address = results[0].formatted_address;  // Update the address input
                    } else {
                        console.error('Geocoder failed due to: ' + status);
                    }
                });
            },
            initAutocomplete() {
                const input = document.getElementById('autocomplete');
                this.autocomplete = new google.maps.places.Autocomplete(input, {
                    types: ['geocode'], // Restrict to addresses
                });

                // When the user selects an address from the dropdown
                this.autocomplete.addListener('place_changed', this.onPlaceChanged);
            },
            onPlaceChanged() {
                const place = this.autocomplete.getPlace();

                // If the place has a geometry (i.e., it’s a valid location), update the map center and coordinates
                if (place.geometry) {
                    const location = place.geometry.location;
                    const newCoords = { lat: location.lat(), lng: location.lng() };

                    // Only emit if coordinates have changed
                    if (this.coordinates.lat !== newCoords.lat || this.coordinates.lng !== newCoords.lng) {
                        this.coordinates = newCoords;
                        this.center = newCoords;
                        this.markers = [{
                            id: new Date().getTime().toString(),
                            position: newCoords,
                        }];
                        this.$emit('update:coordinates', this.coordinates); // Emit updated coordinates
                    }
                    this.address = place.formatted_address;
                } else {
                    alert('Seleccione una direccion correcta para continuar');
                }
            }
        }
    }
</script>