<template>
  <div class="grow">
    <!-- Panel body -->
    <div class="p-6 space-y-6">
      <h2 class="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">Comisiones y gastos</h2>
      <form @submit.prevent="save">
        <div class="grid grid-cols-1 sm:grid-cols-6 gap-2">
            <div class="sm:col-span-3" > 
                <FeesTable 
                :key="this.componentKey"
                :title="'Comisiones'"
                :title-modal="'Agregar Subcategoria de comision'"
                :is-loading="isLoading" :can-edit="canEdit"
                :fees-list_="this.formData.fee"
                @update:model="updateFeesList"/>
            </div>
            <div class="sm:col-span-3" > 
                <FeesTable 
                :key="this.componentKey"
                :title="'Gastos'"
                :title-modal="'Agregar Subcategoria de gasto'"
                :is-loading="isLoading" :can-edit="canEdit"
                :fees-list_="this.formData.expenses"
                @update:model="updateExpensesList"/>
            </div>
        </div>
        <SubmitMainButton v-if="canEdit" :is-loading="isLoading" :show-cancel-button="true" :click-handler="forceRerender" />
    </form>
    </div>
  </div>  
</template>

<script>
import { ref } from 'vue'

import FeesTable from '@/partials/products/FeesTable.vue';
import SubmitMainButton from '@/components/buttons/SubmitMainButton.vue'

export default {
  name: 'FeeAndExpensesPanel',
  props: {
    isLoading: Boolean,
    product: Object,
    canEdit: {
      type: Boolean,
      default: function(){
        return false
      }
    },
  },
  components: {
    FeesTable,      
    SubmitMainButton
  },
  setup() {

  const sync = ref('Off')

    return {
      sync,
    }
  },
  data(){
    return {
      componentKey: 0,
      formData: {
          fee: this.product.fee,
          expenses: this.product.expenses
      },
    }
  },
  methods: {
    async forceRerender() {
      const productId = this.$route.params.id;
      await loadFromLocalStorage('productsEdit', 'products', this);

      const temp = this.products.find(product => product.id === productId) || {};
      this.formData = temp;
      this.componentKey += 1;
      this.$emit('forceRender');
    },
    save(){
      this.$emit('save', this.formData);
    },
    updateFeesList(updated) {
      this.formData.fee = updated;
    },
    updateExpensesList(updated) {
      this.formData.expenses = updated;
    },
  },
}
</script>