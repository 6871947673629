<template>
    <div class="pb-2">
        <div class="text-sm text-slate-800 dark:text-slate-100 font-semibold mb-3">{{label}}</div>
        <ul class="space-y-2">
            <li>
            <label v-for="option in options" :key="option" class="flex items-center">
                <input type="checkbox" :checked="isChecked(option)" class="form-checkbox" @change="updateModel(option)" />
                <span class="text-sm text-slate-600 dark:text-slate-300 font-medium ml-2">{{option === "" ? "Vacio" : option === null ? "Nulo" : option}}</span>
            </label>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'CheckboxFilter',
    props:{
        options: Array,
        model: Object,
        label: String
    },
    data(){
        var o = this.options
        return {options_: o.sort()}
    },
    methods: {
        isChecked(option){
            return this.model[option === "" ? "Vacio" : option === null ? "Nulo" : option]
        },
        updateModel(option) {
            const o = option === "" ? "Vacio" : option === null ? "Nulo" : option
          this.$emit('update:filter', { ...this.model, [o]: !this.model[o] });
        },
    }
}
</script>