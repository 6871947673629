<template>
    Mapa
    <div class="flex">
        <MainButtonClickHandler :text="'Regresar'" :is-loading="isLoading" :click-handler="updateSection" />
    </div>
</template>

<script>
    import MainButtonClickHandler from '@/components/buttons/MainButtonClickHandler.vue';
    export default {
        name: 'MapForm',
        components: {
            MainButtonClickHandler
        },
        methods: {
            updateSection(){
                this.$.emit('update-section:value', 'section1')
            }
        }
    }
</script>