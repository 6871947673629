<template>
  <div class="grow">
    <!-- Panel body -->
    <div class="p-6 space-y-6">
      <h2 class="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">General</h2>
      <div class="flex flex-col space-y-10 sm:flex-row sm:space-x-6 sm:space-y-0 md:flex-col md:space-x-0 md:space-y-10 xl:flex-row xl:space-x-6 xl:space-y-0 mt-9 ">
        
        <form @submit.prevent="save" class="pt-3">
          <FormComponent
          :key="this.componentKey" :disable-inputs="!canEdit"
          :is-loading="this.isLoading_"
          :init-categories-value="this.initValue"
          :categories_="this.categories"
          :prospects="this.prospects"
          :form-data_="this.formData"
          :modify-agent="this.modifyAgent" :modify-source="this.modifySource"
          @update:model="updateFormData"
          />
          <SubmitMainButton v-if="canEdit" :is-loading="isLoading" :show-cancel-button="true" :click-handler="forceRerender" />
      </form>
      <span v-if="isLoading">
        <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
      </span>
      <div v-else-if="!isLoading && this.clientTodos.length > 0" class="w-full lg:w-1/2 pl-10" :key="todoComponentKey">
        <h2 class="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">TO DO's</h2>
        <span v-for="todo in this.clientTodos" :key="todo.id">
          <ToDoAppointmentRow
          :events_="this.events"
          :prospect-id="this.prospectId"
          :todo="todo"
          @checked:value="onChecked"
          @save="emitSave"
          />
        </span>
      </div>
      
      </div>
    </div>
  </div>  
</template>

<script>
import { fetchData } from '@/utils/fetchData.js';
import { loadFromLocalStorage, readFromIdb } from '@/utils/loadFromLocalStorage.js';

import FormComponent from '@/partials/prospects/FormComponent.vue';
import ToDoAppointmentRow from '@/components/ToDoAppointmentRow.vue';
import SubmitMainButton from '@/components/buttons/SubmitMainButton.vue'

export default {
  name: 'GeneralPanel',
  props: {
    isLoading_: Boolean,
    categories: Array,
    prospect: Object,
    clientTodos_: Array,
    canEdit: {
      type: Boolean,
      default: function(){
        return false
      }
    },
    modifyAgent: { type: Boolean, default: function(){ return false } },
    modifySource: { type: Boolean, default: function(){ return false } },
  },
  components: {
    FormComponent,
    SubmitMainButton,
    ToDoAppointmentRow
  },
  data(){
    return {
      isLoading: false,
      isLoadingEdit: false,
      prospectId: null,
      todoComponentKey: 0,
      componentKey: 0,
      prospects: [],
      initValue: 0,
      category: {
          name: null
      },
      formData: this.prospect,
      events: [],
      clientTodos: this.clientTodos_,
    }
  },
  methods: {
    onChecked(){
      this.todoComponentKey += 1;
    },
    async emitSave(formData){
      this.isLoading = true
      this.clientTodos = formData.clientTodos.filter(todo => todo.type == 'PROSPECT');
      await this.fetchEvents();
      this.todoComponentKey += 1;
      this.$emit('save', formData.formData);
      this.isLoading = false
    },
    async forceRerender() {
      this.prospectId = this.$route.params.id;
      //
      const prospects = await readFromIdb(this.stringTypeClient)

      const temp = prospects.find(prospect => prospect.id === this.prospectId) || {};
      this.formData = temp;
      this.componentKey += 1;
      this.$emit('forceRender');
    },
    save(){
      this.$emit('save', this.formData);
    },
    updateFormData(updated){
      this.formData.name = updated.name;
      this.formData.lastName = updated.lastName;
      this.formData.email = updated.email;
      this.formData.phone = updated.phone;
      this.formData.sourceId = updated.sourceId;
      this.formData.agentId = updated.agentId;
      this.formData.addressStreet = updated.addressStreet;
      this.formData.addressNumber = updated.addressNumber;
      this.formData.addressNeighborhood = updated.addressNeighborhood;
      this.formData.addressZipCode = updated.addressZipCode;

      this.formData.addressState = updated.addressState == 'Seleccionar' ? null : updated.addressState;
      this.formData.civilState = updated.civilState == 'Seleccionar' ? null : updated.civilState;
      this.formData.birthState = updated.birthState == 'Seleccionar' ? null : updated.birthState;
    },
    async fetchEvents() {
      return fetchData(`events`, 'events', 'events', this, false);
    },
    
  },
  async created(){
    this.stringTypeClient = this.$route.path.includes('prospects') ? 'prospects' : 'clients'
    this.prospectId = this.$route.params.id;
    //
    const prospects = await readFromIdb(this.stringTypeClient)
    this.prospects = prospects.filter((prospect) => prospect.id !== this.prospectId)
    await loadFromLocalStorage('events', 'events', this);
    this.fetchEvents();
  }
}
</script>