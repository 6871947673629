<template>
    <div class="space-y-4 grid grid-cols-1 gap-x-6 sm:grid-cols-6 mb-4 ">

        <span v-if="isLoading" class="sm:col-span-3 mt-3">
            <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
        </span>
        <div v-else-if="this.showData" class="sm:col-span-3" >
            <span v-if="this.showAsignAgentButton">
                <label class="block text-sm font-medium mb-1">
                    Vendedor:
                </label>
            
                <input v-if="this.users?.length < 1" id="email" class="form-input w-full" type="text" :placeholder="'Crea al menos un vendedor'" :disabled="true" />
            
                <DropdownFull v-else-if="!formData.automaticAsignation" :key="componentKey"
                :propname="'username'"
                :dropdownOptions="this.users" 
                :init-value="isAgent ?  this.users.findIndex((element) => element.id === user.id) : 0"
                :model="null" @update:model="update($event, 'agentId', 'id')" />
                <input v-else class="form-input w-full" type="text" :placeholder="'Se asignara el vendedor con base en la disponibilidad'" :disabled="true" />
                <div style="display: flex;">
                    <div>
                        <input type="checkbox" :checked="formData.automaticAsignation" @change="updateModel(option)">
                    </div>
                    <div class="ml-2">
                        Asignar vendedor automaticamente
                    </div>
                </div>
            </span>
            <div v-else>
                <label class="block text-sm font-medium mb-1">
                    Vendedor:
                </label>
                <input v-if="!isAgent" class="form-input w-full" type="text" :placeholder="'Se asignara el vendedor con base en la disponibilidad'" :disabled="true" />
                <DropdownFull v-else :key="componentKey"
                :propname="'username'" :disable-inputs="true"
                :dropdownOptions="this.users" 
                :init-value="isAgent ?  this.users.findIndex((element) => element.id === user.id) : 0"
                :model="null" @update:model="update($event, 'agentId', 'id')" />
            </div>
            <!-- <div v-if="this.showAsignAgentButton" style="display: flex;">
                <div>
                    <input type="checkbox" :checked="formData.automaticAsignation" @change="updateModel(option)">
                </div>
                <div class="ml-2">
                    Asignar vendedor automaticamente
                </div>
            </div> -->
        </div>
        
        <div v-if="this.showData" class="sm:col-span-3 mt-4" >
            <label class="block text-sm font-medium mb-1" for="email">{{ $t('labels.email') }}<span class="text-rose-500">*</span></label>
            <input id="email" class="form-input w-full" type="email" placeholder="John@doe.com" v-model="formData.email" required />
        </div>
        <span v-if="isLoading" class="sm:col-span-3 mt-3">
            <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
        </span>
        <div v-else-if="this.showData" class="sm:col-span-3" >
            <DropdDownWithFormModal
            :sublabel="'Crea al menos una fuente'" :disable-inputs="!showAsignAgentButton"
            :modaltitle="'Agregar Fuente'"
            :label="'Fuente'"
            :init-value="this.sources.findIndex((element) => element.name === 'Venta directa')"
            :list_="this.sources" :can-add="canAdd"
            @update:model="update($event, 'source', 'id')"
            />
        </div>
        <div v-if="this.showData" class="sm:col-span-6 mt-4" >
            <label class="block text-sm font-medium mb-1" for="phone">{{ $t('labels.telephone') }}<span class="text-rose-500">*</span></label>
            <input id="phone" class="form-input w-1/2" type="text" placeholder="+3323130812" v-model="formData.phone" required />
        </div>
        
    </div>
</template>

<script>
    import { fetchData } from '@/utils/fetchData.js';
    import { loadFromLocalStorage } from '@/utils/loadFromLocalStorage.js';

    import DropdownFull from '@/components/DropdownFull.vue';
    import DropdDownWithFormModal from '@/partials/prospects/detail/DropdDownWithFormModal.vue';
    
    export default {
        name: 'FormComponent',
        components: {
            DropdownFull,
            DropdDownWithFormModal,
        },
        props:{
            canAdd: {
                type: Boolean,
                default: function(){
                    return false;
                }
            },
            showAsignAgentButton: Boolean,
            showData: Boolean,
            formData_: {
                type: Object,
                default: function() {
                    return {
                        name: '',
                    };
                }
            }
        },
        data() {
            const user = JSON.parse(localStorage.getItem('me')) || {};
            const isAgent = user.roles.some(e => e.name == 'Agente AFIE')
            return {
                user,
                isAgent,
                componentKey: 0,
                initValue: 0,
                isLoading: false,
                sources: [],
                users: [],
                formData: this.formData_,
            };
        },
        methods: {
            async fetchSources() {
                this.users = await fetchData(`users`, 'users', 'users', this);
                this.sources = await fetchData(`sources/`, 'sources', 'sources', this);
                const user = JSON.parse(localStorage.getItem('me')) || {};
                const isAgent = user.roles.some(e => e.name == 'Agente AFIE')
                const initValueSources = this.sources.findIndex((element) => element.name === 'Venta directa')
                this.users = this.users.filter(element => element.roles.some(e => e.name == 'Agente AFIE'))
                const initValue = isAgent ?  this.users.findIndex((element) => element.id === user.id) : 0
                if (this.users.length > 0) {
                    this.formData.agentId = this.users[initValue].id;
                    this.$emit('update:model', this.formData);
                }
                if(this.sources.length > 0){
                    this.formData.source = this.sources[initValueSources].id;
                    this.$emit('update:model', this.formData);
                }
            },
            update(updated, propName, updatedPropName) {
                this.formData[propName] = updated[updatedPropName];
                this.$emit('update:model', this.formData);
            },
            updateModel(option) {
                this.formData.automaticAsignation = !this.formData.automaticAsignation;
                this.$emit('update:model', this.formData);
            },
        },
        created(){
            Promise.all([
                loadFromLocalStorage('sources', 'sources', this),
                loadFromLocalStorage('users', 'users', this),
            ]).then((results) =>{
                const user = JSON.parse(localStorage.getItem('me')) || {};
                const isAgent = user.roles.some(e => e.name == 'Agente AFIE')
                const initValueSources = this.sources.findIndex((element) => element.name === 'Venta directa')
                this.users = this.users.filter(element => element.roles.some(e => e.name == 'Agente AFIE'))
                const initValue = isAgent ?  this.users.findIndex((element) => element.id === user.id) : 0
                this.componentKey++
                if(!this.showAsignAgentButton && !isAgent){
                    this.formData.automaticAsignation = true
                    this.$emit('update:model', this.formData);
                }
                if (this.users.length > 0) {
                    this.formData.agentId = this.users[initValue].id;
                    this.$emit('update:model', this.formData);
                }
                if (this.sources.length > 0) {
                    this.formData.source = this.sources[initValueSources].id;
                    this.$emit('update:model', this.formData);
                }

                this.fetchSources();
                    
                this.isLoading = false;
            })
        }
    }
</script>
