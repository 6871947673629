<template>
    <div class="grow">
      <!-- Panel body -->
      <div class="p-6 space-y-6">
        <h2 class="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">Documentos</h2>
        <span v-if="isLoading">
            <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
        </span>
        <DocumentsTable v-else
        @save-p="saveClientDocument" :can-delete="canEdit" :can-edit="canEdit"
        @delete-p="deleteClientDocument"
        :documents="this.clientDocuments"/>
    </div>
  </div>  
</template>

<script>
    import { deleteData } from '@/utils/deleteData.js';
    import { editData } from '@/utils/editData.js';
    import { setDataInStore } from '@/utils/loadFromLocalStorage.js';

    import DocumentsTable from './documents/DocumentsTable.vue';

    export default {
        name: 'DocumentsPanel',
        components: {  
            DocumentsTable
        },
        props: {
            prospectId: String,
            clientDocuments_: Array,
            canEdit: {
                type: Boolean,
                    default: function(){
                    return false
                }
            },
        },
        data(){
            return {
                clientDocuments: this.clientDocuments_,
                isLoading: false,
            }
        },
        methods: {
            async saveClientDocument(object){
                this.isLoading = true;
                await editData(`client-documents/${object.documentId}`, object.body, 'El documento se a editado exitosamente.', 'clientDocuments', 'clientDocuments', this, true);
                this.isLoading = false;
            },
            async deleteClientDocument(documentId){
                this.isDeleting = true;
                this.deletingId = documentId;
                const response = await deleteData('client-documents', id, this, 'El documento se a eliminado exitosamente.', true, true)
                if(!response){
                    return
                }
                const updated = this.clientDocuments.filter((e) => e.id !== id);
                await setDataInStore('clientDocuments', updated)
                this.clientDocuments = this.clientDocuments.filter((e) => e.id !== id)
            }
        },        
    }
</script>