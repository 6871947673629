<template>
  <ModalBasic id="preview-modal" :modalOpen="modalOpen" @close-modal="modalOpen = false" :title="'Preview Contrato'" :size="'max-w-2xl'" :document-row="true">

    <vue-document-editor class="editor h-[32rem] overflow-y-auto" ref="editor"
    v-model:content="content" :content-editable="false"
    :overlay="overlay"
    :zoom="zoom"
    :page_format_mm="page_format_mm"
    :page_margins="page_margins"
    :display="display" />
  </ModalBasic>
  <div class="grow">
    <!-- Panel body -->
    <div class="p-6 space-y-6">
      <h2 class="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">Contratos</h2>
      <form @submit.prevent="add">
        <div class="grid grid-cols-1 sm:grid-cols-6 gap-2">
            <div v-if="canEdit" class="sm:col-span-3" > 
              <label class="block text-sm font-medium mb-1">
                  {{ $t('labels.contracts') }}:
              </label>
              <div class="flex w-full">
                <input v-if="this.contracts?.length < 1" id="email" class="form-input w-full" type="text" placeholder="Crea al menos un contrato" :disabled="true" />
                
                <DropdownFullQuestions v-else
                :property-key="'name'"
                :dropdownOptions="this.contracts" 
                :model="this.formData.contract" @update:model="updateSelectedContract" />

                <div class="pr-2"></div>
                <button v-if="showAdd" class="text-white-500 hover:text-white-600 rounded-full">
                  <svg @click.stop="createContract" class="w-4 h-8 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                      <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                  </svg>
                </button>
              </div>
              <button :disabled="isLoading || this.contracts?.length < 1" @click.stop="addContractToProduct" class="btn bg-indigo-500 hover:bg-indigo-600 text-white mt-2">
                <span v-if="!isLoading">{{ $t('buttons.add') }}</span>
                <span v-else>
                  <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
                </span>
              </button>
            </div>
            <div class="sm:col-span-3" > 
                <SubCategoriesTable 
                :is-draggable="false"
                :can-edit="canEdit"
                :title="'Contratos del producto'" 
                :empty-label="'Selecciona un contrato del dropdown para a;adirlo a los contratos requeridas para el producto'"
                :property-key="'contract.name'"
                :values="this.productContracts" :is-loading="isLoading" 
                @update:model="deleteProductContract"
                @updateorder:model=update
                @edit="previewContract"
                />
            </div>
        </div>
    </form>
    </div>
  </div>  
</template>

<script>
import { editData } from '@/utils/editData.js';
import { postData } from '@/utils/postData.js';
import { deleteData } from '@/utils/deleteData.js';
import { setDataInStore } from '@/utils/loadFromLocalStorage.js';
import { hasSpecificPermission } from '@/utils/hasSpecificPermission.js';

import Swal from 'sweetalert2';
import ModalBasic from '@/components/ModalBasic.vue';
import SubCategoriesTable from './SubCategoriesTable.vue';
import DropdownFullQuestions from './DropdownFullQuestions.vue';
import VueDocumentEditor from '@/pages/DocumentEditor/DocumentEditor.vue';

export default {
  name: 'ContractsPanel',
  components: {  
    ModalBasic,
    VueDocumentEditor,
    SubCategoriesTable,
    DropdownFullQuestions,
  },
  props: {
    productId: String,
    contracts_: Array,
    productContracts_: Array,
    canEdit: {
      type: Boolean,
      default: function(){
        return false
      }
    },
  },
  data(){
    return {
      modalOpen: false,
      zoom: 0.8,
      zoom_min: 0.10,
      zoom_max: 5.0,
      page_format_mm: [256, 330],
      page_margins: "0.98in 1.18in",
      display: "grid", // ["grid", "vertical", "horizontal"]
      content: [],
      contracts: this.contracts_,
      productContracts: this.productContracts_,
      isLoading: false,
      formData: {
        contract: this.contracts_.length > 0 ?this.contracts_[0] : null,
        expenses: []
      },
    }
  },
  methods: {
    overlay (page, total) {
      // Add page numbers on each page
      let html = '<div style="position: absolute; bottom: 8mm; ' + ((page % 2) ? 'right' : 'left') + ': 10mm">Page ' + page + ' of ' + total + '</div>';

      // Add custom headers and footers from page 3
      // if(page >= 3) {
      //     html += '<div style="position: absolute; left: 0; top: 0; right: 0; padding: 3mm 5mm; background: rgba(200, 220, 240, 0.5)"><strong>MYCOMPANY</strong> example.com /// This is a custom header overlay</div>';
      //     html += '<div style="position: absolute; left: 10mm; right: 10mm; bottom: 5mm; text-align:center; font-size:10pt">MY COMPANY - example.com /// This is a custom footer overlay</div>';
      // }
      return html;
    },
    createContract(){
      this.$router.push('/products/create-contract'); // Or use a custom route if defined
    },
    async deleteProductContract(index){
      const id = this.productContracts[index].id
      const response = await deleteData('product-contracts', id, this, 'El contrato del producto se a eliminado exitosamente.', true, true)
      if(!response){
          return
      }
      this.productContracts.splice(index, 1);
      await setDataInStore('productContracts', this.productContracts)
    },
    async addContractToProduct(){
      const body = {
        productId: this.productId,
        contractId: this.formData.contract.id
      }
      const response = await postData('product-contracts', body, this);
      if (!response) {
          return
      };
      Swal.fire({
        icon: 'success',
        title: 'Exito!',
        text: 'El contrato se agrego al producto.',
      });
      this.productContracts.push(response);
      await setDataInStore('productContracts', this.productContracts)
    },
    async update(elements){
      const productId = this.$route.params.id;
      var body = [];
      for (let i = 0; i < elements.length; i++) {
        body.push({
          id: elements[i].id,
          index: i + 1,
          contractId: elements[i].contract.id,
          productId: productId,
        });
        
      }
      const response = await editData(`product-contracts`, body, 'El orden se modifico correctamente.', 'productContracts', 'productContracts', this, true);
      if (!response) {
          return
      };
      this.productContracts = response;
      await setDataInStore('productContracts', this.productContracts)
      this.$emit('forceRender');
    },
    updateSelectedContract(contract){
      this.formData.contract = contract;
    },
    previewContract(contract){
      this.modalOpen = true;
      this.content = contract.contract.content
    }
  },
  created(){
    hasSpecificPermission('showAdd', 'add', ["add_contracts"], this)
  }
}
</script>