<template>
  <BasePage>
    <span v-if="isLoading">
      <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
    </span>
    <div v-else class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      <!-- Page header -->
      <div class="sm:flex sm:justify-between sm:items-center mb-8">
        <!-- Left: Title -->
        <PageTitle :title="`TODO's`" />
      </div>
      <div class="mb-4 border-b border-slate-200 dark:border-slate-700">
      </div>          

      <div class="flex flex-col space-y-10 sm:flex-row sm:space-x-6 sm:space-y-0 md:flex-col md:space-x-0 md:space-y-10 xl:flex-row xl:space-x-6 xl:space-y-0 mt-9">
        
        <FiltersSidebar v-if="showFilters"
          :key="componentKey"
          :filter-config_="this.filtersConfig"
          :filters_="filters" @resetFilters:value="resetFilters"
          @update:checkboxfilters="updateCheckBoxFilter($event, this)"
          @update:filters="updateFilter($event, this)"
          @update:updateDateFilter="updateDateFilter($event, this)"
          @update:selectfilters="updateCheckBoxFilter($event, this)"
            />
        
            <div class="w-full" >
          <TodosTable 
              @edit-todo:value="editTodo"
            :key="componentKey" :todos="this.todos" :filters="this.filters" :items-per-page="100"/>
            </div>
      </div>

    </div>
  </BasePage>
</template>

<script>
import { editData } from '@/utils/editData.js';
import { fetchData } from '@/utils/fetchData.js';
import { loadFromLocalStorage } from '@/utils/loadFromLocalStorage.js';
import { hasSpecificPermission } from '@/utils/hasSpecificPermission.js';
import { getUniqueValuesFilter } from '@/utils/getUniqueValuesFilter.js';

import BasePage from '@/components/BasePage.vue';
import PageTitle from '@/components/PageTitle.vue';
import TodosTable from '@/partials/todos/TodosTable.vue'
import FiltersSidebar from '@/components/FiltersSidebar.vue';

export default {
  name: 'TasksKanban',
  components: {
    BasePage,
    PageTitle,
    TodosTable,
    FiltersSidebar,
  },
  data(){
    return {
      todos: [],
      users: [],
      componentKey: 0,
      showFilters: false,
      isLoading: false,
      filters: {
        name: '',
        createdAtStart: null,
        createdAtEnd: null,
        balance: [],
        selectedAppointmentStatus: {},
        selectedAgent: {},
        selectedTypes: {},
        selectedBloqued: {},
        selectedClients: {},
      },
      filtersConfig: [
        {title: 'General', filters: [
          {name: 'name', label: 'Nombre', propname: 'name', type: 'string', options: null, values: null},
          {name: 'createdAt', label: 'Fecha de Creacion', propname: 'createdAt', type: 'date', options: null, values: null}
        ]},
        // {title: 'Progreso', filters: [
        //   {name: 'selectedAppointmentStatus', label: 'Cita', propname: 'selectedAppointmentStatus', type: 'checkbox', options: ['TODO'], values: null}
        // ]},
        {title: 'Producto/Vendedor', filters: [
          {name: 'selectedAgent', label: 'Vendedor', propname: 'selectedAgent', type: 'dropdown', options: [], values: null},
          {name: 'selectedProducts', label: 'Producto', propname: 'selectedProducts', type: 'dropdown', options: [], values: null},
          // {name: 'selectedClients', label: 'Cliente', propname: 'selectedClients', type: 'dropdown', options: [], values: null},
        ]},
        {title: 'Tipo', filters: [
          {name: 'selectedTypes', label: 'Tipo', propname: 'selectedTypes', type: 'dropdown', options: [], values: null},
          {name: 'selectedBloqued', label: 'Bloqueados/Desbloqueados', propname: 'selectedBloqued', type: 'dropdown', options: [], values: null}
        ]},
        // {title: 'Infonavit', filters: [
        //   {name: 'balance', label: 'Saldo Cuenta', propname: 'balance', type: 'slider', values: [0]}
        // ]}, 
      ]
    }
  },
  methods: {
    async editTodo(formData){
      await editData(`todos/${formData.todo.id}`, {description: formData.todo.description, notes: formData.todo.notes}, 'El TO DO se ha guardado con exito.', 'todos', 'todos', this, false);
      this.componentKey += 1;
    },
    resetFilters(updated){
      this.filters = updated
      this.componentKey++
    },
    updateFilter(updated, context){
      context.filters[updated.propName] = updated.value;
    },
    updateDateFilter(updated, context){
      context.filters[updated.propName] = updated.value !==  null ? updated.value['0'] : null;
    },
    updateCheckBoxFilter(updated, context){
      context.filters[updated.propName] = { ...updated.value };
    },
    async getTodos() {
        this.users = await fetchData(`users`, 'users', 'users', this);
        const user = JSON.parse(localStorage.getItem('me')) || {};
        await fetchData(`todos/user/${user.id}`, 'todos', 'todos', this, true, false, 'data', false, true, 1000, 0);
        this.setFilters();
        this.componentKey++
        return
    },
    setFilters(){
      const { uniqueValues: uniqueProducts, filter: selectedProducts } = getUniqueValuesFilter(this.todos, 'clientproduct.name');
      this.filtersConfig[1].filters[1].options = uniqueProducts
      this.filters.selectedProducts = selectedProducts;
      // const { uniqueValues: uniqueClients, filter: selectedClients } = getUniqueValuesFilter(this.todos, 'client.name');
      // this.filtersConfig[1].filters[2].options = uniqueClients
      // this.filters.selectedClients = selectedClients;
      const { uniqueValues: uniqueTypes, filter: selectedTypes } = getUniqueValuesFilter(this.todos, 'type');
      this.filtersConfig[2].filters[1].options = ['Bloqueado', 'Desbloqueado']
      this.filters.selectedBloqued = {'Bloqueado': true, 'Desbloqueado': true}
      this.filtersConfig[2].filters[0].options = uniqueTypes
      this.filters.selectedTypes = selectedTypes;
      const usersOptions = this.users.map(user => user.username)
      this.filtersConfig[1].filters[0].options = usersOptions
      for (const option in usersOptions) {
        this.filters.selectedAgent[usersOptions[option]] = true;
      } 
    }
  },
  async created() {
    hasSpecificPermission('showFilters', 'page-filter', ["view_todos_filter"], this)
    Promise.all([
      loadFromLocalStorage('todos', 'todos', this),
      loadFromLocalStorage('users', 'users', this),
    ]).then((results) =>{
      this.setFilters();
      this.getTodos();
    })
  },
}
</script>