<template>
  <div class="min-w-fit">
    <!-- Sidebar backdrop (mobile only) -->
    <div class="fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200" :class="sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'" aria-hidden="true"></div>

    <!-- Sidebar -->
    <div
      id="sidebar"
      ref="sidebar"
      :class="[
        'flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 h-[100dvh] overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-slate-800 p-4 transition-all duration-200 ease-in-out',
        sidebarOpen ? 'translate-x-0' : '-translate-x-64',
        sidebarOpen && 'lg:w-64'
      ]"
    >

      <!-- Sidebar header -->
      <div class="flex justify-between mb-10 pr-3 sm:px-2">
        <!-- Close button -->
        <button
          ref="trigger"
          class="lg:hidden text-slate-500 hover:text-slate-400"
          @click.stop="$emit('close-sidebar')"
          aria-controls="sidebar"
          :aria-expanded="sidebarOpen"
        >
          <span class="sr-only">Close sidebar</span>
          <svg class="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
          </svg>
        </button>
        <!-- Logo -->
        <router-link class="block" to="/">
          <LogoSvg></LogoSvg>
        </router-link>
      </div>

      <!-- Links -->
      <div class="space-y-8">
        <!-- Pages group -->
        <div>
          <h3 class="text-xs uppercase text-slate-500 font-semibold pl-3">
            <span class="hidden 2xl:hidden text-center w-6"
              :class="[sidebarOpen ? 'lg:hidden' : 'lg:block']"
             aria-hidden="true" @click.prevent="sidebarExpanded = !sidebarExpanded">•••</span>
            <span class="2xl:block" :class="[sidebarOpen ? 'block' : 'lg:hidden']">Paginas</span>
          </h3>
          <ul class="mt-3">
            <!--  TODO: if we use .js tailwind find we need to add style="list-style:none;" to all li, ol,  -->
            <span
            v-for="route in routes"
            :key="route.id">
              <router-link v-if="!route.hasDropdown" 
              :to="route.path" custom v-slot="{ href, navigate, isExactActive }">
                <li class="px-3 py-2 rounded-sm mb-0.5 last:mb-0" :class="isExactActive && 'bg-slate-900'">
                  <a class="block text-slate-200 truncate transition duration-150" :class="isExactActive ? 'hover:text-slate-200' : 'hover:text-white'" :href="href" @click="navigate">
                    <div class="flex items-center">
                      <component :is="route.icon" :includes="currentRoute.fullPath.includes(route.includes)"/>
                      <span class="text-sm font-medium ml-3 2xl:opacity-100 duration-200"
                      :class="[sidebarExpanded ? 'lg:opacity-100' : 'lg:opacity-0']">{{route.name}}</span>
                    </div>
                  </a>
                </li>
              </router-link>
              <SidebarLinkGroup v-else v-slot="parentLink" :activeCondition="currentRoute.fullPath.includes(route.includes)">
                <a class="block text-slate-200 truncate transition duration-150" :class="currentRoute.fullPath.includes(route.includes) ? 'hover:text-slate-200' : 'hover:text-white'" href="#0" @click.stop.prevent="parentLink.handleClick(); sidebarExpanded = true">
                  <div class="flex items-center justify-between">
                    <div class="flex items-center">
                      <component :is="route.icon" :includes="currentRoute.fullPath.includes(route.includes)"/>
                      <span class="text-sm font-medium ml-3 2xl:opacity-100 duration-200"
                      :class="[sidebarExpanded ? 'lg:opacity-100' : 'lg:opacity-0']">{{route.name}}</span>
                    </div>
                    <!-- Icon -->
                    <div class="flex shrink-0 ml-2">
                      <svg class="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400" :class="parentLink.expanded && 'rotate-180'" viewBox="0 0 12 12">
                        <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                      </svg>
                    </div>
                  </div>
                </a>
                <div class="2xl:block"
                :class="[sidebarExpanded ? 'lg:block' : 'lg:hidden']">
                  <ul class="pl-9 mt-1" :class="!parentLink.expanded && 'hidden'">
                    <router-link 
                    v-for="subroute in route.links"
                    :key="subroute.id"
                    :to="subroute.path" custom v-slot="{ href, navigate, isExactActive }">
                      <li class="mb-1 last:mb-0">
                        <a class="block transition duration-150 truncate" :class="isExactActive ? 'text-indigo-500' : 'text-slate-400 hover:text-slate-200'" :href="href" @click="navigate">
                          <span class="text-sm font-medium 2xl:opacity-100 duration-200"
                          :class="[sidebarExpanded ? 'lg:opacity-100' : 'lg:opacity-0']">{{subroute.name}}</span>
                        </a>
                      </li>
                    </router-link>
                  </ul>
                </div>
              </SidebarLinkGroup>
            </span>
          </ul>
        </div>
      </div>

      <!-- Expand / collapse button -->
      <div class="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
        <div class="px-3 py-2">
          <button @click.prevent="sidebarExpanded = !sidebarExpanded">
            <span class="sr-only">Expand / collapse sidebar</span>
            <svg class="w-6 h-6 fill-current" :class="[sidebarExpanded && 'rotate-180']" viewBox="0 0 24 24">
              <path class="text-slate-400" d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z" />
              <path class="text-slate-600" d="M3 23H1V1h2z" />
            </svg>
          </button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { ref, onMounted, onUnmounted, watch, markRaw } from 'vue';

import LogoSvg from '../components/LogoSvg.vue';
import RoleSvg from '@/partials/sidebar/RoleSvg.vue';
import SidebarLinkGroup from './SidebarLinkGroup.vue';
import UsersSvg from '@/partials/sidebar/UsersSvg.vue';
import SourcesSvg from '@/partials/sidebar/SourcesSvg.vue';
import ClientsSvg from '@/partials/sidebar/ClientsSvg.vue';
import ProductsSvg from '@/partials/sidebar/ProductsSvg.vue';
import SettingsSvg from '@/partials/sidebar/SettingsSvg.vue';
import ProspectsSvg from '@/partials/sidebar/ProspectsSvg.vue';
import {extractUniquePermissions} from '@/utils/extractUniquePermissions.js';
export default {
  name: 'SidebarWidget',
  props: ['sidebarOpen'],
  components: {
    LogoSvg,
    RoleSvg,
    UsersSvg,
    SourcesSvg,
    ClientsSvg,
    ProductsSvg,
    SettingsSvg,
    ProspectsSvg,
    SidebarLinkGroup,
  },  
  setup(props, { emit }) {

    const trigger = ref(null)
    const sidebar = ref(null)

    const storedSidebarExpanded = localStorage.getItem('sidebar-expanded')
    const sidebarExpanded = ref(storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true')

    const currentRoute = useRouter().currentRoute.value

    // close on click outside
    const clickHandler = ({ target }) => {
      if (!sidebar.value || !trigger.value) return
      if (
        !props.sidebarOpen ||
        sidebar.value.contains(target) ||
        trigger.value.contains(target)
      ) return
      emit('close-sidebar')
    }

    // close if the esc key is pressed
    const keyHandler = ({ keyCode }) => {
      if (!props.sidebarOpen || keyCode !== 27) return
      emit('close-sidebar')
    } 

    onMounted(() => {
      document.addEventListener('click', clickHandler)
      document.addEventListener('keydown', keyHandler)
    })

    onUnmounted(() => {
      document.removeEventListener('click', clickHandler)
      document.removeEventListener('keydown', keyHandler)
    })

    watch(sidebarExpanded, () => {
      localStorage.setItem('sidebar-expanded', sidebarExpanded.value)
      if (sidebarExpanded.value) {
        document.querySelector('body').classList.add('sidebar-expanded')
      } else {
        document.querySelector('body').classList.remove('sidebar-expanded')
      }
    })

    return {
      trigger,
      sidebar,
      sidebarExpanded,
      currentRoute,
    }
  },
  data(){
    return {
      user: {},
      routes: []
    }
  },
  methods: {
    transformPermissionsToRoutes(permissions) {
      const routes = [];
      permissions.forEach((permission, index) => {
        
        if(permission.params.parent){
          const route = {
            id: index + 1, // Assuming unique id generation here, adjust as needed
            path: permission.params.path,
            includes: permission.params.includes,
            name: permission.params.name,
            icon: this.getSvgComponent(permission.params.icon), // Assuming you have a function to get SVG component based on icon name
            hasDropdown: permission.params.hasDropdown,
            parent: permission.params.parent
          };

          // Handle nested links for permissions with dropdown
          if (permission.params.hasDropdown) {
            route.links = permissions
              .filter(p => p.params.parent === false && p.params.includes === permission.params.includes && (p.params.showInMenu === undefined || p.params.showInMenu === true) )
              .map((childPermission, childIndex) => ({
                id: childIndex + 1,
                path: childPermission.params.path,
                name: childPermission.params.name
              }));
          }

          routes.push(route);
        }
      });
      return routes;
    },
    getSvgComponent(componentType) {
      switch (componentType) {
        case 'UsersSvg':
          return markRaw(UsersSvg);
        case 'RoleSvg':
          return markRaw(RoleSvg);
        case 'ProductsSvg':
          return markRaw(ProductsSvg);
        case 'SourcesSvg':
          return markRaw(SourcesSvg);
        case 'ProspectsSvg':
          return markRaw(ProspectsSvg);
        case 'SettingsSvg':
          return markRaw(SettingsSvg);
        case 'ClientsSvg':
          return markRaw(ClientsSvg);
        default:
          return markRaw(UsersSvg);
      }
    }
  },
  created(){
    const uniquePermissions = extractUniquePermissions('page');
    this.routes = this.transformPermissionsToRoutes(uniquePermissions)
    
  }
}
</script>