<template>
    <BasePage>
      <span v-if="isLoading">
        <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
      </span>
      <div v-else class="w-full max-w-9xl mx-auto">
        <div class="sm:flex sm:justify-between sm:items-center mb-8">
            <!-- Left: Title -->
            <PageTitle :title="`Clientes ✨`" />

            <div class="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2 items-center">
              <BoardToggle :is-board="boardModeClients" @update:model="changeBoardModeClients"/>
            </div>            
        </div>
        <div v-if="showFilters" class="flex flex-col space-y-10 sm:flex-row sm:space-x-6 sm:space-y-0 md:flex-col md:space-x-0 md:space-y-10 xl:flex-row xl:space-x-6 xl:space-y-0 mt-9">
          <!-- Sidebar -->
          <FiltersSidebar :key="this.componentKey" v-if="!isLoading && showFilters" 
              :filter-config_="this.filtersConfig"
              :filters_="filters" @resetFilters:value="resetFilters"
              @update:checkboxfilters="updateCheckBoxFilter($event, this)"
              @update:filters="updateFilter($event, this)"
              @update:updateDateFilter="updateDateFilter($event, this)"
              @update:selectfilters="updateCheckBoxFilter($event, this)"
            />
          <div class="w-full" >
            <ProspectsTable v-if="!isLoading" :title="'Clientes'"
            :is-todo="isTodo" :is-in-progress="isInProgress" :is-completed="isCompleted" :is-rejected="isRejected"
            :is-board="boardModeClients"  :can-delete="showDelete" :can-edit="showEdit" :can-visualize="showVisualize"
            :filters="filters"
            :sources="this.sources" :users="this.users"
            :prospects="this.clients" :items-per-page="20" @delete-p="deleteP"/>
            <span v-else>
              <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
            </span>
          </div>
        </div>
      </div>
    </BasePage>
  </template>

<script>
    import { fetchData } from '@/utils/fetchData.js';
    import { hasSpecificPermission } from '@/utils/hasSpecificPermission.js';
    import { setFiltersMethod } from '@/services/prospects/prospectsPageServices';
    import { loadFromLocalStorage, setDataInStore } from '@/utils/loadFromLocalStorage.js';
    import { prospectsPageComponentData } from '@/services/prospects/prospectsPageComponentData';
  
    import BasePage from '@/components/BasePage.vue';
    import PageTitle from '@/components/PageTitle.vue';
    import BoardToggle from '@/components/BoardToggle.vue';
    import FiltersSidebar from '@/components/FiltersSidebar.vue';
    import ProspectsTable from '@/partials/prospects/ProspectsTable.vue';
    export default {
        name: 'ClientsPage',
        components: {
          BasePage,
          PageTitle,
          BoardToggle,
          ProspectsTable, 
          FiltersSidebar,
        },
        data(){
          return prospectsPageComponentData()
        },
        methods: {
            isTodo(prospect) {
              return !prospect.isRejected && prospect.appointmentStatus === 'TODO' &&
                prospect.callStatus === 'TODO' &&
                prospect.closingStatus === 'TODO';
            },
            isInProgress(prospect) {
              const { appointmentStatus, callStatus, closingStatus, isRejected } = prospect;

              // Check if any status is neither 'TODO' nor 'DONE'
              const hasInprogressStatus = [appointmentStatus, callStatus, closingStatus].some(
                status => status !== 'TODO' && status !== 'DONE'
              );

              // Check if any status is 'DONE' while others are still 'TODO'
              const isPartiallyComplete = (
                (appointmentStatus === 'DONE' && (callStatus === 'TODO' || closingStatus === 'TODO')) ||
                (callStatus === 'DONE' && (appointmentStatus === 'TODO' || closingStatus === 'TODO')) ||
                (closingStatus === 'DONE' && (appointmentStatus === 'TODO' || callStatus === 'TODO'))
              );

              return (hasInprogressStatus || isPartiallyComplete) && !isRejected;
            },
            isCompleted(prospect) {
              return prospect.appointmentStatus === 'DONE' && 
                prospect.callStatus === 'DONE' && 
                prospect.closingStatus === 'DONE' && !prospect.isRejected
            },
            isRejected(prospect) {
              return prospect.isRejected;
            },
            resetFilters(updated){
              const original = JSON.parse(localStorage.getItem('clientsFiltersOriginal')) || null;
              this.filters = original
              localStorage.setItem('clientsFilters', JSON.stringify(this.filters))
              this.componentKey++
            },
            updateFilter(updated, context){
              context.filters[updated.propName] = updated.value;
              localStorage.setItem('clientsFilters', JSON.stringify(context.filters))
            },
            updateDateFilter(updated, context){
              context.filters[updated.propName] = updated.value !==  null ? updated.value['0'] : null;
              localStorage.setItem('clientsFilters', JSON.stringify(context.filters))
            },
            updateCheckBoxFilter(updated, context){
              context.filters[updated.propName] = { ...updated.value };
              localStorage.setItem('clientsFilters', JSON.stringify(context.filters))
            },
            async deleteP(id){
                const updated = this.clients.filter((prospect) => prospect.id !== id);
                await setDataInStore('clients', updated)
                this.clients = this.clients.filter((prospect) => prospect.id !== id)
            },
            changeBoardModeClients(updated){
                this.boardModeClients = updated;
                localStorage.setItem('boardModeClients', updated);
            },
            async getData() {
              
              await fetchData(`prospects/${false}`, 'clients', 'clients', this, true, false, 'data.data', false, true, 1000, 0),
              await fetchData(`adds`, 'adds', 'adds', this),
              await fetchData(`users`, 'users', 'users', this),
              await fetchData(`sources`, 'sources', 'sources', this),
              await fetchData(`products/see`, 'products', 'products', this),
              
              this.setFilters(true);
              
            },
            setFilters(storeValues) {
              setFiltersMethod(this, this.clients, 'clientsFilters', storeValues)
            }
        },
        async created() {
          this.boardModeClients = JSON.parse(localStorage.getItem('boardModeClients')) ?? true;
          hasSpecificPermission('showFilters', 'page-filter', ["view_clients_filter"], this)
          hasSpecificPermission('showAdd', 'add', ["add_client"], this)
          hasSpecificPermission('showEdit', 'edit', ["edit_client"], this)
          hasSpecificPermission('showDelete', 'delete', ["delete_client"], this)
          hasSpecificPermission('showVisualize', 'page', ["view_clients_detail_page"], this)
          Promise.all([
            loadFromLocalStorage('clients', 'clients', this),
            loadFromLocalStorage('users', 'users', this),
            loadFromLocalStorage('sources', 'sources', this),
            loadFromLocalStorage('products', 'products', this),
          ]).then((results) =>{
            this.setFilters(false);  
            this.getData();
          })
        },
    }

</script>