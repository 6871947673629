<template>
    <div class="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 p-4" draggable="true">
        <div class="sm:flex sm:justify-between">
            <!-- Left side -->
            
            <div class="flex items-center">
                <!-- Drag button -->
                <button class="cursor-move mr-2">
                <span class="sr-only">Drag</span>
                <svg class="w-3 h-3 fill-slate-300 dark:fill-slate-600" viexBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 1h12v2H0V1Zm0 4h12v2H0V5Zm0 4h12v2H0V9Z" fill-rule="evenodd" />
                </svg>
                </button>
                <!-- Checkbox button -->
                <label class="flex items-center">
                <input type="checkbox" :disabled="todo.status == 'DONE' || disableCheck" :checked="todo.status == 'DONE'" class="form-checkbox w-5 h-5 rounded-full peer" @change="onChecked(todo)"/>
                <span class="font-medium text-slate-800 dark:text-slate-100 peer-checked:line-through ml-2 line-clamp-2">{{todo.name}}</span>
                </label>
            </div>
            
            <!-- Right side -->
            <div class="flex items-center my-2 md:my-0 justify-center md:justify-end space-x-3">
            <!-- Avatars -->
            <div class="flex shrink-0 -space-x-3 -ml-px">
                <a class="block" href="#0">
                <img class="rounded-full border-2 border-white dark:border-slate-800 box-content" src="../images/user-32-07.jpg" width="24" height="24" alt="User 07" />
                </a>
            </div>
            <font-awesome-icon class="h-4 text-green-500" v-if="todo.status == 'TODO' && this.toSignDocument?.documentSigned" :icon="['fas', 'file-circle-check']" />
            <button class="text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400 rounded-full disabled:opacity-35" @click.stop="previewD(document), modalOpen = true">
                <span class="sr-only">Preview</span>
                <font-awesome-icon class="h-4" v-if="!isLoading" :icon="['fas', 'eye']" />
                <font-awesome-icon v-else :icon="['fas', 'spinner']" spin />
            </button>
            
            </div>
            <ModalBasic id="preview-modal" :modalOpen="modalOpen" @close-modal="modalOpen = false, this.showData = false, this.documentLoadingSuccess = false" :title="'Preview Documento'" :size="'max-w-4xl'" :document-row="true">
                <!-- <PdfDocument v-if="this.showData && this.documentLoadingSuccess" :documentUrl="this.documentUrl" :isLoading="false"/>-->
                <span v-if="this.toSignDocument?.documentSigned">
                    <div class="flex justify-center py-3">

                        <iframe v-if="this.showData && this.documentLoadingSuccess" :src="this.documentUrl+ '#toolbar=1&navpanes=0'" width="100%" height="500px"  frameborder="0"></iframe> 
                        <button v-else :disabled="true" type="submit" class="btn bg-indigo-500 hover:bg-indigo-600 text-white mt-2">
                            <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
                        </button>
                    </div>
                    <div v-if="todo.status == 'TODO' && this.documentLoadingSuccess && permissions.canValidateDocument" class="border-t border-slate-200 dark:border-slate-700">
                        <div class="flex divide-x divide-slate-200 dark:divide-slate-700">
                            <button :disabled="disableInputs" @click.prevent.stop="validate({document, todo, action: 'validateContract'})" class="block flex-1 text-center text-sm text-slate-600 hover:text-slate-800 dark:text-slate-300 dark:hover:text-slate-200 font-medium px-3 py-4 group">
                                <div class="flex items-center justify-center">
                                    <ToolTip v-if="disableInputs" bg="dark">Es necesario validar todos los documentos primero para poder validar el contrato</ToolTip>
                                    <svg class="w-4 h-4 shrink-0 fill-current mr-1.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                        <path 
                                        :class="'text-green-500'"
                                        d="M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM305 273L177 401c-9.4 9.4-24.6 9.4-33.9 0L79 337c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L271 239c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/>
                                    </svg>
                                    <span>Validar</span>
                                </div>
                            </button>
                            <button @click.prevent.stop="reject({document, todo, action: 'rejectContract'})" class="block flex-1 text-center text-sm text-slate-600 hover:text-slate-800 dark:text-slate-300 dark:hover:text-slate-200 font-medium px-3 py-4 group">
                                <div class="flex items-center justify-center">
                                    <svg class="w-8 h-8 fill-current" viewBox="0 0 32 32">
                                        <path :class="'text-red-500'" d="M13 15h2v6h-2zM17 15h2v6h-2z" />
                                        <path :class="'text-red-500'" d="M20 9c0-.6-.4-1-1-1h-6c-.6 0-1 .4-1 1v2H8v2h1v10c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V13h1v-2h-4V9zm-6 1h4v1h-4v-1zm7 3v9H11v-9h10z" />
                                    </svg>
                                    <span>Rechazar</span>
                                </div>
                            </button>
                        </div>
                    </div>
                    
                </span>
                <vue-document-editor v-else class="editor h-[32rem] overflow-y-auto" ref="editor"
                v-model:content="content" :content-editable="false"
                :overlay="overlay"
                :zoom="zoom"
                :page_format_mm="page_format_mm"
                :page_margins="page_margins"
                :display="display" />

            </ModalBasic>
            <ModalBasic id="upload-modal" :modalOpen="modalUploadOpen" @close-modal="modalUploadOpen = false" :title="'Subir Documento'">
                <form @submit.prevent="saveD(document)" class="flex justify-center py-3">
                    <label class="btn bg-indigo-500 hover:bg-indigo-600 text-white mt-2 mr-2" >
                        <p>{{ selectedFileName || 'Elegir archivo' }}</p>
                        <input class="hidden" type="file" @change="handleFC">
                    </label>
                    <button :disabled="isLoading" type="submit" class="btn bg-indigo-500 hover:bg-indigo-600 text-white mt-2">
                        <span v-if="!isLoading">{{ $t('buttons.save') }}</span>
                        <span v-else>
                        <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
                        </span>
                    </button>
                </form>
            </ModalBasic>
        </div>
    </div>
</template>
<script>
    import ToolTip from '@/components/ToolTip.vue';
    import ModalBasic from '@/components/ModalBasic.vue';
    import VueDocumentEditor from '@/pages/DocumentEditor/DocumentEditor.vue';
    import { save, downloadDocument, handleFileChange, previewDocument, getDocument } from '@/utils/handleDocuments.js';

    export default {
        name: 'ToDoContractRow',
        components: {
            ToolTip,
            ModalBasic,
            VueDocumentEditor
        },
        props:{
            permissions: Object,
            disableInputs: Boolean,
            disableCheck: Boolean,
            todo: Object,
            document: Object,
            toSignDocument: Object
        },
        data(){
            return {
                modalOpen: false,
                modalUploadOpen: false,
                document_: this.document,
                content: this.document.contractMetaData.content,
                zoom: 0.8,
                zoom_min: 0.10,
                zoom_max: 5.0,
                page_format_mm: [256, 330],
                page_margins: "0.98in 1.18in",
                display: "grid", // ["grid", "vertical", "horizontal"]
                isLoading: false,
                file: null,
                selectedFileName: null,
                documentLoadingSuccess: false,
                showData: false,
                documentUrl: null,
            }
        },
        methods: {
            overlay (page, total) {
            // Add page numbers on each page
            let html = '<div style="position: absolute; bottom: 8mm; ' + ((page % 2) ? 'right' : 'left') + ': 10mm">Page ' + page + ' of ' + total + '</div>';

            // Add custom headers and footers from page 3
            // if(page >= 3) {
            //     html += '<div style="position: absolute; left: 0; top: 0; right: 0; padding: 3mm 5mm; background: rgba(200, 220, 240, 0.5)"><strong>MYCOMPANY</strong> example.com /// This is a custom header overlay</div>';
            //     html += '<div style="position: absolute; left: 10mm; right: 10mm; bottom: 5mm; text-align:center; font-size:10pt">MY COMPANY - example.com /// This is a custom footer overlay</div>';
            // }
            return html;
            },
            onChecked(todo){
                this.$emit('checked:value', todo);
            },
            onPressed(value){
                this.$emit('pressed:value', value);
            },
            handleFC(event) {
                handleFileChange(event, this, this.document.formats)
            },
            async downloadD(d) {    
                await downloadDocument({
                    clientId: this.todo.clientId,
                    id: this.toSignDocument.clientProductId,
                    name: this.document.name,
                    documentExtension: '.pdf'
                }, this);
            },
            previewD() {    
                if(this.toSignDocument?.documentSigned != true){
                    return;
                }
                previewDocument({
                    clientId: this.todo.clientId,
                    id: this.toSignDocument.clientProductId,
                    documentExtension: '.pdf'
                }, this)
            },
            async getD(document){
                await getDocument(document, this)
            },
            deleteDocument(id) {
                // Implement delete user logic
                this.$emit('delete-document', id);
            },
            async saveD(document) {
                save(document, this)
            },
            validate(object){
                this.$emit('validate:value', object);
            },
            reject(object){
                this.$emit('reject:value', object);
            },
        },
    }
</script>