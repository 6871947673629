
import api from '../src/interceptors/axiosInterceptos'; 
import { openDB, deleteDB } from 'idb';

// Function to clear IndexedDB and reset it to initial version
export async function resetDB() {
  // Close any open connections to the database
  await deleteDB('app-db');
  console.log('Database cleared');

  // Reinitialize to create the default stores again
  return await initDB();
}
// Initialization function that creates stores in the database
export async function initDB() {
  return openDB('app-db', 1, {
    upgrade(db) {
      const storeNames = [
        'adds',
        'categories', 'clients', 'contracts',
        'clientDocuments', 'clientInteractions', 'clientProducts', 'clientTodos',
        'documents',
        'events',
        'notifications',
        'prospects', 'products', 'productsEdit', 'policies',
        'productContracts', 'productDocuments', 'productPolicies', 'productSteps', 'productSurveys',
        'roles',
        'sources', 'steps', 'surveys',
        'todos',
        'users', 'userRoles',
      ];

      for (const storeName of storeNames) {
        if (!db.objectStoreNames.contains(storeName)) {
          db.createObjectStore(storeName, { keyPath: 'id' });
        }
      }
      db.createObjectStore('permissions', { keyPath: 'category' });
    },
  });
}

export async function validateSession() {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(`${process.env.API_URL}/auth/validate-session/${token}`);
    localStorage.setItem('token', response.data.token)
    localStorage.setItem('me', JSON.stringify(response.data.user))
    return response.data.valid;
  } catch (error) {
    console.error('Error validating session:', error);
    return false;
  }
}

export async function logoutUser() {
  try {
    const email = localStorage.getItem('user');
    const token = localStorage.getItem('token');
    const headers = { 
      "authorization": `Bearer ${token}`,
    };
    localStorage.clear();
    await resetDB()
    const response = await api.post(`${process.env.API_URL}/auth/logout/${email}`, {}, { headers });
  } catch (error) {
    console.error('Error logging out user:', error);
  }
}