<template>
  
  <button v-if="isCard" @click="canEdit || canVisualize ? editProspect(prospect) : ()=>{}" class="text-left bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 p-4">
    <!-- Body -->
    <div class="mb-3">
      <!-- Title -->
      <h2 class="font-semibold text-slate-800 dark:text-slate-100 mb-1">{{prospect.name}} {{prospect.lastName}}</h2>
      <!-- Content -->
      
    </div>
    <!-- Footer -->
    <div class="flex items-center justify-between">
      <!-- Left side -->
      <div class="flex shrink-0 -space-x-3 -ml-px">
        <a class="block" href="#0">
          <AvatarLetter :name="users[users.findIndex((e) => e.id === prospect.agentId)]?.username" :color="users[users.findIndex((e) => e.id === prospect.agentId)]?.color"/>
        </a>
      </div>
      <!-- Right side -->
      <div class="flex items-center">        
        <svg class="w-4 h-4 shrink-0 fill-current mr-1.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path 
          :class="prospect.callStatus == 'TODO' ? 'text-black-100': prospect.callStatus == 'DONE' ? 'text-green-500' : 'text-blue-500'" 
          d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/>
        </svg>
        <svg class="w-4 h-4 shrink-0 fill-current mr-1.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path 
          :class="prospect.appointmentStatus == 'TODO' ? 'text-black-100': prospect.appointmentStatus == 'DONE' ? 'text-green-500' : 'text-blue-500'" 
          d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zM329 305c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-95 95-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L329 305z"/>
        </svg>
        <svg class="w-4 h-4 shrink-0 fill-current mr-1.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
          <path 
          :class="prospect.closingStatus == 'TODO' ? 'text-black-100': prospect.closingStatus == 'DONE' ? 'text-green-500' : 'text-blue-500'" 
          d="M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM305 273L177 401c-9.4 9.4-24.6 9.4-33.9 0L79 337c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L271 239c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/>
        </svg>
        
        
      </div>
    </div>
    <span class="flex items-center justify-between mt-2">
      <ToolTip bg="dark">
        <div class="text-xs text-slate-200 whitespace-nowrap">
          Productos: <p></p> Pendientes de comenzar: <p class="text-red-500">{{ todoCount }}</p> En Progreso: <p class="text-blue-500">{{progressCount}}</p> Completado: <p class="text-green-500">{{ doneCount }}</p></div>
      </ToolTip>
      <ProgressBar  :progress-width="progressPercentage" :done-progress="donePercentage" />
    </span>
    <div class="mt-2">
      <!-- Title -->
      <h2 class="font-semibold text-slate-800 dark:text-slate-100 mb-1">Creado: {{formatDate(prospect.createdAt)}}</h2>
      <!-- Content -->  
    </div>
    
  </button>
  <tr v-else>
    <td class="px-2 first:pl-5 last:pr-5 py-3">
      <div class="font-medium text-sky-500">{{prospect.name}} {{prospect.lastName}}</div>
    </td>        
    
    <td v-if="canEdit || canDelete" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
      <div class="space-x-1">
        <button v-if="canEdit" class="text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400 rounded-full" @click="editProspect(prospect)">
          <span class="sr-only">Edit</span>
          <svg class="w-8 h-8 fill-current" viewBox="0 0 32 32">
              <path d="M19.7 8.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM12.6 22H10v-2.6l6-6 2.6 2.6-6 6zm7.4-7.4L17.4 12l1.6-1.6 2.6 2.6-1.6 1.6z" />
          </svg>
        </button>
        <DeleteButton v-if="canDelete" :clickHandler="() => deleteProspect(prospect.id)" />
      </div>
    </td>
  </tr>  
</template>

<script>
import ToolTip from '@/components/ToolTip.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import AvatarLetter from '@/components/AvatarLetter.vue'
import DeleteButton from '@/components/buttons/DeleteButton.vue'
export default {
  name: 'ProspectsTableItem',
  props: {
    prospect: Object, 
    isCard: Boolean,
    users: Array,
    canVisualize: {
      type: Boolean,
      default: function(){
        return false
      }
    },
    canEdit: {
      type: Boolean,
      default: function(){
        return false
      }
    },
    canDelete: {
      type: Boolean,
      default: function(){
        return false
      }
    },
  },
  components: {
    ToolTip,
    ProgressBar,
    DeleteButton,
    AvatarLetter,
  },
  data(){
    return {
      progressPercentage: 0,
      donePercentage: 0,
      doneCount: 0,
      progressCount: 0,
      todoCount: 0
    }
  },
  methods: {
    calculateProgressAndDone(elements) {
      let totalTasks = elements.length;
      let doneCount = 0;
      let progressCount = 0;

      elements.forEach(task => {
        if (task.clientproducts.status == 'DONE') {
          doneCount++;
        } else if(task.clientproducts.status == 'INPROGRESS') {
          progressCount++;
        }
      });
      

      this.donePercentage = totalTasks > 0 ? (doneCount / totalTasks) * 100 : 0;
      this.progressPercentage =  totalTasks > 0 ? (progressCount / totalTasks) * 100 : 0;
      this.progressCount = progressCount;
      this.doneCount = doneCount;
      this.todoCount = totalTasks - progressCount - doneCount;
    },
    editProspect(prospect) {
      if(this.$route.path == '/clients'){
        this.$router.push(`/clients/${prospect.id}`);
      } else {
        this.$router.push(`/prospects/${prospect.id}`);
      }
    },
    deleteProspect(id) {
      // Implement delete user logic
      this.$emit('delete-prospect', id);
    },
    formatDate(dateStr) {
      if (!dateStr) return null;
      const date = new Date(dateStr);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
  },
  created(){
    this.calculateProgressAndDone(this.prospect.Products);
  }
}
</script>