<template>
  <BasePage>
    <PageTitle :title="`Agregar Prospecto ✨`" />
    <div class="max-w mx-auto w-full px-4 py-2">
      <form @submit.prevent="add" ref="form">
        <span v-if="selectedSection === 'section1'">
          <SearchProspectForm :form-data_="this.formData" :selected-section_="this.selectedSectionSearch"
            @update:section="updateSectionSearch" />
          <FormComponent
          :show-asign-agent-button="modifyAgent" :can-add="showAddSources"
          :show-data="this.showData"
          :form-data_="this.formData.client"
          @update:model="updateFormData"
          />
          <!-- nameOrCurpCorrection": false,
          "nssUnification": false,
          "unknownWeeksAcknowledgment": false -->
          <MainButtonClickHandler :text="'Buscar'" :is-loading="isLoading" :click-handler="searchProspect" />
          <div v-if="this.showData" class="grid grid-cols-1 sm:grid-cols-6 gap-2">
            <div class="sm:col-span-3 ">
                <ProspectionValuesTable 
                :title="'Valores'"
                :key="this.componentKey"
                :is-loading="isLoading" :model="this.formData.values" @update:model="updateList"
                :list_="this.formData.values"/>
            </div>  
            <div class="sm:col-span-3" >
                <AplicableProductsTable 
                :is-board="false"
                :key="this.componentKey"
                :title="'Productos Aplicables'"
                :title-modal="'Agregar Producto'"
                @update:model="updateList"
                :list_="this.formData.aplicableProducts" :full-list="this.products"/>
            </div>
          </div>
          <div v-if="this.showData" class="grid grid-cols-1 sm:grid-cols-6 gap-2 mt-2">
            <div class="sm:col-span-6">
              <ErrorsTable 
                :title="'Errores'"
                :key="this.componentKey"
                :is-loading="isLoading" :model="this.formData.values" @update:model="updateList"
                :list_="this.formData.errors"/>
            </div>
          </div>
          <button v-if="this.showData" @click="downloadPdf">Download PDF Semanas</button>

          <PdfDocument v-if="this.showData && this.documentLoadingSuccess" :documentUrl="this.formData.documentUrl" @continue-form="console.log(1)" :public-key="'1'" :isLoading="false"/>

          <div v-if="this.showData" class="flex">
            <MainButtonClickHandler :text="'Vender propiedad'" :is-loading="isLoading" :click-handler="() => {selectedSection = 'section2'}" />
            <MainButtonClickHandler class="ml-2" :text="'Comprar propiedad'" :is-loading="isLoading" :click-handler="() => {selectedSection = 'section5'}" />
            
          </div>
        </span>
        <span v-else-if="selectedSection === 'section2'">
          <AvaclickForm :form-data_="this.formData.avaclick" @update-section:value="updateSection"
            @submit-form:form="handleSubmitAvaclick"/>
        </span>
        <span v-else-if="selectedSection === 'section3'">
          <TwinsForm 
            :isLoading="isLoading" 
            :twins="this.formData.twins" :selected-images_="this.selectedImages"
            @update-section:value="updateSection" 
            @submit-selected-images="handleSelectedImages"
          />
        </span>
        <span v-else-if="selectedSection === 'section4'">
          <ValuationTable @update-section:value="updateSection" :valuations_="this.formData.valuations_"/>
        </span>
        <span v-else-if="selectedSection === 'section5'">
          <MapForm @update-section:value="updateSection"/>
        </span>
        <div v-if="this.showData" class="flex">
          <MainButtonClickHandler :text="'Enviar Al Cliente'" :is-loading="isLoading" :click-handler="sendToClient" />
          <MainButtonClickHandler class="ml-2" :text="'Descargar'" :is-loading="isLoading" :click-handler="download" />
          <MainButtonClickHandler class="ml-2" :text="'Terminar'" :is-loading="isLoading" :click-handler="add" />
        </div>
      </form>
    </div>
  </BasePage>
</template>
  
  <script>
  import { fetchData } from '@/utils/fetchData.js';
  import { loadFromLocalStorage } from '@/utils/loadFromLocalStorage.js';
  import { hasSpecificPermission } from '@/utils/hasSpecificPermission.js';
  import { createProspectComponentData } from '@/services/prospects/createProspectComponentData';
  import { getWeeksDocument, downloadPdfDocument, searchProspectMethod, addProspect, sendToClientMethod, 
    sumbitAvaclickForm, submitTwingForm, downloadReport } from '@/services/prospects/createProspectServices';

  import BasePage from '@/components/BasePage.vue'; 
  import PageTitle from '@/components/PageTitle.vue';
  import PdfDocument from '@/components/PdfDocument';
  import ErrorsTable from '@/partials/prospects/create/ErrorsTable.vue';
  import MapForm from '@/partials/prospects/create/Avaclick/MapForm.vue';
  import FormComponent from '@/partials/prospects/create/FormComponent.vue';
  import TwinsForm from '@/partials/prospects/create/Avaclick/TwinsForm.vue';
  import AvaclickForm from '@/partials/prospects/create/Avaclick/AvaclickForm.vue';
  import SearchProspectForm from '@/partials/prospects/create/SearchProspectForm.vue';
  import ValuationTable from '@/partials/prospects/create/Avaclick/ValuationTable.vue';
  import MainButtonClickHandler from '@/components/buttons/MainButtonClickHandler.vue';
  import ProspectionValuesTable from '@/partials/prospects/create/ProspectionValuesTable.vue';
  import AplicableProductsTable from '@/partials/prospects/create/AplicableProductsTable.vue';

  export default {
    name: 'CreateProspectPage',
    components: {
      MapForm,
      BasePage,
      PageTitle,
      TwinsForm,
      ErrorsTable,
      PdfDocument,
      AvaclickForm,
      FormComponent,
      ValuationTable,
      SearchProspectForm,
      MainButtonClickHandler,
      ProspectionValuesTable,
      AplicableProductsTable,
    },
    data(){
      return createProspectComponentData()
    },
    methods: {
      updateSectionSearch(section){
        this.selectedSectionSearch = section
      },
      updateSection(section){
        this.selectedSection = section;
      },
      async handleSubmitAvaclick(formData){
        const response = await sumbitAvaclickForm(this, formData)
        if (!response) {
          return;
        };
        if(formData.constructionNA || formData.terrainNA){
          //post gemelos
          this.formData.folio = response.data.Folio
          this.formData.twins = response.data.Gemelo
          this.updateSection('section3')
        } else {
          // post gemelos (valuacion directa)
          this.formData.valuations_ = [
            {'name': 'VALOR COMERCIAL APROX', 'amount': parseInt(response.data.PrecioCliente.replace(/[$,]/g, '')), viable: true, stars: 2},
            {'name': 'VALOR COMERCIAL MIN', 'amount': parseInt(response.data.PrecioClienteMIn.replace(/[$,]/g, '')), viable: false, stars: 0},
          ]
          this.updateSection('section4')
        }
      },
      async handleSelectedImages(selectedImageIds) {
        this.selectedImages = selectedImageIds;
        const response = await submitTwingForm(this)
        if (!response) {
          return;
        };
        this.formData.valuations_ = response
        this.updateSection('section4')
      },
      async getProducts() {
        this.products = await fetchData(`products/see`, 'products', 'products', this);
      },
      getD(){
        getWeeksDocument(this)
      },
      downloadPdf(){
        downloadPdfDocument(this)
      },
      searchProspect(){
        searchProspectMethod(this)
      },
      add(){
        addProspect(this)
      },
      sendToClient(){
        sendToClientMethod(this)
      },
      download(){
        downloadReport(this)
      },
      updateFormData(updated){
        this.formData.client.curp = updated.curp;
        this.formData.client.email = updated.email;
        this.formData.client.phone = updated.phone;
        this.formData.client.sourceId = updated.source;
        this.formData.client.agentId = updated.agentId;
      },
      updateList(updated, propName){
          this.formData[propName] = updated;
      },
    },
    async created(){
      hasSpecificPermission('showAddSources', 'add', ["add_sources"], this)
      hasSpecificPermission('showAdd', 'add', ["add_prospect"], this)
      hasSpecificPermission('modifyAgent', 'action', ["modify_agent_prospect_at_creation"], this)
      console.log(this.modifyAgent)
      await loadFromLocalStorage('products', 'products', this);
      this.getProducts()
    }
  }
  </script>