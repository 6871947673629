<template>
  <div v-if="!isLoading" class="relative inline-flex">
    <button
      ref="trigger"
      class="inline-flex justify-center items-center group"
      aria-haspopup="true"
      @click.prevent="dropdownOpen = !dropdownOpen"
      :aria-expanded="dropdownOpen"
    >
      <img class="w-8 h-8 rounded-full" :src="UserAvatar" width="32" height="32" alt="User" />
      <div class="flex items-center truncate">
        <span class="truncate ml-2 text-sm font-medium dark:text-slate-300 group-hover:text-slate-800 dark:group-hover:text-slate-200">{{this.user.username}}</span>
        <svg class="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400" viewBox="0 0 12 12">
          <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
        </svg>
      </div>
    </button>
    <transition
      enter-active-class="transition ease-out duration-200 transform"
      enter-from-class="opacity-0 -translate-y-2"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-out duration-200"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-show="dropdownOpen" class="origin-top-right z-10 absolute top-full min-w-44 bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1" :class="align === 'right' ? 'right-0' : 'left-0'">
        <div class="pt-0.5 pb-2 px-3 mb-1 border-b border-slate-200 dark:border-slate-700">
          <div class="font-medium text-slate-800 dark:text-slate-100">{{this.user.username}}</div>
          <div v-if="this.user.roles !== undefined" class="text-xs text-slate-500 dark:text-slate-400 italic">{{this.user?.roles[0].name}}</div>
        </div>
        <ul
          ref="dropdown"
          @focusin="dropdownOpen = true"
          @focusout="dropdownOpen = false"
        >
          <!-- <li>
            <router-link class="font-medium text-sm text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-400 flex items-center py-1 px-3" to="#0" @click="dropdownOpen = false">Settings</router-link>
          </li> -->
          <li>
            <router-link class="font-medium text-sm text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-400 flex items-center py-1 px-3" to="#0" @click="dropdownOpen = false, signOut()">Sign Out</router-link>
          </li>
        </ul>
      </div> 
    </transition>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import {resetDB} from '../../middleware/middleware'
import Swal from 'sweetalert2';

import api from '@/interceptors/axiosInterceptos'; 
import UserAvatar from '../images/user-avatar-32.png';

export default {
  name: 'DropdownProfile',
  props: ['align'],
  setup() {

    const dropdownOpen = ref(false)
    const trigger = ref(null)
    const dropdown = ref(null)

    // close on click outside
    const clickHandler = ({ target }) => {
      if (!dropdownOpen.value || dropdown.value.contains(target) || trigger.value.contains(target)) return
      dropdownOpen.value = false
    }

    // close if the esc key is pressed
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen.value || keyCode !== 27) return
      dropdownOpen.value = false
    }

    onMounted(() => {
      document.addEventListener('click', clickHandler)
      document.addEventListener('keydown', keyHandler)
    })

    onUnmounted(() => {
      document.removeEventListener('click', clickHandler)
      document.removeEventListener('keydown', keyHandler)
    })

    return {
      dropdownOpen,
      trigger,
      dropdown,
    }
  },
  data(){
    return {
      UserAvatar: UserAvatar,
      user: {},
      isLoading: false
    }
  },
  methods: {
    async signOut(){
      try {
        this.isLoading = true;
        const email = localStorage.getItem('user');
        const token = localStorage.getItem('token');
        const headers = { 
          "authorization": `Bearer ${token}`,
        };
        const response = await api.post(`${process.env.API_URL}/auth/logout/${email}`, {}, { headers });
        if(response.status == 200){
          const token = response.data.token;

          // Store the token in localStorage
          localStorage.clear();
          await resetDB()
          // Inside a component method or event handler
          this.$router.push('/'); // Or use a custom route if defined
          this.isLoading = false;

        } else {
            // Handle authentication error (e.g., display error message)            
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: response?.statusText ?? response,
            })
            this.isLoading = false;
          }
        // Handle successful authentication (e.g., store user token, redirect, etc.)
        this.isLoading = false;
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: error.response?.statusText ?? error,
        })
            
          
        // Handle authentication error (e.g., display error message)
        this.isLoading = false;
      }
    }
  },
  created(){
    this.isLoading = true;
    this.user = JSON.parse(localStorage.getItem('me')) || {};
    this.isLoading = false;
  }
}
</script>