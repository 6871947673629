<template>
    <button v-if="showDocumentTodos && product.extras?.length > 0 && canGenerateContract(product)" class="btn mb-2 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300 mr-2" 
        @click.stop.prevent="generateContract(product)">
        <span v-if="!isLoading">Generar Contrato</span>
        <span v-else>
            <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
        </span>
    </button>
</template>

<script>
    export default {
        name: 'GenerateContractButton',
        props: {
            isLoading: Boolean,
            product: Object,
            showDocumentTodos: Boolean,
            generateContract: Function,
        },
        methods: {
            canGenerateContract(item) {
                return item.extras.every(element => 
                    element.clientDocument.requestDuringSignature === true || 
                    element.todo.status === 'DONE' ||
                    element.clientDocument.documentExtension !== null
                ) && item.contractExtras?.length == 0 &&
                item.clientSurveys.every(element => 
                    element.surveyMetaData.requestDuringSignature === true || 
                    (element.surveyMetaData.required && element.answer !== null)||
                    (!element.surveyMetaData.required)
                );
            },
        }
    }

</script>