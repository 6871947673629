import { initDB } from '../../middleware/middleware';


export async function loadFromLocalStorage(key, targetProperty, context) {
  const result = await readFromIdb(key)
  context[targetProperty] = result
  
  // const temp = localStorage.getItem(key);
  // const cached = temp !== "undefined" && temp !== null ? JSON.parse(temp) : [];
  // if (cached.length) {
  //   context[targetProperty] = cached;
  // }
}

export async function readFromIdb(key){
  try{
    const db = await initDB();
    const tx = db.transaction(key, 'readonly');
    const result = await tx.store.getAll()
    return result;
  } catch (error) {
    console.error("Failed to load prospects from database:", key);
    return []; // Set to an empty array to ensure it's defined
  }
}

export async function setDataInStore(storeName, data) {
  try {
    const db = await initDB();
    const tx = db.transaction(storeName, 'readwrite');
    const store = tx.objectStore(storeName);

    // Clear all existing data in the store
    await store.clear();

    // Add the new data
    if (Array.isArray(data)) {
      for (const item of data) {
        const cloneableItem = JSON.parse(JSON.stringify(item)); // Deep clone
        await store.put(cloneableItem);
      }
    } else {
      const cloneableData = JSON.parse(JSON.stringify(data)); // Deep clone for single object
      await store.put(cloneableData);
    }

    await tx.done;
  } catch (error) {
    console.error("Failed to store data:", error, storeName, data);
  }
}
