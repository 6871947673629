<template>
    <div>
        <!-- Title -->
        Gemelos
        <!-- Image Grid -->
        <div 
        @click="toggleSelection(twin.Id)" 
        :class="{'border-indigo-500': isSelected(twin.Id), 'border-gray-300': !isSelected(twin.Id)}"
        class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 mt-4 relative cursor-pointer border-2 p-2 rounded-lg" 
        v-for="twin in twins" :key="twin.Id">
            <div 
                class="relative cursor-pointer border-2 p-2 rounded-lg"
                v-for="(image, index) in twin.Fotos" 
                :key="index" 
                
            >
                <img :src="image" class="object-cover h-32 w-full rounded-md" alt="Image" />
                <!-- Selection Circle -->
                
            </div>
            <div class="absolute top-2 right-2">
                <span class="w-6 h-6 rounded-full border-2 flex items-center justify-center"
                        :class="{'border-indigo-500 bg-indigo-500': isSelected(twin.Id), 'border-gray-400 bg-white': !isSelected(twin.Id)}">
                    <span v-if="isSelected(twin.Id)" class="text-white">✔</span>
                </span>
            </div>
        </div>

        <!-- Buttons -->
        <div class="flex mt-6">
            <MainButtonClickHandler :text="'Regresar'" :is-loading="isLoading" :click-handler="()=>{updateSection('section2')}" />
            <MainButtonClickHandler class="ml-2" :text="'Continuar'" :is-loading="isLoading" :click-handler="submitSelection" />
        </div>
    </div>
</template>

<script>
import MainButtonClickHandler from '@/components/buttons/MainButtonClickHandler.vue';
export default {
    name: 'TwinsForm',
    emits: ['update-section:value', 'submit-selected-images'],
    components: {
        MainButtonClickHandler
    },
    props: {
        isLoading: Boolean,
        twins: Array,
        selectedImages_: Array
    },
    data() {
        return {
            selectedImages: this.selectedImages_
        };
    },
    methods: {
        updateSection(section) {
            this.$emit('update-section:value', section);
        },
        toggleSelection(imageId) {
            if (this.isSelected(imageId)) {
                this.selectedImages = this.selectedImages.filter(id => id !== imageId); // Deselect if already selected
            } else {
                this.selectedImages.push(imageId); // Select the image
            }
        },
        isSelected(imageId) {
            return this.selectedImages.includes(imageId);
        },
        submitSelection() {
            // Emit the selected images to the parent component
            this.$emit('submit-selected-images', this.selectedImages);
        }
    }
};
</script>

<style scoped>
/* You can add custom styles here if needed */
</style>
