import Swal from 'sweetalert2';
import { createRouter, createWebHistory } from 'vue-router'

import { validateSession, logoutUser, resetDB } from '../middleware/middleware';
import {extractUniquePermissions} from '@/utils/extractUniquePermissions'

import { 
  TodoPage,
  SigninPage,
  SourcesPage,
  ClientsPage,
  CalendarPage,
  RolesListPage,
  ResetPassword,
  UsersListPage,
  ProspectsPage,
  ContractsPage,
  EditUsersPage,
  ForbiddenPage,
  ForgotPassword,
  CategoriesPage,
  CreateUsersPage,
  PermissionsPage,
  ProductsListPage,
  ClientsDetailPage,
  SourcesDetailPage,
  NotificationsPage,
  ProductsDetailPage,
  CreateProductsPage,
  ProspectDetailPage,
  CreateProspectPage,
  TempTextEditorPage,
} from './pages';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: SigninPage,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/reset-password/:token', // Add a dynamic parameter for the reset token
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/users',
    name: 'UsersPage',
    component: UsersListPage,
    meta: { requiresAuth: true, requiredPermission: ['view_users_page'] },
  },
  {
    path: '/users/create-user',
    name: 'CreateUsersPage',
    component: CreateUsersPage,
    meta: { requiresAuth: true, requiredPermission: ['add_user'] },
  },
  {
    path: '/users/:id', // Add a dynamic parameter for the user ID
    name: 'EditUsersPage',
    component: EditUsersPage,
    meta: { requiresAuth: true, requiredPermission: ['edit_user'] }, // Add this line if the route requires authentication
  },
  {
    path: '/roles', // Add a dynamic parameter for the user ID
    name: 'RolesListPage',
    component: RolesListPage,
    meta: { requiresAuth: true, requiredPermission: ['view_roles_page'] }, // Add this line if the route requires authentication
  },
  {
    path: '/calendar', // Add a dynamic parameter for the user ID
    name: 'CalendarPage',
    component: CalendarPage,
    meta: { requiresAuth: true, requiredPermission: ['view_calendar_page'] }, // Add this line if the route requires authentication
  },
  {
    path: '/todo-board', // Add a dynamic parameter for the user ID
    name: 'TodoPage',
    component: TodoPage,
    meta: { requiresAuth: true, requiredPermission: ['view_todos_page'] }, // Add this line if the route requires authentication
  },
  // {
  //   path: '/roles/create-role',
  //   name: 'CreateRolePage',
  //   component: CreateRolePage,
  //   meta: { requiresAuth: true, requiredPermission: ['view_users_page'] },
  // },
  {
    path: '/products',
    name: 'ProductsListPage',
    component: ProductsListPage,
    meta: { requiresAuth: true, requiredPermission: ['view_services_list_page'] },
  },
  {
    path: '/products/create-product',
    name: 'CreateProductsPage',
    component: CreateProductsPage,
    meta: { requiresAuth: true, requiredPermission: ['add_service'] },
  },
  {
    path: '/products/contracts', // Add a dynamic parameter for the product ID
    name: 'ProductContracts',
    component: ContractsPage,
    meta: { requiresAuth: true, requiredPermission: ['view_services_contracts_page'] },
  },
  {
    path: '/products/create-contract',
    name: 'TempTextEditorPage',
    component: TempTextEditorPage,
    meta: { requiresAuth: true, requiredPermission: ['add_contracts'] },
  },
  {
    path: '/products/:id', // Add a dynamic parameter for the product ID
    name: 'ProductDetailPage',
    component: ProductsDetailPage,
    meta: { requiresAuth: true, requiredPermission: ['edit_service', 'view_services_detail_page'] },
  },
  {
    path: '/sources/', // Add a dynamic parameter for the product ID
    name: 'SourcesPage',
    component: SourcesPage,
    meta: { requiresAuth: true, requiredPermission: ['view_sources_page'] },
  },
  {
    path: '/sources/:id', // Add a dynamic parameter for the product ID
    name: 'SourcesDetailPage',
    component: SourcesDetailPage,
    meta: { requiresAuth: true, requiredPermission: ['view_sources_detail_page'] },
  },
  {
    path: '/prospects/', // Add a dynamic parameter for the product ID
    name: 'ProspectsPage',
    component: ProspectsPage,
    meta: { requiresAuth: true, requiredPermission: ['view_prospects_list_page'] },
  },
  {
    path: '/prospects/create-prospect', // Add a dynamic parameter for the product ID
    name: 'CreateProspectPage',
    component: CreateProspectPage,
    meta: { requiresAuth: true, requiredPermission: ['view_prospects_create_page', 'add_prospect'] },
  },
  {
    path: '/prospects/:id', // Add a dynamic parameter for the product ID
    name: 'ProspectDetailPage',
    component: ProspectDetailPage,
    meta: { requiresAuth: true, requiredPermission: ['edit_prospect', 'view_prospects_detail_page'] },
  },
  {
    path: '/clients/', // Add a dynamic parameter for the product ID
    name: 'ClientsPage',
    component: ClientsPage,
    meta: { requiresAuth: true, requiredPermission: ['view_clients_list_page'] },
  },
  {
    path: '/clients/:id', // Add a dynamic parameter for the product ID
    name: 'ClientsDetailPage',
    component: ProspectDetailPage,
    meta: { requiresAuth: true, requiredPermission: ['edit_client', 'view_clients_detail_page'] },
  },
  {
    path: '/categories',
    name: 'CategoriesPage',
    component: CategoriesPage,
    meta: { requiresAuth: true, requiredPermission: ['view_categories_page'] },
  },
  {
    path: '/permissions',
    name: 'PermissionsPage',
    component: PermissionsPage,
    meta: { requiresAuth: true, requiredPermission: ['view_permissions_page'] },
  },
  {
    path: '/notifications',
    name: 'NotificationsPage',
    component: NotificationsPage,
    meta: { requiresAuth: true, requiredPermission: ['view_notifications_page'] },
  },
  {
    path: '/forbidden',
    name: 'ForbiddenPage',
    component: ForbiddenPage,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from, next) => {
  // localStorage.clear();
  //   await resetDB()
  const isAuthenticated = await validateSession();
  if (to.matched.some(route => route.meta.requiresAuth)) {
    
    if (!isAuthenticated) {
      await logoutUser(); 
      next('/'); // Redirect to login page if not authenticated
    } else {
      const uniquePermissions = extractUniquePermissions(null);
      if (!uniquePermissions.map( element => element.name).some(e => to.meta.requiredPermission.includes(e))) {
        // User lacks the required permission
        return next({ name: 'ForbiddenPage' }); // Redirect to a forbidden page or any other appropriate route
      }
      next(); // Proceed to the requested route
    }
  } else {
    if(to.fullPath === '/' && isAuthenticated){
      const uniquePermissions = extractUniquePermissions('page');
      if (uniquePermissions.map( e => e.params.path).includes('/calendar')) {
        // User lacks the required permission
        return next('/calendar')
      }
      if (uniquePermissions.length > 0){
        next(uniquePermissions[0].params.path);
      }else {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'Rol mal configurado no cuenta con permisos para visualizar ninguna pagina',
        })
      }
      next('/')
    }else{

      next(); // Proceed to the requested route (no authentication required)
    }
  }
  
  
});

export default router