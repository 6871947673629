<template>
    <BasePage>
      <span v-if="isLoading">
        <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
      </span>
      <div v-else class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <PageTitle :title="`${product.name} ✨`" />

        <!-- Filters -->
        
        <div class="mb-4 border-b border-slate-200 dark:border-slate-700">
          <div v-if="sections.length == 0">El usuario no tiene configurado poder visualizar ninguna subseccion dentro de detalle de producto</div>
          <ul v-else class="text-sm font-medium flex flex-nowrap -mx-4 sm:-mx-6 lg:-mx-8 overflow-x-scroll no-scrollbar">
            <li
              v-for="section in sections"
              :key="section.id"
              @click="selectedSection = section.id"
              class="pb-3 mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8"
            >
              <a
                class="whitespace-nowrap dark:hover:text-slate-300"
                :class="selectedSection == section.id ? 'text-indigo-500 hover:text-indigo-600' : 'text-slate-500 hover:text-slate-600'"
                :href="section.href"
              >
                {{ section.name }}
              </a>
            </li>
          </ul>
        </div>
        <!-- Content -->
        <div class="bg-white dark:bg-slate-800 shadow-lg rounded-sm mb-8">
          <div v-if="selectedSection === 'section1'">
            <GeneralPanel :is-loading="this.isLoading" @save="updateProduct" @force-render="forceRender" :product="this.product" :categories="this.categories" :can-edit="showEdit" />
          </div>

          <div v-else-if="selectedSection === 'section2'">
            <FeeAndExpensesPanel :is-loading="this.isLoading" @save="updateProduct" @force-render="forceRender" :product="this.product" :can-edit="showEdit"/>
          </div>

          <div v-else-if="selectedSection === 'section3'">
            <SurveysPanel :key="this.componentKey" @force-render="forceRender" :productid="this.productId" :surveys="this.surveys" :productSurveys="this.productSurveys" :can-edit="showEdit"/>
          </div>
          <div v-else-if="selectedSection === 'section4'">
            <StepsPanel :key="this.componentKey" @force-render="forceRender" :productId="this.productId" :steps_="this.steps" :productSteps_="this.productSteps" :can-edit="showEdit"/>
          </div>
          <div v-else-if="selectedSection === 'section5'">
            <ContractsPanel :key="this.componentKey" :productId="this.productId" :contracts_="this.contracts" :productContracts_="this.productContracts" :can-edit="showEdit"/>
          </div>
          <div v-else-if="selectedSection === 'section6'">
            <DocumentsPanel :key="this.componentKey" :productId="this.productId" :documents_="this.documents" :productDocuments_="this.productDocuments" :can-edit="showEdit"/>
          </div>
          <div v-else-if="selectedSection === 'section7'">
            <PoliciesPanel :key="this.componentKey" :productId="this.productId" :policies_="this.policies" :productPolicies_="this.productPolicies" :can-edit="showEdit"/>
          </div>
        </div>
      </div>
    </BasePage>
  </template>
  
  <script>
  import { fetchData } from '@/utils/fetchData.js';
  import { hasSpecificPermission } from '@/utils/hasSpecificPermission.js';
  import {extractUniquePermissions} from '@/utils/extractUniquePermissions.js';
  import {transformPermissionsToSections} from '@/utils/transformPermissionsToSections.js';
  import { loadFromLocalStorage, readFromIdb, setDataInStore, } from '@/utils/loadFromLocalStorage.js';
  
  import Swal from 'sweetalert2';
  import BasePage from '@/components/BasePage.vue';
  import api from '@/interceptors/axiosInterceptos'; 
  import PageTitle from '@/components/PageTitle.vue';
  import StepsPanel from '@/partials/products/detail/StepsPanel.vue';
  import SurveysPanel from '@/partials/products/detail/SurveysPanel.vue';
  import GeneralPanel from '@/partials/products/detail/GeneralPanel.vue';
  import PoliciesPanel from '@/partials/products/detail/PoliciesPanel.vue';
  import ContractsPanel from '@/partials/products/detail/ContractsPanel.vue';
  import DocumentsPanel from '@/partials/products/detail/DocumentsPanel.vue';
  import FeeAndExpensesPanel from '@/partials/products/detail/FeeAndExpensesPanel.vue';
  
  export default {
    name: 'ProductsDetailPage',
    components: {
      BasePage,
      PageTitle,
      StepsPanel,
      SurveysPanel,
      GeneralPanel,
      PoliciesPanel,
      ContractsPanel,
      DocumentsPanel,
      FeeAndExpensesPanel
    },
    data(){
      return {
        componentKey: 0,
        showEdit: false,
        isLoading: false,
        categories: [],
        productId: null,
        product: {},
        surveys: [],
        productSurveys: [],
        steps: [],
        productSteps: [],
        contracts: [],
        productContracts: [],
        documents: [],
        productDocuments: [],
        policies: [],
        productPolicies: [],
        selectedSection: null,
        sections: []
      }
    },
    methods: {
      async forceRender(){
        const productId = this.$route.params.id;
        const products = await readFromIdb('productsEdit');

        const temp = products.find(product => product.id === productId) || {};
        const productSurveys = await readFromIdb('productSurveys');
        const productSteps = await readFromIdb('productSteps');
        this.product = temp;
        this.productSurveys = productSurveys;
        this.productSteps = productSteps;
        this.componentKey++
      },
      async updateProduct(formData){
        try {
          this.isLoading = true;
          const token = localStorage.getItem('token');
          const headers = { 
            "authorization": `Bearer ${token}`,
          };
          const response = await api.put(`${process.env.API_URL}/products/${this.productId}`, formData, { headers });

          if(response.status == 200){
            Swal.fire({
                icon: 'success',
                title: 'Exito!',
                text: 'El producto se a actualizado exitosamente.',
            });
          }
          let productsList = await readFromIdb('productsEdit');
              
          const index = productsList.findIndex((product) => product.id === this.productId);

          if (index !== -1) {
            // Remove the item from the array
            productsList.splice(index, 1);
            // Insert the updated item at the original index
            productsList.splice(index, 0, response.data);
            setDataInStore('productsEdit', productsList)
            this.product = response.data;
          }
          this.isLoading = false;
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: error.response.data.message,
          });
          this.isLoading = false;
          const productId = this.$route.params.id;
          const products = await readFromIdb('productsEdit');

          const temp = products.find(product => product.id === productId) || {};
          this.product = temp;
        }
      },
      async fetchProductFromLocalStorage(productId) {
        const products = await readFromIdb('productsEdit');
        return products.find(product => product.id === productId) || {};
      },
      getData(){
        this.isLoading = true
        Promise.all([
          fetchData(`categories`, 'categories', 'categories', this, false),
          fetchData(`surveys`, 'surveys', 'surveys', this, false),
          fetchData(`product-surveys/${this.productId}`, 'productSurveys', 'productSurveys', this, false),
          fetchData(`steps`, 'steps', 'steps', this, false),
          fetchData(`product-steps/${this.productId}`, 'productSteps', 'productSteps', this, false),
          fetchData(`contracts`, 'contracts', 'contracts', this, false),
          fetchData(`product-contracts/${this.productId}`, 'productContracts', 'productContracts', this, false),
          fetchData(`documents`, 'documents', 'documents', this, false),
          fetchData(`product-documents/${this.productId}`, 'productDocuments', 'productDocuments', this, false),
          fetchData(`policies`, 'policies', 'policies', this, false),
          fetchData(`product-policies/${this.productId}`, 'productPolicies', 'productPolicies', this, false),
        ]).then((results) => {
          this.componentKey++
          this.isLoading = false
        })
      }
    },
    async created() {
      this.productId = this.$route.params.id;
      this.product = await this.fetchProductFromLocalStorage(this.productId);
      hasSpecificPermission('showEdit', 'edit', ["edit_service"], this)
      const uniquePermissions = extractUniquePermissions('subsection');
      this.sections = transformPermissionsToSections(uniquePermissions, 'services', this)

      Promise.all([
        loadFromLocalStorage('documents', 'documents', this),
        loadFromLocalStorage('categories', 'categories', this),
        loadFromLocalStorage('surveys', 'surveys', this),
        loadFromLocalStorage('steps', 'steps', this),
        loadFromLocalStorage('contracts', 'contracts', this),
        loadFromLocalStorage('productDocuments', 'productDocuments', this),
        loadFromLocalStorage('policies', 'policies', this),
        loadFromLocalStorage('productPolicies', 'productPolicies', this),
      ]).then((results) =>{
        this.componentKey++

        this.getData()
      })
    }
  }
  </script>