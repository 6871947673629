export const statesApiMarket = [
    {'name': 'Aguascalientes', value: 1},
    {'name': 'Baja California', value: 2},
    {'name': 'Baja California Sur', value: 3},
    {'name': 'Campeche', value: 4},
    {'name': 'Coahuila', value: 5},
    {'name': 'Colima', value: 6},
    {'name': 'Chiapas', value: 7},
    {'name': 'Chihuahua', value: 8},
    {'name': 'Ciudad de México', value: 9},
    {'name': 'Durango', value: 10},
    {'name': 'Guanajuato', value: 11},
    {'name': 'Guerrero', value: 12},
    {'name': 'Hidalgo', value: 13},
    {'name': 'Jalisco', value: 14},
    {'name': 'Estado de México', value: 15},
    {'name': 'Michoacán', value: 16},
    {'name': 'Morelos', value: 17},
    {'name': 'Nayarit', value: 18},
    {'name': 'Nuevo León', value: 19},
    {'name': 'Oaxaca', value: 20},
    {'name': 'Puebla', value: 21},
    {'name': 'Querétaro', value: 22},
    {'name': 'Quintana Roo', value: 23},
    {'name': 'San Luis Potosí', value: 24},
    {'name': 'Sinaloa', value: 25},
    {'name': 'Sonora', value: 26},
    {'name': 'Tabasco', value: 27},
    {'name': 'Tamaulipas', value: 28},
    {'name': 'Tlaxcala', value: 29},
    {'name': 'Veracruz', value: 30},
    {'name': 'Yucatán', value: 31},
    {'name': 'Zacatecas', value: 32},
    {'name': 'Doble nacionalidad', value: 87},
    {'name': 'Nacido extranjero o naturalizado', value: 88},
]