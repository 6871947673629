<template>
    <BasePage>
        <PageTitle :title="`Editar Usuario ✨`" />
        <div class="max-w-sm mx-auto w-full px-4 py-8">

            <form @submit.prevent="updateUser">
                <div class="space-y-4 mb-2">
                    <div>
                        <label class="block text-sm font-medium mb-1" for="email">Nombre de Usuario</label>
                        <input id="name" class="form-input w-full" type="text" placeholder="john_doe" v-model="editedUser.username" required />
                    </div>
                    <div>
                        <label class="block text-sm font-medium mb-1" for="email">Correo Electronico</label>
                        <input id="email" class="form-input w-full" type="email" placeholder="user@afieconsultores.com" v-model="editedUser.email" required />
                    </div>
                    <div>
                        <label class="block text-sm font-medium mb-1" for="password">Contraseña</label>
                        <input id="password" class="form-input w-full" autoComplete="on" placeholder="******" v-model="editedUser.password" :type="showPassword ? 'text' : 'password'" />
                    </div>
                    <div>
                        <button type="button" @click="togglePasswordVisibility" class="btn bg-indigo-500 hover:bg-indigo-600 text-white">
                            {{ showPassword ? 'Ocultar' : 'Mostrar' }}
                        </button>
                        <button type="button" @click="generateRandomPassword" class="btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-indigo-500 ml-3">
                            Generar contraseña aleatoria
                        </button>
                    </div>
                    <div v-if="user.password?.length > 0 && !isStrongPassword(user.password)" class="password-error">
                        La contraseña debe tener al menos 8 caracteres y debe incluir mayúsculas, minúsculas, un dígito y un carácter especial.
                    </div>
                    <DropdownRolesFilter :key="componentKey" :select-all_="false" label="Roles" :options="roleOptions" :model="selectedRoles" @update:model="updateUserRoles"/>
                    <p v-if="!isAtLeastOneRoleSelected">Por favor seleccione al menos un rol.</p>
                </div>
                <SubmitMainButton :is-loading="isLoading" />
            </form>
        </div>
    </BasePage>
  </template>
  
  <script>
  import { ref } from 'vue';
  import { editData } from '@/utils/editData.js';
  import { fetchData } from '@/utils/fetchData.js';
  import { loadFromLocalStorage, readFromIdb } from '@/utils/loadFromLocalStorage.js';
  
  import BasePage from '@/components/BasePage.vue';
  import PageTitle from '@/components/PageTitle.vue';
  import SubmitMainButton from '@/components/buttons/SubmitMainButton.vue'
  import DropdownRolesFilter from '@/partials/users/DropdownRolesFilter.vue';

  
  export default {
    name: 'EditUsersPage',
    data() {
      return {
        componentKey: 0,
        isEditing: false,
        isLoading: false,
        userId: null,
        user: {},
        editedUser: {},
        roles: [],
        selectedRoles: {},
        showPassword: false,
        roleOptions: []
      };
    },
    components: {
        BasePage,
        PageTitle,
        SubmitMainButton,
        DropdownRolesFilter,
    },
    setup() {
  
      const sidebarOpen = ref(false)
  
      return {
        sidebarOpen,
      }  
    },
    computed: {
        isAtLeastOneRoleSelected() {
          return Object.values(this.selectedRoles).some(role => role);
        },
    },
    methods: {
        async updateUser() {
            this.isLoading = true
            const selectedIds = [];
            for (const role in this.selectedRoles) {
                if (this.selectedRoles[role]) {
                    const index = this.roles.findIndex((element) => element.name === role);
                    if (index !== -1) {
                        selectedIds.push(this.roles[index].id);
                    }
                }
            }
            const body = {
              "username": this.editedUser.username,
              "email": this.editedUser.email,
              "roles": selectedIds,
              "password": this.editedUser.password
            }
            await editData(`users/${this.userId}`, body, 'El usuario se a actualizado exitosamente.', 'users', 'users', this, true)
            this.isLoading = false
            this.$router.push('/users');
          
        },
        async fetchUserFromLocalStorage(userId) {
            const users = await readFromIdb('users');
            const roles = await readFromIdb('roles')
            const a = roles.map(role => role.name)
            for (const role in a) {
                this.selectedRoles[a[role]] = false;
            }
            return users.find(user => user.id === userId) || {};
        },
        addTag (newTag) {
            const tag = {
                name: newTag,
                code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
            }
            this.taggingOptions.push(tag)
            this.taggingSelected.push(tag)
        },
        isStrongPassword(password) {
            // Minimum length of 8 characters
            const minLength = 8;
        
            // Check if the password meets the minimum length requirement
            if (password.length < minLength) {
                return false;
            }
        
            // Check if the password contains at least one uppercase letter
            if (!/[A-Z]/.test(password)) {
                return false;
            }
        
            // Check if the password contains at least one lowercase letter
            if (!/[a-z]/.test(password)) {
                return false;
            }
        
            // Check if the password contains at least one digit
            if (!/\d/.test(password)) {
                return false;
            }
        
            // Check if the password contains at least one special character
            if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
                return false;
            }
        
            // All criteria passed, the password is strong
            return true;
        },
        
        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },
        generateRandomPassword() {
            const length = 12; // You can adjust the length as needed
            const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
            const digits = '0123456789';
            const specialCharacters = '!@#$%^&*(),.?":{}|<>';

            const allCharacters = uppercaseLetters + lowercaseLetters + digits + specialCharacters;

            const getRandomChar = (characters) => {
                const randomIndex = Math.floor(Math.random() * characters.length);
                return characters.charAt(randomIndex);
            };

            const randomPassword =
            getRandomChar(uppercaseLetters) +
            getRandomChar(lowercaseLetters) +
            getRandomChar(digits) +
            getRandomChar(specialCharacters) +
            // Fill the remaining characters randomly
            Array.from({ length: length - 4 }, () => getRandomChar(allCharacters)).join('');

            this.user.password = randomPassword;
        },
        toggleRoleDropdown() {
            this.showRoleDropdown = !this.showRoleDropdown;
        },
        updateUserRoles(updatedRoles) {
            this.selectedRoles = { ...updatedRoles };
        },
    },
    async created() {
        // Fetch user ID from the URL
        this.userId = this.$route.params.id;
        
        // Fetch user data from localStorage based on the user ID
        this.user = await this.fetchUserFromLocalStorage(this.userId);
        console.log(this.user)

        // Initialize editedUser with a copy of user for editing
        this.editedUser = {
            username: this.user.username,
            email: this.user.email,
            roles: this.user.roles !== undefined ? this.user.roles.map(role => role.id) : [],
            password: null
        };
        const a = this.user.roles !== undefined ? this.user.roles.map(role => role.id) : []
        for (const role in a) {
            this.selectedRoles[a[role]] = true;
        }
        await loadFromLocalStorage('roles', 'roles', this),
        this.roleOptions = this.roles.map(role => role.name);
        console.log(this.roleOptions)
        this.componentKey++
    }
  }
  </script>