import { getProperty } from '@/utils/getProperty.js'

export function applyDateFilters(elements, dateFilters, context) {
    return dateFilters.reduce((result, filter) => filterByDate(result, filter, context.filters[`${filter}Start`], context.filters[`${filter}End`]), elements);
};

export function filterByDate(elements, propName, filterStart, filterEnd) {
    if (filterStart) elements = elements.filter(element => new Date(element[propName]) >= filterStart);
    if (filterEnd) elements = elements.filter(element => new Date(element[propName]) <= filterEnd);
    return elements;
};

export function applySelectFilters(elements, filtersPropName, propName, context) {
    return elements.filter(element => getSelected(context.filters[filtersPropName]).some(option => getProperty(element, propName) === null ? ('Nulo' === option || 'null' === option) : (getProperty(element, propName) === option)))    
}

export function applyTextFilters(elements, textFilters, context) {
    return elements.filter(element => textFilters.every(field => element[field]?.toLowerCase().includes(context.filters[`${field}`]?.toLowerCase())))
};

export function applySliderFilters(elements, sliderFilters, context) {
    
    return sliderFilters.reduce((result, filter) => filterBySlider(result, filter, context.filters[`${filter}`]), elements, sliderFilters);
};

export function filterBySlider(elements, propName, filter) {
    return elements.filter(element => {
      if (filter[0] !== undefined && element[propName] !== null && element[propName] < filter[0]) return false;
      if (filter[1] !== undefined && element[propName] !== null && element[propName] > filter[1]) return false;
      return true;
    });
};

export function filterByStepBlocked(elements, filtersPropName, context) {
    const bloquedSelected = getSelected(context.filters[filtersPropName]).some(e=>e=='Bloqueado')
    const unblockedSelected = getSelected(context.filters[filtersPropName]).some(e=>e=='Desbloqueado')

    return elements.filter(element => {
        const blockedBy = element?.clientproductstep?.stepMetaData?.blockedBy;
      
        if (bloquedSelected && blockedBy && blockedBy.length > 0 ) {
            return true;
        }
        if (unblockedSelected && (!blockedBy || blockedBy.length === 0)) {
            
            return true;
        }
        return false
    });
}

export function getSelected(array) {
    return Object.keys(array).filter(option => array[option] === "" ? "Vacio" : array[option] === null ? "Nulo" : array[option]);
};