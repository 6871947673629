<template>
  <ModalBasic id="modal" :modalOpen="addModalOpen" @close-modal="addModalOpen = false" title="Agregar T&C" size="w-1/3">
    <!-- Modal content -->
    <form @submit.prevent="addPolicy">
      <div class="px-5 py-4">
        <div class="space-y-3">
          <div>
            <label class="block text-sm font-medium mb-1" for="name">T&C <span class="text-rose-500">*</span></label>
            <textarea id="name" v-model="policy.name" class="form-textarea w-full px-2 py-1" placeholder="Descripcion" rows="4"></textarea>
          </div>
        </div>
      </div>
      <!-- Modal footer -->
      <div class="px-5 py-4 border-t border-slate-200 dark:border-slate-700">
        <div class="flex flex-wrap justify-end space-x-2">
          <SubmitMainButton :is-loading="isLoading" :show-cancel-button="true" :click-handler="() => {addModalOpen = false}" />
        </div>
      </div>
    </form>
  </ModalBasic>
    <div class="grow">
      <!-- Panel body -->
      <div class="p-6 space-y-6">
        <h2 class="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">{{ $t('labels.policies') }}</h2>
        <form @submit.prevent="add">
          <div class="grid grid-cols-1 sm:grid-cols-6 gap-2">
              <div v-if="canEdit" class="sm:col-span-3" > 
                <label class="block text-sm font-medium mb-1">
                    {{ $t('labels.policies') }}:
                </label>
                <div class="flex w-full">
                  <input v-if="this.policies?.length < 1" id="policy" class="form-input w-full" type="text" placeholder="Crea al menos un T&C" :disabled="true" />
                  
                  <DropdownFullQuestions v-else
                  :property-key="'name'"
                  :dropdownOptions="this.policies" 
                  :model="this.formData.policy" @update:model="updateSelectedPolicy" />
  
                  <div class="pr-2"></div>
                  <button class="text-white-500 hover:text-white-600 rounded-full">
                    <svg @click.stop="addModalOpen = true" class="w-4 h-8 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                        <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                    </svg>
                  </button>
                </div>
                <button :disabled="isLoading || this.policies?.length < 1" @click.stop="addPolicyToProduct" class="btn bg-indigo-500 hover:bg-indigo-600 text-white mt-2">
                  <span v-if="!isLoading">{{ $t('buttons.add') }}</span>
                  <span v-else>
                    <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
                  </span>
                </button>
              </div>
              <div class="sm:col-span-3" > 
                <SubCategoriesTable 
                  :can-edit="canEdit"
                  :is-draggable="false"
                  :title="'T&C del producto'" 
                  :empty-label="'Selecciona un t&c del dropdown para agregarlo a los t&c del producto'"
                  :property-key="'policy.name'"
                  :values="this.productPolicies" :is-loading="isLoading" 
                  @update:model="deleteProductPolicy"
                  />
              </div>
          </div>
        </form>
      </div>
    </div>  
  </template>
  
  <script>
  import { postData } from '@/utils/postData.js';
  import { deleteData } from '@/utils/deleteData.js';
  import { setDataInStore } from '@/utils/loadFromLocalStorage.js';
  
  import Swal from 'sweetalert2';
  import ModalBasic from '@/components/ModalBasic.vue';
  import SubCategoriesTable from './SubCategoriesTable.vue';
  import DropdownFullQuestions from './DropdownFullQuestions.vue';
  import SubmitMainButton from '@/components/buttons/SubmitMainButton.vue'
  
  export default {
    name: 'PoliciesPanel',
    components: {  
        ModalBasic,
        SubmitMainButton,
        SubCategoriesTable,
        DropdownFullQuestions,
    },
    props: {
      productId: String,
      policies_: Array,
      productPolicies_: Array,
      canEdit: {
        type: Boolean,
        default: function(){
          return false
        }
      },
    },
    data(){
      return {
        addModalOpen: false,
        policies: this.policies_,
        productPolicies: this.productPolicies_,
        isLoading: false,
        formData: {
          policy: this.policies_.length > 0 ?this.policies_[0] : null,
        },
        policy: {
            name: '',
        }
      }
    },
    methods: {
      async addPolicy(){
        const response = await postData('policies', { name: this.policy.name }, this);
        if (!response) {
            return
        };
        this.policies.push(response);
        this.formData.policy = response;
        await this.addPolicyToProduct();
        this.policy = {
            name: '',
        };
        this.addModalOpen = false;
      },
      async deleteProductPolicy(index){
        const id = this.productPolicies[index].id
        const response = await deleteData('product-policies', id, this, 'El T&C del producto se a eliminado exitosamente.', true, true)
        if(!response){
            return
        }
        this.productPolicies.splice(index, 1);
        await setDataInStore('productPolicies', this.productPolicies)
      },
      async addPolicyToProduct(){
        const body = {
          productId: this.productId,
          policyId: this.formData.policy.id
        }
        const response = await postData('product-policies', body, this);
        if (!response) {
            return
        };
        Swal.fire({
          icon: 'success',
          title: 'Exito!',
          text: 'El T&C se agrego al producto.',
        });
        this.productPolicies.push(response);
        await setDataInStore('productPolicies', this.productPolicies)
      },
      updateSelectedPolicy(policy){
        this.formData.policy = policy;
      },
    },
  }
  </script>