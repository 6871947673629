// src/data/prospectData.js
export function createProspectComponentData() {
    return {
      selectedSectionSearch: 'searchByCurp',
      selectedSection: 'section1',
      modifyAgent: false,
      showAddSources: false,
      showAdd: false,
      products: [],
      documentLoadingSuccess: false,
      componentKey: 0,
      showData: false,
      isLoading: false,
      formData: {
        searchByCurp: {
          curp: '',
        },
        searchByNss: {
          nss: '',
        },
        searchByData: {
          name: '',
          fatherLastName: '',
          motherLastName: '',
          birthDay: null,
          birthMonth: null,
          birthYear: null,
          birthState: 1,
          sex: 'H'
        },
        client: {
          curp: '',
          email: '',
          phone: '',
          sourceId: null,
          agentId: null,
          automaticAsignation: false,
        },
        documentUrl: null,
        values: [],
        aplicableProducts: [],
        errors: [],
        avaclick: {
          address: '',
          coordinates: { lat: null, lng: null },
          construction: null,
          constructionNA: true,
          terrain: null,
          terrainNA: true,
          typeProperty: 3,
          rooms: 0,
          bathrooms: 0,
          constructionYear: 2024,
          price: null,
          kitchen: true,
          priceNA: true,
          conservation: 2,
        },
        folio: null,
        twins: [],
        valuations_: []
      },
      selectedImages: [],
    };
  }
  