// i18n.js
import { createI18n } from 'vue-i18n';

const messages = {
  en: {
    // English language configurations
    labels: {
      users: 'Users',
      roles: 'Roles',
      products: 'Products',
      sources: 'Sources',
      prospects: 'Prospects',
      success: 'Success!',
      welcome: 'Welcome',
      login: 'Login',
      loading: 'Loading',
      actions: 'Actions',
      name: 'Name',
      username: 'Username',
      email: 'Email',
      password: 'Password',
      forgotPassword: 'Forgot your password?',
      resetPassword: 'Reset it here',
      description: 'Description',
      priority: 'Priority',
      excludeFromProspection: 'Exclude from prospection process',
      maximumAge: 'Maximum Age',
      minimumAge: 'Minimum Age',
      minimumBalance: 'Minimum Balance',
      maximumBalance: 'Maximum Balance',
      minimumInfonavitPoints: 'Minimum Points',
      maximumInfonavitPoints: 'Maximum Points',
      maximumLastSalary: 'Max Last Salary',
      canHaveCredit: 'Can Have Credit',
      creditMustNotBeActive: 'Credit Must Not Be Active',
      mustHaveCredit: 'Must have credit',
      minimumWeeks: 'Minimum Weeks',
      minimumDiscountedWeeks: 'Min Discounted W',
      maximumWeeks: 'Maximum Weeks',
      maximumDiscountedWeeks: 'Max Discounted W',
      category: 'Category',
      isLaw73: 'Law 73',
      isActive: 'Active',
      activeBefore1982: 'Active Before 1982',
      canVisualizeQuotedWeeksReport: 'Visualize Report',
      minimumDaysInactive: 'Minimum Days Inactive',
      maximumDaysInactive: 'Maximum Days Inactive',
      fractionMaximumQuotedWeeksInactive: 'Max Inactive Q/Weeks',
      precandidate: 'Product which im precandidate',
      telephone: 'Telephone',
      curp: 'Curp',
      nss: 'Nss',
      balance: 'Infonavit Balance',
      quotedWeeks: 'Quoted Weeks',
      discountedWeeks: 'Discounted Weeks',
      reintegratedWeeks: 'Reintegrated Weeks',
      noRecordsFound: 'No records found',
      error: 'Error',
      dob: 'Date of Birth',
      createdAt: 'Created At',
      created: 'Created',
      appointment: 'Appointment',
      call: 'Call',
      contract: 'Contract',
      done: 'Done',
      rejected: 'Rejected',
      status: 'Status',
      multiselectPlaceHolder: 'Search or add a tag',
      questions: 'Questions',
      steps: 'Steps',
      contracts: 'Contracts',
      documents: 'Documents',
      policies: 'T&C',
      month: 'Month',
      week: 'Week',
      day: 'Day',
      street: 'Street',
      number: 'Number',
      neighborghood: 'Neighborghood',
      state: 'State',
      zipCode: 'Zip Code',
      birthState: 'Birth State',
      civilState: 'Marital State',
      startLaborActivityDate: 'Start Labor Act.',
      endLaborActivityDate: 'End Labor Act.',
      creditCloseDate: 'Date End Credit',
      sar92: 'SAR 92',
      sar97: 'SAR 97',
      creditStatus: 'Credit Status',
      creditSituation: 'Credit Situation',
      creditType: 'Credit Type',
      infonavitCreditNumber: 'Credit Number',
      infonavitDebt: 'Debt',
      infonavitCreditCurrency: 'Currency',
      unpaidMonths: 'Unpaid Months',
      infonavitRFC: 'RFC infonavit',
      infonavitEmail: 'Email infonavit',
      infonavitPhone: 'Phone infonavit',
      creditOpenDate: 'Date open credit',
      srvBalance: 'SRV balance',
      afore: 'AFORE',
      seller: 'Seller',
      lastName: 'Last Name',
      platform: 'Platform',
      fatherLastName: 'Fathers Last Name',
      motherLastName: 'Mothers Last Name',
      birthDay: 'Day of Birth',
      birthMonth: 'Month of Birth',
      birthYear: 'Year of Birth',
      sex: 'Biological Sex'
      // Add other labels here
    },
    buttons: {
      add: 'Add',
      save: 'Save',
      submit: 'Submit',
      update: 'Update',
      cancel: 'Cancel',
      edit: 'Edit',
      delete: 'Delete',
      changePassword: 'Change password',
      createNewRole: 'Create new role',
      clearFilter: 'Clear All Filters',
      previous: 'Previous',
      next: 'Next',
      logout: 'Logout',
      filters: 'Filters',
      apply: 'Apply',
      source: 'Source',
      desiredProduct: 'Desired product',
      advertisement: 'Advertisement'
      // Add other button labels here
    },
    texts: {
      startProduct: 'Start product',
      cantStartProduct: 'To be able to start the product first you need to realize the appointment and the call with the client'
    }
    // Add other text configurations here
  },
  es: {
    // Spanish language configurations
    labels: {
      users: 'Usuarios',
      roles: 'Roles',
      products: 'Productos',
      sources: 'Promotores',
      prospects: 'Prospectos',
      success: 'Exito!',
      welcome: 'Bienvenido',
      login: 'Iniciar Sesion',
      loading: 'Cargando',
      actions: 'Acciones',
      name: 'Nombre',
      username: 'Nombre de usuario',
      email: 'Correo Electronico',
      password: 'Contraseña',
      forgotPassword: '¿Olvidaste tu contraseña?',
      resetPassword: 'Reestablecela aqui',
      description: 'Descripcion',
      priority: 'Prioridad',
      excludeFromProspection: 'Excluir del proceso de prospeccion',
      maximumAge: 'Edad Maxima',
      minimumAge: 'Edad Minima',
      minimumBalance: 'Saldo Minimo',
      minimumInfonavitPoints: 'Puntos Minimos',
      maximumBalance: 'Saldo Maximo',
      maximumInfonavitPoints: 'Puntos Maximos',
      maximumLastSalary: 'Max Ultimo Salario',
      canHaveCredit: 'Credito',
      creditMustNotBeActive: 'Credito Activo',
      mustHaveCredit: 'Debe tener Credito',
      minimumWeeks: 'Semanas Minimas',
      minimumDiscountedWeeks: 'Min Sem Descontadas',
      maximumWeeks: 'Semanas Maximas',
      maximumDiscountedWeeks: 'Max Sem Descontadas',
      category: 'Categoria',
      isLaw73: 'Ley 73',
      isActive: 'Activo',
      activeBefore1982: 'Activo antes de 1982',
      canVisualizeQuotedWeeksReport: 'Visualiza Reporte',
      minimumDaysInactive: 'Dias inactivos Minimos',
      maximumDaysInactive: 'Dias inactivos Maximos',
      fractionMaximumQuotedWeeksInactive: 'Max Inactivo C/Semanas',
      precandidate: 'Producto del cual es precandidato',
      telephone: 'Telefono',
      curp: 'Curp',
      nss: 'Nss',
      balance: 'Saldo Infonavit',
      quotedWeeks: 'Semanas Cotizadas',
      discountedWeeks: 'Semanas Descontadas',
      reintegratedWeeks: 'Semanas Reintegradas',
      noRecordsFound: 'No se encontraron registros',
      error: 'Error',
      dob: 'Fecha de nacimiento',
      createdAt: 'Fecha de creacion',
      created: 'Creado',
      appointment: 'Cita',
      call: 'Llamada',
      contract: 'Contrato',
      done: 'Finalizado',
      rejected: 'Rechazado',
      status: 'Estatus',
      multiselectPlaceHolder: 'Busca o agrega una opcion',
      questions: 'Preguntas',
      steps: 'Pasos',
      contracts: 'Contratos',
      documents: 'Documentos',
      policies: 'T&C',
      month: 'Mes',
      week: 'Semana',
      day: 'Dia',
      street: 'Calle',
      number: 'Numero',
      neighborghood: 'Colonia',
      state: 'Estado',
      zipCode: 'Codigo Postal',
      birthState: 'Estado de nacimiento',
      civilState: 'Estado civil',
      startLaborActivityDate: 'Comienzo Act. Laboral',
      endLaborActivityDate: 'Fin Act. Laboral',
      creditCloseDate: 'Fin Credito',
      sar92: 'SAR 92',
      sar97: 'SAR 97',
      creditStatus: 'Estatus Credito',
      creditSituation: 'Situacion Credito',
      creditType: 'Tipo Credito',
      infonavitCreditNumber: 'Numero credito',
      infonavitDebt: 'Deuda credito',
      infonavitCreditCurrency: 'Moneda',
      unpaidMonths: 'Meses omisos',
      infonavitRFC: 'RFC infonavit',
      infonavitEmail: 'Email infonavit',
      infonavitPhone: 'Telefono infonavit',
      creditOpenDate: 'Apertura credito',
      srvBalance: 'SRV Saldo',
      afore: 'AFORE',
      seller: 'Vendedor',
      lastName: 'Apellido',
      source: 'Fuente',
      platform: 'Plataforma',
      desiredProduct: 'Producto deseado',
      advertisement: 'Anuncio',
      fatherLastName: 'Apellido Paterno',
      motherLastName: 'Apellido Materno',
      birthDay: 'Dia de Nacimiento',
      birthMonth: 'Mes de Nacimiento',
      birthYear: 'Año de Nacimiento',
      sex: 'Sexo Biologico'
      // Add other labels here
    },
    buttons: {
      add: 'Agregar',
      save: 'Guardar',
      submit: 'Enviar',
      update: 'Actualizar',
      cancel: 'Cancelar',
      edit: 'Editar',
      delete: 'Eliminar',
      changePassword: 'Cambiar contraseña',
      createNewRole: 'Crear nuevo rol',
      clearFilter: 'Limpiar Filtros',
      previous: 'Anterior',
      next: 'Siguiente',
      logout: 'Cerrar Sesion',
      filters: 'Filtros',
      apply: 'Aplicar'
      // Add other button labels here
    },
    texts: {
      startProduct: 'Iniciar producto',
      cantStartProduct: 'Para poder iniciar el producto es necesario haber realizado la cita y la llamada al cliente'
    }
    // Add other text configurations here
  },
  // Add configurations for other languages as needed
};

const i18n = createI18n({
  locale: 'es', // Set the default locale
  messages,
});

export default i18n;
