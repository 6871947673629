<template>
    <button :disabled="isLoading" @click.prevent.stop="clickHandler" class="btn bg-indigo-500 hover:bg-indigo-600 text-white mt-2">
        <span v-if="!isLoading">{{ text }}</span>
        <span v-else>
        <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
        </span>
    </button>
  </template>
    
  <script>
    export default {
      name: 'MainButtonClickHandler',
      props: {
        isLoading: Boolean,
        text: String,
        clickHandler: {
          type: Function,
          required: false,
        },
      },
    };
  </script>
    