<template>
    <div class="grow">
      <!-- Panel body -->
      <div class="p-6 space-y-6">
        <h2 class="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">Interacciones</h2>
        <InteractionsTable 
        @add:model="addClientInteraction" :can-add="canAdd"
        @remove:model="deleteClientInteraction"
        :is-loading="isLoading" :title="'Interacciones'" :title-modal="'Agregar Interaccion'" :list="this.clientInteractions"/>
    </div>
  </div>  
</template>

<script>
    import { postData } from '@/utils/postData.js';
    import { deleteData } from '@/utils/deleteData.js';
    import {hasSpecificPermission} from '@/utils/hasSpecificPermission'
    import { setDataInStore } from '@/utils/loadFromLocalStorage.js';

    import Swal from 'sweetalert2';
    import InteractionsTable from './interactions/InteractionsTable.vue';
    export default {
        name: 'InteractionsPanel',
        components: {  
            InteractionsTable
        },
        props: {
            prospectId: String,
            clientInteractions_: Array
        },
        data(){
            return {
                canAdd: false,
                clientInteractions: this.clientInteractions_,
                isLoading: false,
            }
        },
        methods: {
            async addClientInteraction(content){
                const interaction = {
                    content: content,
                    userId: null,
                    clientId: this.prospectId
                }
                const response = await postData('client-interactions/', interaction, this, true)
                if (!response) {
                    return;
                };
                Swal.fire({
                    icon: 'success',
                    title: 'Exito!',
                    text: 'La interaccion se agrego.',
                });
                this.clientInteractions.push(response.data);
                await setDataInStore('clientInteractions', this.clientInteractions)
            },
            async deleteClientInteraction(index){
                const id = this.clientInteractions[index].id
                const response = await deleteData('client-interactions', id, this, 'La interaccion se a eliminado exitosamente.', true, true)
                if(!response){
                    return
                }
                this.clientInteractions.splice(index, 1);
                await setDataInStore('clientInteractions', this.clientInteractions)
            },
        },        
        created(){
            hasSpecificPermission('canAdd', 'add', ["add_interaction"], this)
        }
    }
</script>