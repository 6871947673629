export const propertyValuationExpenses = [
        {'name': 'LIBERA TU DEUDA', 'fees': [
        {'name': 'ADEUDO INFONAVIT', 'amount': 10000},
        {'name': 'ADEUDO AGUA', 'amount': 400*12*1, 
            options: [{name: '1 año', amount: 400*12*1},{name: '2 años', amount: 400*12*2},{name: '3 años', amount: 400*12*3},{name: '4 años', amount: 400*12*4},{name: '5 años', amount: 400*12*5},{name: '6 años', amount: 400*12*6},{name: '7 años', amount: 400*12*7}]},
        {'name': 'ADEUDO PREDIAL', 'amount': 200*12*1,
            options: [{name: '1 año', amount: 200*12*1},{name: '2 años', amount: 200*12*2},{name: '3 años', amount: 200*12*3},{name: '4 años', amount: 200*12*4},{name: '5 años', amount: 200*12*5},{name: '6 años', amount: 200*12*6},{name: '7 años', amount: 200*12*7}]},
        {'name': 'ADEUDO LUZ', 'amount': 40*12*1, 
            options: [{name: '1 año', amount: 40*12*1},{name: '2 años', amount: 40*12*2},{name: '3 años', amount: 40*12*3},{name: '4 años', amount: 40*12*4},{name: '5 años', amount: 40*12*5},{name: '6 años', amount: 40*12*6},{name: '7 años', amount: 40*12*7}]},
        {'name': '¿TIENE ESCRITURAS?', 'amount': 0, 
            options: [{name: 'NO', amount: 0}, {name: 'SI', amount: 10000}]},
        {'name': 'CONDICIONES DE LA CASA', 'amount': 9000, 
            options: [{name: 'BUENAS', amount: 9000}, {name: 'REGULARES', amount: 18000}, {name: 'MALAS', amount: 35000}, {name: 'VANDALIZADA', amount: 45000}]},
        {'name': 'COMISION FIJA', 'amount': 14000},
        {'name': 'COMISION VARIABLE', 'amount': 0.04},
    ],},
    {'name': 'TRASPASO INFONAVIT', 'fees': [
        {'name': 'ADEUDO INFONAVIT', 'amount': 10000},
        {'name': 'ADEUDO AGUA', 'amount': 10000, 
            options: [{name: 1, amount: 400*12*1},{name: 2, amount: 400*12*2},{name: 3, amount: 400*12*3},{name: 4, amount: 400*12*4},{name: 5, amount: 400*12*5},{name: 6, amount: 400*12*6},{name: 7, amount: 400*12*7}]},
        {'name': 'ADEUDO PREDIAL', 'amount': 10000,
            options: [{name: 1, amount: 200*12*1},{name: 2, amount: 200*12*2},{name: 3, amount: 200*12*3},{name: 4, amount: 200*12*4},{name: 5, amount: 200*12*5},{name: 6, amount: 200*12*6},{name: 7, amount: 200*12*7}]},
        {'name': 'ADEUDO LUZ', 'amount': 10000, 
            options: [{name: 1, amount: 40*12*1},{name: 2, amount: 40*12*2},{name: 3, amount: 40*12*3},{name: 4, amount: 40*12*4},{name: 5, amount: 40*12*5},{name: 6, amount: 40*12*6},{name: 7, amount: 40*12*7}]},
        {'name': '¿TIENE ESCRITURAS?', 'amount': 10000, 
            options: [{name: 'SI', amount: 10000}, {name: 'NO', amount: 0}]},
        {'name': 'CONDICIONES DE LA CASA', 'amount': 10000, 
            options: [{name: 'BUENAS_CONDICIONES', amount: 9000}, {name: 'CONDICIONES_REGULARES', amount: 18000}, {name: 'MALAS_CONDICIONES', amount: 35000}, {name: 'VANDALIZADA_TOTALMENTE', amount: 45000}]},
        {'name': 'COMISION FIJA', 'amount': 7000},
        {'name': 'COMISION VARIABLE', 'amount': 0.04},
    ],}
]