<template>
  <div class="my-4">
    <div class="flex flex-col sm:flex-row sm:items-center sm:justify-between">
      <nav class="mb-4 sm:mb-0 sm:order-1" role="navigation" aria-label="Navigation">
        <ul class="flex justify-center">
          <li class="ml-3 first:ml-0">
            <button @click="prevPage" :disabled="currentPage === 1" class="btn bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 text-slate-300 dark:text-slate-600">&lt;- Previous</button>
          </li>
          <li class="ml-3 first:ml-0">
            <button @click="nextPage" :disabled="currentPage === totalPages" class="btn bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-indigo-500">Next -&gt;</button>
          </li>
        </ul>
      </nav>
      <div class="text-sm text-slate-500 dark:text-slate-400 text-center sm:text-left">
        Mostrando <span class="font-medium text-slate-600 dark:text-slate-300">{{ startIndex }}</span> a <span class="font-medium text-slate-600 dark:text-slate-300">{{ endIndex }}</span> de <span class="font-medium text-slate-600 dark:text-slate-300">{{ prospects?.length }}</span> resultados
      </div>
    </div>
  </div>
  <div v-if="!isBoard" class="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
    <GenericTable
      :title='title'
      :items="filteredProspects"
      :columns="columns"
      :itemsPerPage="itemsPerPage"
      :show-pagination="true"
    >
      <template v-slot:table-rows="{ items }">
        <ProspectsTableItem
          v-for="prospect in items"
          :key="prospect.id" :can-delete="canDelete" :can-edit="canEdit"
          :prospect="prospect" :users="users"
          :value="prospect.id"
          @delete-prospect="deleteProspect"
        />
      </template>
    </GenericTable>
    
  </div>

  <div v-else class="w-full grid grid-cols-12 gap-x-4 gap-y-8">
    <TasksGroups v-for="element in boardProspects" :key="element.title" :title="element.title" :total="element.prospects.length">
      <ProspectsTableItem
        v-for="prospect in element.prospects"
        :key="prospect.id" :users="users"
        :is-card="true"
        :prospect="prospect"
        :value="prospect.id" :can-delete="canDelete" :can-edit="canEdit" :can-visualize="canVisualize"
        @delete-prospect="deleteProspect"
      />
    </TasksGroups>
  </div>

</template>

<script>
import Swal from 'sweetalert2';
import api from '@/interceptors/axiosInterceptos';
import GenericTable from '@/components/GenericTable.vue'
import ProspectsTableItem from './ProspectsTableItem.vue';
import TasksGroups from '@/partials/tasks/TasksGroups.vue';

export default {
  name: 'ProspectsTable',
  components: {
    TasksGroups,
    GenericTable,
    ProspectsTableItem,
  },
  emits: ["delete-p"],
  props: {
    title: String,
    isTodo: Function,
    isInProgress: Function,
    isCompleted: Function,
    isRejected: Function,
    isBoard: Boolean,
    users: Array,
    sources: Array,
    filters: Object,
    prospects: Array,
    itemsPerPage: Number,
    canVisualize: {
      type: Boolean,
      default: function(){
        return false
      }
    },
    canEdit: {
      type: Boolean,
      default: function(){
        return false
      }
    },
    canDelete: {
      type: Boolean,
      default: function(){
        return false
      }
    },
  },
  data() {
    return {
      currentPage: 1,
      isLoading: false,
      isDeleting: false,
      deletingId: '',
      columns: this.canEdit || this.canDelete ? 
          [
            { label: 'Nombre', key: 'name' },
            { label: 'Actions', key: 'actions' }
          ]
        : [
          { label: 'Nombre', key: 'name' },
        ],
    };
  },
  computed: {
    filteredProspects() {
      if (!this.prospects) return [];
      let filtered = this.prospects;
      filtered = this.applyFilters(filtered);
      filtered = this.applyDateFilters(filtered);
      filtered = this.applySliderFilters(filtered);
      filtered = this.filterByOptions(filtered, this.filters.selectedRejectedOptionsFilter);
      filtered = filtered.filter(prospect => {
        return this.filters.selectedCallStatusFilter[prospect.callStatus === "" ? "Vacio" : prospect.callStatus === null ? "Nulo" : prospect.callStatus] &&
               this.filters.selectedAppointmentStatusFilter[prospect.appointmentStatus === "" ? "Vacio" : prospect.appointmentStatus === null ? "Nulo" : prospect.appointmentStatus] &&
               this.filters.selectedClosingStatusFilter[prospect.closingStatus === "" ? "Vacio" : prospect.closingStatus === null ? "Nulo" : prospect.closingStatus] &&
               this.filters.selectedCreditStatusFilter[prospect.creditStatus === "" ? "Vacio" : prospect.creditStatus === null ? "Nulo" : prospect.creditStatus] &&
               this.filters.selectedCreditSituationFilter[prospect.creditSituation === "" ? "Vacio" : prospect.creditSituation === null ? "Nulo" : prospect.creditSituation] &&
               this.filters.selectedCreditTypeFilter[prospect.creditType === "" ? "Vacio" : prospect.creditType === null ? "Nulo" : prospect.creditType];
      });

      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      return filtered.slice(startIndex, startIndex + this.itemsPerPage);
    },
    totalPages() {
      return Math.ceil(this.prospects?.length / this.itemsPerPage);
    },
    startIndex() {
      return (this.currentPage - 1) * this.itemsPerPage + 1;
    },
    endIndex() {
      return Math.min(this.startIndex + this.itemsPerPage - 1, this.prospects?.length);
    },
    boardProspects() {
      return [
        {title: "To Do’s 🖋️", prospects: this.filteredProspects.filter(this.isTodo)},
        {title: "In Progress ✌️", prospects: this.filteredProspects.filter(this.isInProgress)},
        {title: "Completed 🎉", prospects: this.filteredProspects.filter(this.isCompleted)},
        {title: "Rejected ❌", prospects: this.filteredProspects.filter(this.isRejected)},
      ];
    }
  },
  methods: {
    getSelected(array) {
      return Object.keys(array).filter(option => array[option]);
    },
    filterByOptions(prospects, selectedRejectedOptionsFilter) {
      return prospects.filter(prospect => {
        if (!selectedRejectedOptionsFilter['Rechazados'] && prospect.isRejected) return false;
        if (!selectedRejectedOptionsFilter['Activos'] && !prospect.isRejected) return false;
        return true;
      });
    },
    applyFilters(prospects) {
      return prospects
        .filter(prospect => prospect.Products.map(obj => obj.name).some(product => this.getSelected(this.filters.selectedProducts).includes(product)))
        .filter(prospect => this.getSelected(this.filters.selectedUsersSource).some(option => this.sources.find(source => source.id === prospect.sourceId)?.name === option))
        .filter(prospect => this.getSelected(this.filters.selectedUsersAgent).some(option => this.users.find(user => user.id === prospect.agentId)?.username === option))
        .filter(prospect => ['name', 'lastName', 'email', 'curp', 'phone', 'nss'].every(field => prospect[field]?.toLowerCase().includes(this.filters[`${field}Filter`]?.toLowerCase())));
    },
    applyDateFilters(prospects) {
      const dateFilters = ['dob', 'isRejectedDate', 'startLaborActivityDate', 'endLaborActivityDate', 'createdAt', 'updatedAt'];
      return dateFilters.reduce((result, filter) => this.filterByDate(result, filter, this.filters[`${filter}Start`], this.filters[`${filter}End`]), prospects);
    },
    applySliderFilters(prospects) {
      const sliderFilters = ['balance', 'infonavitPoints', 'quotedWeeks', 'discountedWeeks', 'reintegratedWeeks'];
      return sliderFilters.reduce((result, filter) => this.filterBySlider(result, filter, this.filters[`${filter}Filter`]), prospects);
    },
    filterByDate(prospects, propName, filterStart, filterEnd) {
      if (filterStart) prospects = prospects.filter(prospect => new Date(prospect[propName]) >= filterStart);
      if (filterEnd) prospects = prospects.filter(prospect => new Date(prospect[propName]) <= filterEnd);
      return prospects;
    },
    filterBySlider(prospects, propName, filter) {
      return prospects.filter(prospect => {
        if (filter[0] !== undefined && prospect[propName] !== null && prospect[propName] < filter[0]) return false;
        if (filter[1] !== undefined && prospect[propName] !== null && prospect[propName] > filter[1]) return false;
        return true;
      });
    },
    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) this.currentPage++;
    },
    async deleteProspect(id) {
      try {
        this.isDeleting = true;
        this.deletingId = id;
        this.isLoading = true;
        const token = localStorage.getItem('token');
        const headers = { "authorization": `Bearer ${token}` };

        const response = await api.delete(`/v1/prospects/${id}`, { headers });

        this.$emit('delete-p', response.data._id);
        Swal.fire({ position: 'top-end', icon: 'success', title: response.data.message, showConfirmButton: false, timer: 1500 });
      } catch (error) {
        Swal.fire({ icon: 'error', title: 'Oops...', text: 'Something went wrong!', confirmButtonColor: '#d33', confirmButtonText: 'Close' });
      } finally {
        this.isDeleting = false;
        this.deletingId = '';
        this.isLoading = false;
      }
    },
  },
};
</script>