import Swal from 'sweetalert2';
import api from '@/interceptors/axiosInterceptos'; 
import { getProperty } from '@/utils/getProperty.js';
import { initDB } from '../../middleware/middleware';


export async function fetchData(
  endpoint,
  targetProperty,
  storageKey,
  context,
  loading = true,
  forceLoading = false,
  propertyName = 'data',
  append = false,
  isPaginated = false,
  limit = 100,
  offset = 0
) {
  const db = await initDB();
  
  try {
    const initialLoadCompleted = localStorage.getItem(`initialLoadCompleted${targetProperty}`) === 'true';
    if (loading && (!initialLoadCompleted || forceLoading)) {
      context.isLoading = true;
    }

    const token = localStorage.getItem('token');
    const headers = { "authorization": `Bearer ${token}` };

    let currentOffset = offset;
    let dataFetched;
    let isArrayResponse = true;

    do {
      const paginatedEndpoint = isPaginated ? `${endpoint}/${limit}/${currentOffset}` : endpoint;
      const response = await api.get(`${process.env.API_URL}/${paginatedEndpoint}`, { headers });
      dataFetched = getProperty(response, propertyName);

      // Determine if the response is an array or single object
      isArrayResponse = Array.isArray(dataFetched);

      if (dataFetched && (isArrayResponse ? dataFetched.length > 0 : Object.keys(dataFetched).length > 0)) {
        if (isArrayResponse) {
          // Handle paginated lists
          if (append || currentOffset > 0) {
            context[targetProperty] = [...context[targetProperty], ...dataFetched];
          } else {
            context[targetProperty] = dataFetched;
          }
          // Store each item individually in IndexedDB
          const tx = db.transaction(storageKey, 'readwrite');
          for (const item of dataFetched) {
            await tx.store.put(item);
          }
          await tx.done;
        } else {
          // Handle single object responses
          context[targetProperty] = dataFetched;
          localStorage.setItem(storageKey, JSON.stringify(dataFetched));
          break; // Stop loop for non-array responses
        }
      }
      
      currentOffset += limit;
    } while (isPaginated && isArrayResponse && dataFetched && dataFetched.length > 0);

    localStorage.setItem(`initialLoadCompleted${targetProperty}`, 'true');

    if (loading && (!initialLoadCompleted || forceLoading)) {
      context.isLoading = false;
    }
    return context[targetProperty];
  } catch (error) {
    console.log('here', storageKey)
    Swal.fire({
      icon: 'error',
      title: 'Error!',
      text: error.response?.data?.detail ?? error.message
    });
    context.isLoading = false;
  }
}
