<template>
    <div v-for="(item, index) in configList" :key="index">
        <label class="block text-sm font-medium mb-1">{{ item.label }}:</label>
        <MapInput v-if="item.type === 'map'"
            v-model="formData[item.model]" 
            :initialCoordinates="formData[item.coordinates]"
            @update:coordinates="updateCoordinates"

        />
        <input v-if="item.type === 'input'"
            class="form-input w-full"
            :type="item.inputType"
            :step="item.step"
            :placeholder="item.placeholder"
            v-model="formData[item.model]"
            :disabled="!canEdit || (item.naModel ? formData[item.naModel] : false)"
        />

        <div v-if="item.naModel" class="form-check">
            <input
                :disabled="!canEdit"
                class="form-checkbox"
                type="checkbox"
                v-model="formData[item.naModel]"
                @change="callChangeMethod(item)">
            
            <span class="text-sm text-slate-600 dark:text-slate-300 font-medium ml-2">NA</span>
        </div>
        <DropdownFull v-if="item.type === 'dropdown'"
            :disable-inputs="!canEdit"
            :init-value="item.initValue"
            :dropdownOptions="item.dropdownOptions"
            :model="formData[item.model]"
            @update:model="item.updateMethod($event, item.propName, item.updatedPropName)"
        />
    </div>
    
    <div class="flex">
        <MainButtonClickHandler :text="'Regresar'" :is-loading="isLoading" :click-handler="()=>{updateSection('section1')}" />
        <MainButtonClickHandler class="ml-2" :text="'Continuar'" :is-loading="isLoading" :click-handler="()=>{updateSection('section3')}" />
    </div>
</template>


<script>
    import Swal from 'sweetalert2';
    
    import MapInput from '@/components/MapInput.vue'
    import DropdownFull from '@/components/DropdownFull.vue';
    import MainButtonClickHandler from '@/components/buttons/MainButtonClickHandler.vue';

    export default {
        name: 'AvaclickForm',
        emits: ['update-section:value', 'submit-form:form'],
        props: {
            isLoading: Boolean,
            formData_: {
                type: Object,
                default: function() {
                    return {
                        address: '',
                        coordinates: { lat: null, lng: null },
                        construction: null,
                        constructionNA: true,
                        terrain: null,
                        terrainNA: true,
                        typeProperty: 3,
                        rooms: 0,
                        bathrooms: 0,
                        constructionYear: 2024,
                        price: null,
                        kitchen: true,
                        priceNA: true,
                        conservation: 2,
                    }
                }
            }
        },
        components: {
            MapInput,
            DropdownFull,
            MainButtonClickHandler,
        },
        data() {
            const typePropertyOptions = [
                { id: 0, name: 'Casa Habitacion', value: 3 },
                { id: 1, name: 'Casa en  Condominio', value: 4 },
                { id: 2, name: 'Departamento', value: 5 },
                { id: 3, name: 'Duplex', value: 6 },
            ]
            const conservationOptions = [
                { id: 0, name: 'Nuevo', value: 1 },
                { id: 1, name: 'Muy bueno', value: 2 },
                { id: 2, name: 'Bueno', value: 3 },
                { id: 3, name: 'Regular', value: 4 },
                { id: 4, name: 'Malas condiciones', value: 5 },
                { id: 5, name: 'Vandalizado totalmente', value: 6 },
            ]
            const numberOptions = Array.from({ length: 7 }, (_, i) => ({
                id: i,
                name: `${i}`, // or any name generation logic
                value: i
            }));
            const currentYear = new Date().getFullYear();
            const yearOptions = Array.from({ length: currentYear - 1920 + 1 }, (_, i) => ({
                id: currentYear - i,
                name: `${currentYear - i}`, // or any name generation logic
                value: currentYear - i
            }));
            const yesNoOptions = [
                { id: 0, name: 'Si', value: true },
                { id: 1, name: 'No', value: false },
            ]
            return {
                canEdit: true,
                formData: this.formData_,
                configList: [
                    { label: 'Direccion', model: 'address', coordinates: 'coordinates', type: 'map'},
                    { label: 'TipoInmueble', model: 'typeProperty', type: 'dropdown', dropdownOptions: typePropertyOptions, initValue: typePropertyOptions.findIndex(c => c.value == this.formData_.typeProperty), updateMethod: this.updateDropdown, propName: 'typeProperty', updatedPropName: 'value' },
                    { label: 'Mt2 Construccion', model: 'construction', type: 'input', inputType: 'number', step: 0.01, placeholder: '60', naModel: 'constructionNA', naChangeMethod: 'changePropertyNa', naPropName: 'construction' },
                    { label: 'Mt2 Terreno', model: 'terrain', type: 'input', inputType: 'number', step: 0.01, placeholder: '60', naModel: 'terrainNA', naChangeMethod: 'changePropertyNa', naPropName: 'terrain' },
                    { label: 'Recamaras', model: 'rooms', type: 'dropdown', dropdownOptions: numberOptions, initValue: numberOptions.findIndex(c => c.value == this.formData_.rooms), updateMethod: this.updateDropdown, propName: 'rooms', updatedPropName: 'value' },
                    { label: 'Baños', model: 'bathrooms', type: 'dropdown', dropdownOptions: numberOptions, initValue: numberOptions.findIndex(c => c.value == this.formData_.bathrooms), updateMethod: this.updateDropdown, propName: 'bathrooms', updatedPropName: 'value' },
                    { label: 'Año de compra', model: 'constructionYear', type: 'dropdown', dropdownOptions: yearOptions, initValue: yearOptions.findIndex(c => c.value == this.formData_.constructionYear), updateMethod: this.updateDropdown, propName: 'constructionYear', updatedPropName: 'value' },
                    { label: 'Precio de compra', model: 'price', type: 'input', inputType: 'number', step: 0.01, placeholder: '1000000', naModel: 'priceNA', naChangeMethod: 'changePropertyNa', naPropName: 'price' },
                    { label: 'Cocina', model: 'kitchen', type: 'dropdown', dropdownOptions: yesNoOptions, initValue: yesNoOptions.findIndex(c => c.value == this.formData_.kitchen), updateMethod: this.updateDropdown, propName: 'kitchen', updatedPropName: 'value' },
                    { label: 'Conservacion', model: 'conservation', type: 'dropdown', dropdownOptions: conservationOptions, initValue: conservationOptions.findIndex(c => c.value == this.formData_.conservation), updateMethod: this.updateDropdown, propName: 'conservation', updatedPropName: 'value' },
                ]
            }
        },
        methods: {
            updateSection(section){
                if(section == 'section3' && (this.formData.address == '' || this.formData.address == null)){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'Seleccione una direccion valida',
                    })
                    return 
                }
                if(section == 'section3'){
                    this.$emit('submit-form:form', this.formData)
                } else {
                    this.$.emit('update-section:value', section)
                }
            },
            callChangeMethod(item) {
                if (item.naChangeMethod && typeof this[item.naChangeMethod] === 'function') {
                    this[item.naChangeMethod](item.naPropName);
                }
            },
            changePropertyNa(propName) {
                this.formData[propName] = null;
                this.$emit('update:model', this.formData);
            },
            updateCoordinates(newCoords) {
                this.formData.coordinates = newCoords;
            },
            updateDropdown(updated, propName, updatedPropName){
                this.formData[propName] = updated[updatedPropName]
                this.$emit('update:model', this.formData);
            },
        }
    }
</script>