<template>  
  <div class="space-y-8">
  <!-- White box -->
  <div class="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 p-5 min-w-60">
    <div class="grid md:grid-cols-2 xl:grid-cols-1 gap-6">
      <button
          class="btn w-full justify-between min-w-44 bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-500 hover:text-slate-600 dark:text-slate-300 dark:hover:text-slate-200"
          @click.prevent="resetFilters"
        >Borrar filtros</button>
      <div v-for="element in this.filtersConfig" :key="element.title">
        <ExtrasAccordion class="sm:col-span-6" :title="element.title">
           <span v-for="e in element.filters" :key="e.name">
            <StringFilter v-if="e.type == 'string'" 
              :filter_="this.filters[e.name]" @update:filter="updateFilter($event, e.propname)" :label="e.label" ></StringFilter>
            <CheckboxFilter v-else-if="e.type == 'checkbox'"
              @update:filter="updateCheckbox($event, e.propname)"
              :label="e.label" :options="e.options" :model="this.filters[e.name]"/>
            <DropdownRolesFilter v-else-if="e.type == 'dropdown'"
              class="mb-2 w-full" :label="e.label" :options="e.options" :model="this.filters[e.name]" @update:model="updateSelectFilter($event, e.propname)"/>
            <SliderFilter v-else-if="e.type == 'slider'" :label="e.label"  
              @update:filter="updateFilter($event, e.propname)"
              :min="Math.min(...e.values)" :max="Math.max(...e.values)" 
              :default-values_="[Math.min(...e.values), Math.max(...e.values)]"></SliderFilter>
            <span v-else-if="e.type == 'date'">
              <div class="text-sm text-slate-800 dark:text-slate-100 font-semibold mb-3">{{ e.label }}</div>
              <DatepickerFilter :label="'De'" @update:filter="updateDateRange($event, `${e.propname}Start`)" :default-date_="this.filters[`${e.propname}Start`]" class="pb-2 w-full" />
              <DatepickerFilter :label="'Hasta'" @update:filter="updateDateRange($event, `${e.propname}End`)" :default-date_="this.filters[`${e.propname}End`]" class="pb-2 w-full" />
            </span>
          </span>
        </ExtrasAccordion>
      </div>
    </div>
  </div>
</div>
            
</template>

<script>
import ExtrasAccordion from '@/components/ExtrasAccordion.vue';
import StringFilter from '@/components/filters/StringFilter.vue';
import SliderFilter from '@/components/filters/SliderFilter.vue';
import CheckboxFilter from '@/components/filters/CheckboxFilter.vue';
import DatepickerFilter from '@/components/filters/DatePickerFilter.vue';
import DropdownRolesFilter from '@/partials/users/DropdownRolesFilter.vue';

export default {
  name: 'FiltersSidebar',
  props:{
    filters_: Object,
    filterConfig_: Object,
  },
  components: {
    SliderFilter,
    StringFilter,
    CheckboxFilter,
    ExtrasAccordion,
    DatepickerFilter,
    DropdownRolesFilter,
  },
  data(){
    return {
      originalFilters: JSON.parse(JSON.stringify(this.filters_)),
      filters: this.filters_,
      filtersConfig: this.filterConfig_
    }
  },
  methods: {
    resetFilters(){
      this.filters = this.originalFilters
      this.$emit('resetFilters:value', this.originalFilters)
    },
    updateDateRange(value, propName) {
      var update;
      if (value !== null) {
        let date = new Date(value['0']);
        // Set the time to the end of the day (23:59:59.999)
        if(propName.includes('End')) date.setHours(23, 59, 59, 999);
        update = {value: [date], propName: propName}
      } else {
        update = {value: null, propName: propName}
      }
      this.$emit(`update:updateDateFilter`, update);
    },
    updateSelectFilter(updated, propName) {
      this.filters[propName] = { ...updated };
      const update = {value: this.filters[propName], propName}
      this.$emit(`update:selectfilters`, update);
    },
    updateFilter(value, propName) {
      const update = {value: value, propName: propName}
      this.$emit(`update:filters`, update);
    },
    updateCheckbox(option, propName) {
      const update = {value: option, propName: propName}
      this.$emit('update:checkboxfilters', update);
    },
  },
}
</script>