<template>
    <form @submit.prevent="handleSurvey">
        <div class="px-5 py-4">
            <div class="space-y-3">
                <div>
                    <label class="block text-sm font-medium mb-1" for="name">Nombre <span class="text-rose-500">*</span></label>
                    <input id="name" v-model="formData.name" class="form-input w-full px-2 py-1" type="text" required />
                </div>
                <div>
                    <label class="block text-sm font-medium mb-1" for="description">Descripcion</label>
                    <textarea id="description" v-model="formData.description" class="form-textarea w-full px-2 py-1" placeholder="Descripcion" rows="4"></textarea>
                </div>
                <label class="block text-sm font-medium mb-1">Requerido</label>
                <DropdownFull :dropdownOptions="[
                    { id: 0, name: 'No' },
                    { id: 1, name: 'Si' },
                ]" :model="this.formData.required" @update:model="updateStepRequired"/>
                <label class="block text-sm font-medium mb-1">Tipo</label>
                <DropdownFull :dropdownOptions="[
                { id: 0, name: 'Tarea', slug: 'UserTodo' },
                { id: 1, name: 'Autorizacion', slug: 'Authorization'},
                { id: 2, name: 'Cita', slug: 'UserAppointment' },
                    // { id: 3, name: 'Balance', slug: 'BalanceAccount' }
                ]" :model="this.formData.type" @update:model="updateStepType"/>
                <DropdownSelect class="w-full" label="Roles" :options="roles" :name-key="'name'" :target-key="'id'" :model="this.formData.roleIds"
                    @update:value="updateSelect($event, 'roleIds')"/>
                <DropdownSelect class="w-full" label="Paso bloqueado por" :options="isEditing ? this.productSteps.filter(p=>p.index<this.stepIndex) : this.productSteps" :name-key="'step.name'" :target-key="'id'" :model="this.formData.blockedBy"
                    @update:value="updateSelect($event, 'blockedBy')"/>
                <DropdownSelect class="w-full" label="Notificacion" :options="notifications" :name-key="'title'" :target-key="'id'" :model="this.formData.notifications"
                    @update:value="updateSelect($event, 'notifications')"/>
                <div class="form-check">
                    <input class="form-checkbox" type="checkbox" value="" id="flexCheckDefault" v-model="formData.generatesEvent">
                    <span class="text-sm text-slate-600 dark:text-slate-300 font-medium ml-2">Genera Evento</span>
                </div>
                <div class="form-check">
                    <input class="form-checkbox" type="checkbox" value="" id="flexCheckDefault" v-model="formData.hasDocument">
                    <span class="text-sm text-slate-600 dark:text-slate-300 font-medium ml-2">Paso con Documento</span>
                </div>
                <div>
                    <label class="block text-sm font-medium mb-1" for="name">Dias para fecha limite despues de desbloqueado <span class="text-rose-500">*</span></label>
                    <input id="name" v-model="formData.dueDateDays" class="form-input w-full px-2 py-1" type="number" step="1" required />
                </div>
                <div v-if="formData.hasDocument" class="grid grid-cols-1 md:grid-cols-3 w-full gap-x-4 mb-4 gap-y-4" >
                    <div
                    class="text-xs text-black"
                    >
                        <label class="block mb-1 text-rose-500">Formatos</label>
                        <div v-for="(p, index) in ['.png', '.jpg', '.pdf']" :key="index" class="text-black dark:text-white mb-2">
                            <input class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" 
                            :checked="formData.formats.includes(p)"
                            type="checkbox"  @change="updateSelect(p, 'formats')"> 
                            {{ p }}
                        </div>
                    </div>
                </div>
                <div class="pb-14" ></div>
            </div>
        </div>
        <!-- Modal footer -->
        <div class="px-5 py-4 border-t border-slate-200 dark:border-slate-700">
            <div class="flex flex-wrap justify-end space-x-2">
                <SubmitMainButton :is-loading="isLoading" :show-cancel-button="true" :click-handler="closeModal" />
            </div>
        </div>
    </form>
</template>

<script>
    import DropdownFull from '@/components/DropdownFull.vue';
    import DropdownSelect from '@/components/DropdownSelect.vue';
    import SubmitMainButton from '@/components/buttons/SubmitMainButton.vue'
    export default {
        name: 'StepForm',
        components: {  
            DropdownFull,
            DropdownSelect,
            SubmitMainButton,
        },
        props: {
            stepIndex: Number,
            isEditing: Boolean,
            productSteps: Array,
            roles: Array,
            notifications: Array,
            isLoading: Boolean,
            formData_: {
                type: Object,
                default: function(){
                    return {
                        name: null,
                        description: null,
                        required: false,
                        type: 'UserTodo',
                        roleIds: [],
                        blockedBy: [],
                        notifications: [],
                        dueDateDays: null,
                        generatesEvent: false,
                        hasDocument: false,
                        formats: ['.pdf'],
                    }
                }
            }
        },
        data(){
            return {
                formData: this.formData_,
            }
        },
        methods: {
            handleSurvey(){
                this.$emit('handle-click:value', this.formData)
            },
            closeModal(){
                this.formData = {
                    name: null,
                    description: null,
                    required: false,
                    type: 'UserTodo',
                    roleIds: [],
                    blockedBy: [],
                    notifications: [],
                    dueDateDays: null,
                    generatesEvent: false,
                    hasDocument: false,
                    formats: ['.pdf'],
                } 
                this.$emit('close-modal')
            },
            updateStepRequired(updated) {
                this.formData.required = updated.id;
            },
            updateStepType(updated) {
                this.formData.type = updated.slug;
            },
            updateSelect(updated, propName){
                const index = this.formData[propName].indexOf(updated);
                if (index === -1) {
                    // If the option is not already in the array, push it
                    this.formData[propName].push(updated);
                } else {
                    // If the option is already in the array, remove it
                    this.formData[propName].splice(index, 1);
                }   
            },
        }
    }
</script>