<template>
    <div class="my-4">
        <div class="flex flex-col sm:flex-row sm:items-center sm:justify-between">
            <nav class="mb-4 sm:mb-0 sm:order-1" role="navigation" aria-label="Navigation">
                <ul class="flex justify-center">
                <li class="ml-3 first:ml-0">
                    <button @click="prevPage" :disabled="currentPage === 1" class="btn bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 text-slate-300 dark:text-slate-600">&lt;- Previous</button>
                </li>
                <li class="ml-3 first:ml-0">
                    <button @click="nextPage" :disabled="currentPage === totalPages" class="btn bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-indigo-500">Next -&gt;</button>
                </li>
                </ul>
            </nav>
            <div class="text-sm text-slate-500 dark:text-slate-400 text-center sm:text-left">
                Mostrando <span class="font-medium text-slate-600 dark:text-slate-300">{{ startIndex }}</span> a <span class="font-medium text-slate-600 dark:text-slate-300">{{ endIndex }}</span> de <span class="font-medium text-slate-600 dark:text-slate-300">{{ todos?.length }}</span> resultados
            </div>
        </div>
    </div>
    <div class="w-full grid grid-cols-12 gap-x-4 gap-y-8"> 
        <TasksGroups v-for="element in boardElements" :key="element.title" :title="element.title" :total="element.list.length">
            <TodosTableItem
                v-for="e in element.list"
                :key="e.id"
                :element="{todo: e}" @edit-todo:value="editTodo"
            />
        </TasksGroups>
    </div>
</template>
<script>
    import TasksGroups from '@/partials/tasks/TasksGroups.vue'
    import TodosTableItem from '@/partials/prospects/detail/todos/TodosTableItem.vue';

    import { applyDateFilters, applySelectFilters, applyTextFilters, filterByStepBlocked  } from '@/utils/filters.js';
    export default {
        name: 'TodosTable',
        emits: ['edit-todo:value'],
        components: {
            TasksGroups,
            TodosTableItem,
        },
        props: {
            todos: Array,
            filters: Object,
            itemsPerPage: Number,
        },
        data(){
            return {
                currentPage: 1,
                users: []
            }
        },
        computed:{
            totalPages() {
                return Math.ceil(this.todos?.length / this.itemsPerPage);
            },
            startIndex() {
                return (this.currentPage - 1) * this.itemsPerPage + 1;
            },
            endIndex() {
                return Math.min(this.startIndex + this.itemsPerPage - 1, this.todos?.length);
            },
            boardElements() {
                return [
                    {title: "Pendiente 📝", list: this.filterValues('TODO')},
                    {title: "En Progreso ✌️", list: this.filterValues('INPROGRESS')},
                    {title: "Completado 🎉", list: this.filterValues('DONE')},
                ];
            }
        },
        methods: {
            editTodo(formData){
                this.$emit('edit-todo:value', formData)
            },
            prevPage() {
                if (this.currentPage > 1) this.currentPage--;
            },
            nextPage() {
                if (this.currentPage < this.totalPages) this.currentPage++;
            },
            filterValues(status){
                let filtered = this.todos;
                filtered = filterByStepBlocked(filtered, 'selectedBloqued', this);
                filtered = applyDateFilters(filtered, ['createdAt'], this);
                filtered = applySelectFilters(filtered, 'selectedAgent', 'user.username', this);
                filtered = applySelectFilters(filtered, 'selectedProducts', 'clientproduct.name', this);
                filtered = applySelectFilters(filtered, 'selectedTypes', 'type', this);
                filtered = applyTextFilters(filtered, ['name'], this);
                filtered = filtered.filter((element) => { return element.status == status }).sort((a, b) => {
                    const dateA = a?.dueDate ? new Date(a.dueDate) : null;
                    const dateB = b?.dueDate ? new Date(b.dueDate) : null;

                    if (dateA === null) return 1; // place items with null dueDate at the end
                    if (dateB === null) return -1; // place items with null dueDate at the end
                    if (dateA === dateB) return 0;

                    return dateA - dateB;
                })
                const startIndex = (this.currentPage - 1) * this.itemsPerPage;
                return filtered.slice(startIndex, startIndex + this.itemsPerPage);
                
            },
            
        },
    }
</script>