<template>
  <div class="col-span-full sm:col-span-6 xl:col-span-4 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
    <div class="flex flex-col h-full">
      <!-- Card top -->
      <div class="grow p-5">
        <!-- Image + name -->
        <header>
          <router-link :to="redirectUrl(item.id)">
            <div class="flex justify-center mb-2">
              <div class="relative inline-flex items-start" :to="'#0'">
                <AvatarLetter :name="item.name" :size="64"/>
              </div>
            </div>
            <div class="text-center">
              <div class="inline-flex text-slate-800 dark:text-slate-100 hover:text-slate-900 dark:hover:text-white" :to="'#0'">
                <h2 class="text-xl leading-snug justify-center font-semibold">{{item.name}}</h2>
              </div>
            </div>
          </router-link>
        </header>
      </div>
      <!-- Card footer -->
      <div class="border-t border-slate-200 dark:border-slate-700">
        <div v-if="canDelete" class="flex divide-x divide-slate-200 dark:divide-slate-700">
          <span class="flex items-center justify-center" v-if="isLoading && deletingId == item.id">
            <div class="flex items-center justify-center">
              <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
            </div>
          </span>
          <button  v-else @click="deleteF(item.id)" class="block flex-1 text-center text-sm text-slate-600 hover:text-slate-800 dark:text-slate-300 dark:hover:text-slate-200 font-medium px-3 py-4 group">
            <div class="flex items-center justify-center">
              <svg class="w-8 h-8 fill-current" viewBox="0 0 32 32">
                <path :class="'text-red-500'" d="M13 15h2v6h-2zM17 15h2v6h-2z" />
                <path :class="'text-red-500'" d="M20 9c0-.6-.4-1-1-1h-6c-.6 0-1 .4-1 1v2H8v2h1v10c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V13h1v-2h-4V9zm-6 1h4v1h-4v-1zm7 3v9H11v-9h10z" />
              </svg>
              <span>Eliminar</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AvatarLetter from '@/components/AvatarLetter.vue'

export default {
  name: 'SourcesTilesCard',
  data() {
    return {
      
    }
  },
  components: {
    AvatarLetter
  },
  props: {
    item: Object,
    isLoading: Boolean,
    deletingId: String,
    canDelete: {
      type: Boolean,
      default: function(){
        return false
      }
    }
  },
  methods: {
    redirectUrl(id){
      return `/sources/${id}`
    },
    deleteF(id) {
      // Implement delete user logic
      this.$emit('delete-f', id);
    },
  }
}
</script>