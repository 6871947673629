<template>
  <div class="relative inline-flex">
    <button
      ref="trigger"
      class="w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-slate-200 dark:bg-slate-700 dark:hover:bg-slate-600/80 rounded-full"
      :class="{ 'bg-slate-200': dropdownOpen }"
      aria-haspopup="true"
      @click.prevent="dropdownOpen = !dropdownOpen"
      :aria-expanded="dropdownOpen"
    >
      <span class="sr-only">Notificaciones</span>
      <svg class="w-4 h-4" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path class="fill-current text-slate-500 dark:text-slate-400" d="M6.5 0C2.91 0 0 2.462 0 5.5c0 1.075.37 2.074 1 2.922V12l2.699-1.542A7.454 7.454 0 006.5 11c3.59 0 6.5-2.462 6.5-5.5S10.09 0 6.5 0z" />
        <path class="fill-current text-slate-400 dark:text-slate-500" d="M16 9.5c0-.987-.429-1.897-1.147-2.639C14.124 10.348 10.66 13 6.5 13c-.103 0-.202-.018-.305-.021C7.231 13.617 8.556 14 10 14c.449 0 .886-.04 1.307-.11L15 16v-4h-.012C15.627 11.285 16 10.425 16 9.5z" />
      </svg>
      <div v-if="unreadNotifications()" class="absolute top-0 right-0 w-2.5 h-2.5 bg-rose-500 border-2 border-white dark:border-[#182235] rounded-full"></div>
    </button>
    <transition
      enter-active-class="transition ease-out duration-200 transform"
      enter-from-class="opacity-0 -translate-y-2"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-out duration-200"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-show="dropdownOpen" class="origin-top-right z-10 absolute top-full -mr-48 sm:mr-0 min-w-80 bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1" :class="align === 'right' ? 'right-0' : 'left-0'">
        <div class="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-4">Notificaciones</div>
        <ul
          ref="dropdown"
          @focusin="dropdownOpen = true"
          @focusout="dropdownOpen = false"
          class="max-h-[75vh] overflow-y-auto"
        >
          <li v-if="notifications.length == 0" class="border-b border-slate-200 dark:border-slate-700 last:border-0">
            <span class="block text-sm mb-2 ml-4"><span class="font-medium text-slate-800 dark:text-slate-100">Sin notificaciones</span></span>
          </li>
          <li v-for="notification in notifications" :key="notification.id" class="border-b border-slate-200 dark:border-slate-700 last:border-0">
            <router-link class="block py-2 px-4 hover:bg-slate-50 dark:hover:bg-slate-700/20" :to="formatRedirectUrl(notification.redirectUrl)" @click="dropdownOpen = false, readNotification([notification.id])">
              <div v-if="!notification.isRead" class="right-0 w-2.5 h-2.5 bg-rose-500 border-2 border-white dark:border-[#182235] rounded-full"></div>
              <span class="block text-sm mb-2">📣 <span class="font-medium text-slate-800 dark:text-slate-100">{{notification.title}}</span> {{notification.content}}</span>
              <span class="block text-xs font-medium text-slate-400 dark:text-slate-500">{{returnDate(notification.createdAt)}}</span>
            </router-link>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>


<script>
import { editData } from '@/utils/editData.js';
import { ref, onMounted, onUnmounted } from 'vue'

export default {
  name: 'DropdownNotifications',
  props: {
    align: String,
    notifications: Array
  },
  setup() {

    const dropdownOpen = ref(false)
    const trigger = ref(null)
    const dropdown = ref(null)

    // close on click outside
    const clickHandler = ({ target }) => {
      if (!dropdownOpen.value || dropdown.value.contains(target) || trigger.value.contains(target)) return
      dropdownOpen.value = false
    }

    // close if the esc key is pressed
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen.value || keyCode !== 27) return
      dropdownOpen.value = false
    }

    onMounted(() => {
      document.addEventListener('click', clickHandler)
      document.addEventListener('keydown', keyHandler)
    })

    onUnmounted(() => {
      document.removeEventListener('click', clickHandler)
      document.removeEventListener('keydown', keyHandler)
    })

    return {
      dropdownOpen,
      trigger,
      dropdown,
    }
  },
  data(){
    return {
      notifications_: []
    }
  },
  methods: {
    formatRedirectUrl(url) {
      // Ensure that the redirect URL is absolute
      if (url && !url.startsWith('/')) {
        return `/${url}`;
      }
      return url;
    },
    returnDate(createdAt) {
      const now = new Date();
      const date = new Date(createdAt);
      const diffInMs = now - date;
      const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
      const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
      const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

      if (diffInMinutes < 1) {
        return "hace unos segundos";
      } else if (diffInMinutes < 60) {
        return `hace ${diffInMinutes} min`;
      } else if (diffInHours < 24) {
        return `hace ${diffInHours} ${diffInHours === 1 ? 'hora' : 'horas'}`;
      } else {
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${date.toLocaleTimeString([], { hour12: false, hour: 'numeric', minute: 'numeric' })}`;
      }
    },
    unreadNotifications(){
      return this.notifications.some(n => !n.isRead)
    },
    async readNotification(notificationIds){
      for (const id of notificationIds) {
        const response = await editData(
          `user-notifications/${id}`, 
          {isRead: true}, 
          '', 'notifications_', 'notifications', this, false);
        if (!response) {
          return
        };
        this.$emit('update:notifications', this.notifications_)
      }
    }
  }
}
</script>