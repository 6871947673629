<template>
  <ModalBasic id="feedback-modal" :modalOpen="modalOpen" @close-modal="modalOpen = false" :title="titleModal">
    <form @submit.prevent="add">
      <div class="px-5 py-4">
          <div class="space-y-3">
            <div>
                <label class="block text-sm font-medium mb-1" for="name">Nombre <span class="text-rose-500">*</span></label>
                <input v-if="this.fullList?.length < 1" id="email" class="form-input w-full" type="text" placeholder="Crea al menos un producto" :disabled="true" />
                <DropdownFullQuestions v-else
                  :property-key="'name'"
                  :dropdownOptions="this.fullList" :model="this.product" @update:model="updateSelected" />
            </div>
            <div class="pb-10" ></div>
          </div>
      </div>
      <div class="px-5 py-4 border-t border-slate-200 dark:border-slate-700">
        <SubmitMainButton :is-loading="isLoading_" :show-cancel-button="true" :click-handler="()=>{modalOpen = false}" />
      </div>
    </form>
  </ModalBasic>
  <div class="col-span-full xl:col-span-6 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 min-h-80">
    <header class="px-5 py-4 border-b border-slate-100 dark:border-slate-700">
      <div class="flex justify-between">
        <h2 class="font-semibold text-slate-800 dark:text-slate-100 content-center">{{title}}</h2>
        <button v-if="canEdit" class="text-white-500 hover:text-white-600 rounded-full">
          <svg @click.stop.prevent="modalOpen = true" class="w-4 h-8 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
            <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
          </svg>
        </button>
      </div>
    </header>
    <div v-if="!isBoard" class="p-3">
      <div v-for="(item, index) in this.list" :key="item.name">
        <header class="text-xs uppercase text-slate-400 dark:text-slate-500 bg-slate-50 dark:bg-slate-700 dark:bg-opacity-50 rounded-sm font-semibold p-2">
          <div class="flex justify-between	">
            <span class="content-center">{{item.name}}</span>
            <div class="flex">
              <div class="pr-2"></div>
              <button class="text-white-500 hover:text-white-600 rounded-full">
                <svg @click.stop.prevent="remove(index)" class="w-8 h-8 fill-current" viewBox="0 0 32 32">
                  <path  class="text-red-500" d="M13 15h2v6h-2zM17 15h2v6h-2z" />
                  <path class="text-red-500" d="M20 9c0-.6-.4-1-1-1h-6c-.6 0-1 .4-1 1v2H8v2h1v10c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V13h1v-2h-4V9zm-6 1h4v1h-4v-1zm7 3v9H11v-9h10z" />
                </svg>
              </button>
            </div>
          </div>
        </header>
        <div class="space-y-4 grid grid-cols-1 gap-x-6 sm:grid-cols-6 mb-4 ">
          <ExtrasProspectionAccordion
          :can-edit="false"
          :is-loading="this.isLoading_"
          :products="this.list"
          :form-data_="metaData ? JSON.parse(item.productMetaData) : item"
          />
        </div>
      </div>
    </div>
    <div v-else class="px-4 py-4 relative">
      <div :key="tableComponentKey" class="w-full grid grid-cols-12 gap-x-4 gap-y-8">
        <TasksGroups v-for="element in boardProducts" :key="element.title" :title="element.title" :total="element.products.length">
          <AplicableProductsTableItem
          v-for="product in element.products"
          :key="product.id"
          :product="product"
          @select-product="selectProduct"
          >{{ product.name }}</AplicableProductsTableItem>
        </TasksGroups>
      </div>
      <div v-if="selectedProduct" class="absolute top-0 right-0 h-full bg-white dark:bg-slate-800 shadow-lg border-l border-slate-200 dark:border-slate-700 w-full lg:w-2/3 transition-transform transform" :class="{ 'translate-x-0': selectedProduct, 'translate-x-full': !selectedProduct }">
        <ProductDetail v-if="!isLoading"
        @remove-element:id="remove" :permissions="permissions" @handle-submit-survey:value="handleSubmitSurvey"
        :list="this.list"
        :generate-contract="generateContract" :start-product="startProduct" :finish-product="finishProduct" :regenerate-url="regenerateUrl"
        :handle-request-during-signature="handleRequestDuringSignature"
        :on-checked="onChecked"
        :handle-save="handleSave"
        :update-product:value="updateProduct"
        :show-document-todos="showDocumentTodos" :show-start-button="showStartButton"
        :key="productDetailComponentKey" :product="selectedProduct" @close-detail="selectedProduct = null" />
        <span v-else class="p-4">
            <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { editData } from '@/utils/editData.js';

import ModalBasic from '@/components/ModalBasic.vue';
import TasksGroups from '@/partials/tasks/TasksGroups.vue';
import ProductDetail from '../detail/services/ProductDetail.vue';
import SubmitMainButton from '@/components/buttons/SubmitMainButton.vue'
import ExtrasProspectionAccordion from './ExtrasProspectionAccordion.vue';
import AplicableProductsTableItem from './AplicableProductsTableItem.vue';
import DropdownFullQuestions from '@/partials/products/detail/DropdownFullQuestions.vue';

export default {
  name: 'AplicableProductsTable',
  emits: ['handle-service:value', 'remove:model'],
  components: {
    ModalBasic,
    TasksGroups,
    ProductDetail,
    SubmitMainButton,
    DropdownFullQuestions,
    AplicableProductsTableItem,
    ExtrasProspectionAccordion,
  },
  props: {
    permissions: Object,
    title: String,
    titleModal: String,
    isLoading_: Boolean,
    canEdit: { type: Boolean, default: function(){ return false } },
    isBoard: { type: Boolean, default: function(){ return true } },
    metaData: { type: Boolean, default: function(){ return false } },
    showStartButton: { type: Boolean, default: function(){ return false } },
    showDocumentTodos: { type: Boolean, default: function(){ return false } },
    list_: { type: Array, default: function() { return [] } },
    fullList: { type: Array, default: function() { return [] } },
  },
  data(){
    return {
      prospectId: '',
      isLoading: false,
      modalOpen: false,
      selectedProduct: null,
      tableComponentKey: 0,
      todoComponentKey: 0,
      productDetailComponentKey: 0,
      product: this.fullList?.length < 1 ? null : this.fullList[0],
      list: this.list_
    }
  },
  computed:{
    boardProducts() {
      return [
        {title: "Por Firmar 🖋️", products: this.list.filter((product) => { return product.status == 'PROSPECT' })},
        {title: "En Progreso ✌️", products: this.list.filter((product) => { return product.status == 'INPROGRESS' })},
        {title: "Completado 🎉", products: this.list.filter((product) => { return product.status == 'DONE' })},
        {title: "Rechazado ❌", products: this.list.filter((product) => { return product.status == 'REJECTED' })},
      ];
    }
  },
  methods: {
    selectProduct(product) {
      this.selectedProduct = product;
      this.productDetailComponentKey++;
    },
    async handleSave(event, documentIndex){
      await editData(`client-documents/${event.documentId}`, event.body, 'El documento se a editado exitosamente.', 'clientDocuments', 'clientDocuments', this, true, true);
      // await editData(`todos/${this.selectedProduct.extras[documentIndex].todo.id}`, {status: 'DONE'}, 'El TO DO se han guardado con exito.', 'clientTodos', 'clientTodos', this, false, false);
      
      this.selectedProduct.extras[documentIndex].clientDocument.name = event.name;
      this.selectedProduct.extras[documentIndex].clientDocument.documentExtension = event.documentExtension;
      // this.selectedProduct.extras[documentIndex].todo.status = 'DONE'
      const index = this.list.findIndex((element) => element.id === this.selectedProduct.id);
      if (index !== -1) {
        this.list.splice(index, 1);
        this.list.splice(index, 0, this.selectedProduct);
      }
      this.tableComponentKey++;
    },
    onChecked(){console.log('TODO')},
    updateProduct(product){
      const index = this.list.findIndex((element) => element.id === product.id);
      if (index !== -1) {
        this.list.splice(index, 1);
        this.list.splice(index, 0, product);
      }
    },
    async handleSubmitSurvey(formData){
      await editData(`client-product-surveys`, {
        clientId: formData.prospectId,
        clientProductId: formData.product.id,
        clientProductSurveysToUpdate: formData.formData
      }, 'El Formulario se ha guardado con exito.', 'clientSurveys', 'clientSurveys', this, true, true);
      var updatedProduct = formData.product; 
      updatedProduct.clientSurveys = formData.formData
      this.updateProduct(updatedProduct)
    },
    handleRequestDuringSignature(updated, documentIndex){
      this.selectedProduct.extras[documentIndex].clientDocument.requestDuringSignature = !updated.requestDuringSignature
      const index = this.list.findIndex((element) => element.id === this.selectedProduct.id);
      if (index !== -1) {
        this.list.splice(index, 1);
        this.list.splice(index, 0, this.selectedProduct);
      }
      editData(`client-documents/details/${updated.id}`, {requestDuringSignature: updated.requestDuringSignature}, 'Propiedad del documento se a editado exitosamente.', 'clientDocuments', 'clientDocuments', this, false, false);
      this.tableComponentKey++;
    },
    async generateContract(product){
      await this.handleSubmitSurvey({formData: product.clientSurveys, product: product, prospectId: this.prospectId})
      this.$emit('handle-service:value', {product, action: "generate"})
    },
    startProduct(product){
      this.$emit('handle-service:value', {product, action: "start"});
    },
    finishProduct(product){
      this.$emit('handle-service:value', {product, action: "finish"});
    },
    regenerateUrl(product){
      this.$emit('handle-service:value', {product, action: "regenerate"});
    },
    updateSelected(updated){
      this.product = updated;
    },
    add(){
      if(this.metaData){
        this.$emit('update:model', this.product);
      } else {
        this.list.push(this.product)
      }
      this.modalOpen = false;
      this.product = null;
    },
    remove(index){
      if(this.metaData){
        this.$emit('remove:model', index);
      } else{
        this.list.splice(index, 1);
      }
    },
  },
  created(){
    this.prospectId = this.$route.params.id;
  }
}
</script>