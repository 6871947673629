<template>
    Valuacion
    <ModalBasic id="fee-modal" :modalOpen="feeAmountOpen" @close-modal="feeAmountOpen = false" title=''>
        <!-- Modal content -->
        <form @submit.prevent="addFeeAmount">
        <div class="px-5 py-4">
            <div class="space-y-3">
                <div>
                    <label class="block text-sm font-medium mb-1" for="name">Nombre <span class="text-rose-500">*</span></label>
                    <input id="name" v-model="feeAmountName" class="form-input w-full px-2 py-1" type="text" required />
                </div>
                <div>
                    <label class="block text-sm font-medium mb-1" for="name">Cantidad (valores entre 0 y 1 se tomaran como porcentajes) <span class="text-rose-500">*</span></label>
                    <input id="name" v-model="feeAmount" class="form-input w-full px-2 py-1" type="number" step="0.0001" required />
                </div>
            </div>
        </div>
        <!-- Modal footer -->
        <div class="px-5 py-4 border-t border-slate-200 dark:border-slate-700">
            <div class="flex flex-wrap justify-end space-x-2">
                <SubmitMainButton :is-loading="isLoading" :show-cancel-button="true" :click-handler="() => {feeAmountOpen = false}" />
            </div>
        </div>
        </form>
    </ModalBasic>
    <div class="flex">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
            <div v-for="(item, index) in this.feesList" :key="item.name">
                <header class="text-xs uppercase text-slate-400 dark:text-slate-500 bg-slate-50 dark:bg-slate-700 dark:bg-opacity-50 rounded-sm font-semibold p-2">
                    <div class="flex justify-between	">
                        <span class="content-center">{{item.name}}</span>
                        <div v-if="canEdit" class="flex">
                            <button  class="text-white-500 hover:text-white-600 rounded-full">
                                <svg @click.stop.prevent="feeAmountIndex = index, feeAmountOpen = true" class="w-4 h-8 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                                <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                                </svg>
                            </button>
                            <div class="pr-2"></div>
                            <button
                                class="btn border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300 mr-2" 
                                @click.prevent.stop="resetValues(index)">
                                Valores default
                            </button>
                            <!-- <DeleteButton :clickHandler="() => removeFeeClass(index)" /> -->
                        </div>
                    </div>
                </header>
                <div class="flex">
                    <div>
                        <ul v-for="(valuation) in valuations" :key="valuation.name" class="my-1">
                        <!-- Item -->
                            <li class="px-2 grow border-b border-slate-100 dark:border-slate-700 text-sm py-2">
                                <div class="">
                                    <!-- <div v-if="canEdit" class="w-8 h-8 rounded-full shrink-0 bg-indigo-500 my-2 mr-2">
                                        <DeleteButton :clickHandler="() => removeFeeAmount(index, subindex)" />
                                    </div> -->
                                    <div class="grow flex justify-between">
                                        <div class="self-center">{{valuation.name}}</div>
                                        <div class="flex shrink-0 self-end ml-2 text-center">
                                        <span>{{formatPrice(valuation.amount)}}</span>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="grow flex justify-between mt-2">

                                    <div class="self-center" :class="calculateViable(item.fees, valuation.amount) ? 'text-green-600' : 'text-red-600'">
                                        {{calculateViable(item.fees, valuation.amount) ? 'Viable' : 'No Viable'}}
                                    </div>
                                    <div class="self-center">{{formatPrice(calculateProfit(item.fees, valuation.amount))}}</div>
                                </div>
                                <div class="self-center flex mt-2">
                                    <font-awesome-icon 
                                        v-for="(star, index) in Array.from({ length: calculateStars(item.fees, valuation.amount) })" 
                                        :key="index" 
                                        :icon="['fas', 'star']" 
                                    />
                                </div>
                            </li>
                        </ul> 
                    </div>
                    <div :key="componentKey">
                        <ul v-for="(comission, subindex) in item.fees" :key="comission.name" class="my-1 border-b border-slate-100 dark:border-slate-700 text-sm py-2">
                            <!-- Item -->
                            <li class="flex px-2">
                                <div v-if="canEdit" class="w-8 h-8 rounded-full shrink-0 bg-indigo-500 my-2 mr-2">
                                <DeleteButton :clickHandler="() => removeFeeAmount(index, subindex)" />
                                </div>
                                <div class="grow flex items-center">
                                <div class="grow flex justify-between">
                                    <div class="self-center">{{ comission.name }}</div>

                                    <!-- Dropdown if options exist -->
                                    <div class="flex shrink-0 self-end ml-2 text-center">
                                    

                                    <!-- Display based on selected option or fee amount -->
                                    <div v-if="!comission.options">
                                        <span v-if="comission.amount < 1">{{ (comission.amount * 100).toFixed(2) }}%</span>
                                        <span v-else>{{ formatPrice(comission.amount) }}</span>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </li>
                            <div class="grow flex justify-between mt-2">
                                <!-- <div v-if="comission.options">
                                    <select v-model="selectedOptions[subindex]" @change="updateFeeAmount(index, subindex)" class="border rounded px-2 py-1 bg-white dark:bg-slate-800">
                                        <option v-for="(option, optIndex) in comission.options" :key="optIndex" :value="option.amount">
                                            {{ option.name }} ({{ formatPrice(option.amount) }})
                                        </option>
                                    </select>
                                </div> -->
                                <DropdownFull v-if="comission.options"
                                    :disable-inputs="false"
                                    :dropdownOptions="comission.options" 
                                    :init-value="0"
                                    :model="selectedOptions[subindex]" @update:model="updateDropdown(comission.name, $event)" />
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex">
        <MainButtonClickHandler :text="'Regresar'" :is-loading="isLoading" :click-handler="updateSection" />
    </div>
</template>

<script>
    import {propertyValuationExpenses} from '@/utils/propertyValuationExpenses.js'
    import ModalBasic from '@/components/ModalBasic.vue';
    import DropdownFull from '@/components/DropdownFull.vue';
    import DeleteButton from '@/components/buttons/DeleteButton.vue'
    import SubmitMainButton from '@/components/buttons/SubmitMainButton.vue'
    import MainButtonClickHandler from '@/components/buttons/MainButtonClickHandler.vue';
    export default {
        name: 'ValuationTable',
        emits: ['update-section:value'],
        props: {
            canEdit: {
                type: Boolean,
                default: function(){
                    return true
                }
            },
            isLoading: Boolean,
            valuations_: {
                type: Array,
                default: function(){
                    return [
                        {'name': 'VALOR COMERCIAL APROX', 'amount': 100000, viable: true, stars: 2},
                        {'name': 'VALOR COMERCIAL MAX', 'amount': 1000000, viable: false, stars: 0},
                    ]
                }
            }
        },
        components: {
            ModalBasic,
            DropdownFull,
            DeleteButton,
            SubmitMainButton,
            MainButtonClickHandler,
        },
        data(){
            return {
                componentKey: 0,
                selectedOptions: {'ADEUDO AGUA': 400*12*1, 'ADEUDO PREDIAL': 200*12*1, 'ADEUDO LUZ': 40*12*1, '¿TIENE ESCRITURAS?': 0, 'CONDICIONES DE LA CASA': 9000},
                feeAmountName: '',
                feeAmount: null,
                feeAmountOpen: false,
                valuations: this.valuations_,
                feesList: [JSON.parse(JSON.stringify(propertyValuationExpenses[0]))]
            }
        },
        methods: {
            updateDropdown(name, updated){
                this.selectedOptions[name] = updated.amount
                const index = this.feesList[0].fees.findIndex((e) => e.name === name)
                this.feesList[0].fees[index].amount = updated.amount
            },
            calculateProfit(fees, valuation) {
                const totalFee = fees.reduce(
                    (acc, item) => acc += item.amount,
                    0
                );
                return valuation - totalFee
            },
            updateFeeAmount(index, subindex) {
                const selectedAmount = this.selectedOptions[subindex];
                // Find the correct fee and update its amount based on the selected option
                this.feesList[index].fees[subindex].amount = selectedAmount;
            },
            calculateStars(fees, valuation){
                const profit = this.calculateProfit(fees, valuation)
                return  profit < 50000 ? 0 :  profit < 100000 ? 1 : profit < 150000 ? 2 : profit > 200000 ? 3 : 2
            },
            calculateViable(fees, valuation){
                const profit = this.calculateProfit(fees, valuation)
                return  profit > 80000
            },
            updateSection(){
                this.$.emit('update-section:value', 'section3')
            },
            formatPrice(value) {
                let val = (value/1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            removeFeeClass(index){
                this.feesList.splice(index, 1);
            },
            removeFeeAmount(index, subIndex){
                this.feesList[index].fees.splice(subIndex, 1);
            },
            addFeeAmount(){
                this.feesList[this.feeAmountIndex].fees.push({'name': this.feeAmountName, 'amount': this.feeAmount})
                this.feeAmountIndex = null;
                this.feeAmountName = '';
                this.feeAmount = null;
                this.feeAmountOpen = false;
            },
            resetValues(feeAmountIndex){
                this.componentKey++
                this.feesList = [JSON.parse(JSON.stringify(propertyValuationExpenses[0]))]
                this.selectedOptions = {'ADEUDO AGUA': 400*12*1, 'ADEUDO PREDIAL': 200*12*1, 'ADEUDO LUZ': 40*12*1, '¿TIENE ESCRITURAS?': 0, 'CONDICIONES DE LA CASA': 9000}
            }
        },
    }
</script>