<template>
    <form @submit.prevent="handleDocument">
        <div class="px-5 py-4">
        <div class="space-y-3">
          <div>
              <label class="block text-sm font-medium mb-1" for="name">Nombre <span class="text-rose-500">*</span></label>
              <input id="name" v-model="formData.name" class="form-input w-full px-2 py-1" type="text" required />
          </div>
          <div>
              <label class="block text-sm font-medium mb-1" for="description">Descripcion</label>
              <textarea id="description" v-model="formData.description" class="form-textarea w-full px-2 py-1" placeholder="Descripcion" rows="4" :disabled="formData.optionsNA"></textarea>
          </div>
          <div class="form-check">
            <input class="form-checkbox" type="checkbox" value="" id="flexCheckDefault" v-model="formData.requestDuringSignature">
            <span class="text-sm text-slate-600 dark:text-slate-300 font-medium ml-2">Solicitar durante el proceso de firma</span>
          </div>
            <div class="grid grid-cols-1 md:grid-cols-3 w-full gap-x-4 mb-4 gap-y-4" >
              <div
              class="text-xs text-black"
              >
                <label class="block mb-1 text-rose-500">Formatos</label>
                <div v-for="(p, index) in ['.png', '.jpg', '.pdf']" :key="index" class="text-white mb-2">
                <input class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" 
                :checked="formData.formats.includes(p)"
                type="checkbox"  @change="updateModel(p)"> 
                {{ p }}
                </div>
              </div>
            </div>
          </div>
      </div>
      <!-- Modal footer -->
      <div class="px-5 py-4 border-t border-slate-200 dark:border-slate-700">
        <div class="flex flex-wrap justify-end space-x-2">
            <SubmitMainButton :is-loading="isLoading" :show-cancel-button="true" :click-handler="closeModal" />
        </div>
      </div>
    </form>
        
    
</template>

<script>
    import SubmitMainButton from '@/components/buttons/SubmitMainButton.vue'
    export default {
        name: 'DocumentForm',
        props: {
            isLoading: Boolean,
            formData_: {
                type: Object,
                default: function(){
                    return {
                        name: '',
                        description: '',
                        requestDuringSignature: false,
                        formats: ['.pdf'],
                    }
                }
            }
        },
        components: {
            SubmitMainButton,
        },
        data(){
            return {
                formData: this.formData_,
            }
        },
        methods: {
            handleDocument(){
                this.$emit('handle-click:value', this.formData)
            },
            closeModal(){
                this.formData = {
                    name: '',
                    description: '',
                    requestDuringSignature: false,
                    formats: ['.pdf'],
                } 
                this.$emit('close-modal')
            },
            updateModel(option){
                const index = this.formData.formats.indexOf(option);
                if (index === -1) {
                    // If the option is not already in the array, push it
                    this.formData.formats.push(option);
                } else {
                    // If the option is already in the array, remove it
                    this.formData.formats.splice(index, 1);
                }   
            },
        }
    }
</script>