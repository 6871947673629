<template>
    <ModalBasic id="preview-modal" :modalOpen="modalOpen" @close-modal="modalOpen = false, this.showData = false, this.documentLoadingSuccess = false" :title="'Preview Documento'" :size="'max-w-2xl'" :document-row="true">
        <!-- <PdfDocument v-if="this.showData && this.documentLoadingSuccess" :documentUrl="this.documentUrl" :isLoading="false"/>-->
        <div class="flex justify-center py-3">
        <iframe v-if="this.showData && this.documentLoadingSuccess" :src="this.documentUrl+ '#toolbar=1&navpanes=0'" width="100%" height="500px"  frameborder="0"></iframe> 
        <button v-else :disabled="true" type="submit" class="btn bg-indigo-500 hover:bg-indigo-600 text-white mt-2">
            <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
        </button>
        </div>
    </ModalBasic>
    <ModalBasic id="upload-modal" :modalOpen="modalUploadOpen" @close-modal="modalUploadOpen = false, this.file = null;
        this.selectedFileName = null; this.$refs.fileInput.value = null;" :title="'Subir Documento'" :size="'w-full md:w-1/2'">
        <form @submit.prevent.stop="saveD(todo.document)" class="flex justify-center py-3">
            <label class="btn bg-indigo-500 hover:bg-indigo-600 text-white mt-2 mr-2" >
                <p>{{ selectedFileName || 'Elegir archivo' }}</p>
                <input class="hidden" type="file" @change="handleFC" ref="fileInput">
            </label>
            <button :disabled="isLoading" type="submit" class="btn bg-indigo-500 hover:bg-indigo-600 text-white mt-2">
                <span v-if="!isLoading">{{ $t('buttons.save') }}</span>
                <span v-else>
                <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
                </span>
            </button>
        </form>
    </ModalBasic>
    <div class="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 p-4" draggable="true">
        <div class="sm:flex sm:justify-between sm:items-start">
            <!-- Left side -->
            <div class="grow mt-0.5 mb-3 sm:mb-0 space-y-3">
                <div class="flex items-center">
                    <ToolTip v-if="shouldShowToolTip" bg="dark" class="mr-2">
                        <div class="text-xs text-red-500 whitespace-nowrap">
                            Fecha Limite: {{ this.dueDate }}
                        </div>
                    </ToolTip>
                    <!-- Checkbox button -->
                    <label class="flex items-center">
                        <input type="checkbox" :disabled="(todo.status == 'DONE' && disableWhenDone) || blocked || disableCheck" :checked="todo.status == 'DONE'" class="form-checkbox w-5 h-5 rounded-full peer" @change="onChecked(todo)"/>
                        <span class="font-medium text-slate-800 dark:text-slate-100 peer-checked:line-through ml-2">{{todo.name}}</span>
                        <span v-if="blocked" class="font-medium text-slate-800 dark:text-slate-100 peer-checked:line-through ml-2">- Bloqueado</span>
                    </label>
                </div>
            </div>
            <!-- Right side -->
            <div class="flex items-center justify-end space-x-3">
            <!-- Avatars -->
                <div class="flex shrink-0 -space-x-3 -ml-px">
                    <div class="flex -space-x-3 -ml-0.5">
                        <AvatarLetter v-for="r in todo.rolesId" :key="r" :name="roles[roles.findIndex((e) => e.id === r)]?.name" :color="roles[roles.findIndex((e) => e.id === r)]?.color"/>
                        <AvatarLetter v-if="todo.rolesId == null || todo.rolesId.length == 0" :name="users[users.findIndex((e) => e.id === todo.userId)]?.username" :color="users[users.findIndex((e) => e.id === todo.userId)]?.color"/>
                    </div>
                </div>
                <!-- Date -->
                <div v-if="showAttachButton">
                    <div class="flex items-center" :class="color">
                        <svg class="w-4 h-4 shrink-0 fill-current mr-1.5" viewBox="0 0 16 16">
                        <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
                        </svg>
                        <div class="text-sm" :class="color">
                            {{eventDate}}
                        </div>
                        <!-- Attach button -->
                    <button :disabled="todo.status == 'DONE' || blocked || disableCheck" @click.prevent.stop="onPressed(this.id)" class="text-slate-400 dark:text-slate-500 hover:text-indigo-500 dark:hover:text-indigo-500 ml-2">
                        <svg class="w-4 h-4 shrink-0 fill-current mr-1.5" viewBox="0 0 16 16">
                        <path d="M11 0c1.3 0 2.6.5 3.5 1.5 1 .9 1.5 2.2 1.5 3.5 0 1.3-.5 2.6-1.4 3.5l-1.2 1.2c-.2.2-.5.3-.7.3-.2 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l1.1-1.2c.6-.5.9-1.3.9-2.1s-.3-1.6-.9-2.2C12 1.7 10 1.7 8.9 2.8L7.7 4c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l1.2-1.1C8.4.5 9.7 0 11 0zM8.3 12c.4-.4 1-.5 1.4-.1.4.4.4 1 0 1.4l-1.2 1.2C7.6 15.5 6.3 16 5 16c-1.3 0-2.6-.5-3.5-1.5C.5 13.6 0 12.3 0 11c0-1.3.5-2.6 1.5-3.5l1.1-1.2c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L2.9 8.9c-.6.5-.9 1.3-.9 2.1s.3 1.6.9 2.2c1.1 1.1 3.1 1.1 4.2 0L8.3 12zm1.1-6.8c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-4.2 4.2c-.2.2-.5.3-.7.3-.2 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l4.2-4.2z" />
                        </svg>
                    </button>
                    </div>
                </div>

                <!-- Autorize button -->
                <div v-if="authorization && todo.status == 'TODO'" class="flex items-center">
                    <button v-if="canAuthorize" @click.prevent.stop="validate({todo, action: 'authorizeStep'})"><font-awesome-icon :icon="['fas', 'check']" class="text-green-500 mr-2"/></button>
                    <button v-if="canAuthorize" @click.prevent.stop="reject({todo, action: 'rejectService'})"><font-awesome-icon :icon="['fas', 'xmark']" class="text-red-500 mr-2"/></button>
                    <div class="text-sm text-amber-600">
                        Pendiente de autorizar
                    </div>
                </div>
                
                <!-- Document button -->
                <div v-if="showDocumentbuttons">
                    <div class="flex justify-center gap-4 my-2">
                        <div class="pl-4 md:pl-0 text-sm text-slate-800 dark:text-slate-100 font-medium">
                            <p>{{ todo.document.documentExtension !== null && todo.document.documentExtension !== undefined ? `${todo.document.name}${todo.document.documentExtension}`: 'Elegir archivo' }}</p>
                        </div>
                        <button v-if="todo.status == 'TODO'" @click.stop="modalUploadOpen = true">
                            <span class="sr-only">Upload</span>
                            <font-awesome-icon class="h-4" :icon="['fas', 'upload']" />
                        </button>
                        <button 
                            :disabled="todo.document.documentExtension === null || todo.document.documentExtension === undefined || todo.document.documentExtension === ''" 
                            class="text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400 rounded-full disabled:opacity-35" 
                            @click.stop="previewD(todo.document), modalOpen = true">
                            <span class="sr-only">Preview</span>
                            <font-awesome-icon class="h-4" v-if="!isLoading" :icon="['fas', 'eye']" />
                            <font-awesome-icon class="h-4" v-else :icon="['fas', 'spinner']" spin />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { loadFromLocalStorage } from '@/utils/loadFromLocalStorage.js';
    import { save, handleFileChange, previewDocument, getDocument } from '@/utils/handleDocuments.js';
    
    import ToolTip from '@/components/ToolTip.vue';
    import ModalBasic from '@/components/ModalBasic.vue';
    import AvatarLetter from '@/components/AvatarLetter.vue'
    export default {
        name: 'ToDoRow',
        emits: ['checked:value', 'pressed:value', 'saveDocument'],
        components: {
            ToolTip,
            ModalBasic,
            AvatarLetter,
        },
        props:{
            canAuthorize: { type: Boolean, default: function(){ return false } },
            disableCheck: { type: Boolean, default: function(){ return false } },
            authorization: { type: Boolean, default: function(){ return false } },
            blocked: { type: Boolean, default: function(){ return false } },
            showAttachButton: { type: Boolean, default: function(){ return true } },
            showDocumentbuttons: { type: Boolean, default: function(){ return false } },
            disableWhenDone: { type: Boolean, default: function(){ return true } },
            id: String,
            todo: Object,
        },
        data(){
            var eventDate;
            var dueDate = '';
            var color;
            if (this.todo?.event?.eventDate !== null && this.todo?.event?.eventDate !== undefined){
                const date = new Date(this.todo.event.eventDate);
                eventDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${date.toLocaleTimeString([], { hour12: true, hour: 'numeric', minute: 'numeric' })}`
                color = 'text-green-500'
            } else if(this.todo.realizedDate !== null){
                eventDate = ''
            } else {
                eventDate = 'Pendiete Agendar'
                color = 'text-amber-600'
            }
            if (this.todo?.dueDate !== null && this.todo?.dueDate !== undefined){
                const date = new Date(this.todo.dueDate);
                dueDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${date.toLocaleTimeString([], { hour12: true, hour: 'numeric', minute: 'numeric' })}`
            }
            return {
                users: [],
                roles: [],
                dueDate,
                eventDate,
                color,
                modalOpen: false,
                modalUploadOpen: false,
                isLoading: false,
                file: null,
                selectedFileName: null,
                documentLoadingSuccess: false,
                showData: false,
                documentUrl: null,
            }
        },
        computed: {
            shouldShowToolTip() {
                const now = new Date();
                let formattedDueDate = this.dueDate.replace(' p.m.', ' PM').replace(' a.m.', ' AM');
                // Use Date.parse to convert the formatted string into a Date object
                const dueDate = new Date(Date.parse(formattedDueDate));
                // If the date is invalid, return false early
                if (isNaN(dueDate.getTime())) {
                    return false;
                }
                // Calculate the difference in milliseconds
                const timeDifference = dueDate - now;
                // Convert the time difference to hours
                const hoursDifference = timeDifference / (1000 * 60 * 60);
                return (hoursDifference <= 24 || hoursDifference < 0) && this.todo.status !== 'DONE';
            },
        },
        methods: {
            handleFC(event) {
                handleFileChange(event, this, this.todo.document.formats)
            },
            previewD(document) {    
                previewDocument(document, this)
            },
            async saveD(document) {
                save(document, this)
            },
            onChecked(todo){
                this.$emit('checked:value', todo);
            },
            onPressed(value){
                this.$emit('pressed:value', value);
            },
            validate(object){
                this.$emit('validate:value', object);
            },
            reject(object){
                this.$emit('reject:value', object);
            },
        },
        created(){
            Promise.all([
                loadFromLocalStorage('roles', 'roles', this),
                loadFromLocalStorage('users', 'users', this),
            ])
        }
    }
</script>