<template>
    <div class="col-span-full xl:col-span-6 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 min-h-80">
        <div class="px-4 py-4 relative">
            <div class="w-full grid grid-cols-12 gap-x-4 gap-y-8">

                <TasksGroups v-for="element in boardElements" :key="element.title" :title="element.title" :total="element.list.length">
                    <TodosTableItem
                        v-for="e in element.list"
                        :key="e.id"
                        :element="{todo: e}" @edit-todo:value="editTodo"
                    />
                </TasksGroups>
            </div>
        </div>
    </div>
</template>

<script>
    import TasksGroups from '@/partials/tasks/TasksGroups.vue';
    import TodosTableItem from '@/partials/prospects/detail/todos/TodosTableItem.vue';

    export default {
        name: 'TodosPanel',
        components: {
            TasksGroups,
            TodosTableItem,
        },
        props: {
            list_: {
                type: Array,
                default: function() {
                    return [];
                }
            }
        },
        data(){
            return {
                componentKey: 0,
                list: this.list_,
                selectedElement: null
            }
        },
        computed:{
            boardElements() {
                return [
                    {title: "Pendiente 📝", list: this.list.filter((element) => { return element.status == 'TODO' }).sort((a, b) => {
                        const dateA = a?.dueDate ? new Date(a.dueDate) : null;
                        const dateB = b?.dueDate ? new Date(b.dueDate) : null;

                        if (dateA === null) return 1; // place items with null dueDate at the end
                        if (dateB === null) return -1; // place items with null dueDate at the end
                        if (dateA === dateB) return 0;

                        return dateA - dateB;
                        })},
                    {title: "En Progreso ✌️", list: this.list.filter((element) => { return element.status == 'INPROGRESS' }).sort((a, b) => {
                        const dateA = a?.dueDate ? new Date(a.dueDate) : null;
                        const dateB = b?.dueDate ? new Date(b.dueDate) : null;

                        if (dateA === null) return 1; // place items with null dueDate at the end
                        if (dateB === null) return -1; // place items with null dueDate at the end
                        if (dateA === dateB) return 0;

                        return dateA - dateB;
                        })},
                    {title: "Completado 🎉", list: this.list.filter((element) => { return element.status == 'DONE' }).sort((a, b) => {
                        const dateA = a?.dueDate ? new Date(a.dueDate) : null;
                        const dateB = b?.dueDate ? new Date(b.dueDate) : null;

                        if (dateA === null) return 1; // place items with null dueDate at the end
                        if (dateB === null) return -1; // place items with null dueDate at the end
                        if (dateA === dateB) return 0;

                        return dateA - dateB;
                        })},
                ];
            }
        },
        methods: {
            selectProduct(e) {
                this.selectedElement = e;
                this.componentKey++;
            },
            editTodo(formData){
                this.$emit('edit-todo:value', formData)
            },
        }
    }
</script>