<template>
    <form @submit.prevent="addAdds">
        <div class="px-5 py-4">
            <div class="space-y-3">
                <div>
                    <label class="block text-sm font-medium mb-1" for="name">Nombre <span class="text-rose-500">*</span></label>
                    <input id="name" v-model="this.formData.name" class="form-input w-full px-2 py-1" type="text" required />
                </div>
            </div>
        </div>
        <!-- Modal footer -->
        <div class="px-5 py-4 border-t border-slate-200 dark:border-slate-700">
            <div class="flex flex-wrap justify-end space-x-2">
                <SubmitMainButton :is-loading="isLoading" :show-cancel-button="true" :click-handler="() => {feedbackModalOpen = false}" />
            </div>
        </div>
    </form>
        
    
</template>

<script>
    import SubmitMainButton from '@/components/buttons/SubmitMainButton.vue'

    import { postData } from '@/utils/postData.js';
    import { loadFromLocalStorage, setDataInStore } from '@/utils/loadFromLocalStorage.js';
    export default {
        name: 'AddsForm',
        props: {
            adds_: Array
        },
        components: {
            SubmitMainButton
        },
        data(){
            return {
                adds: this.adds_,
                formData: {
                    name: null
                },
                isLoading: false
            }
        },
        methods: {
            async addAdds() {
                const response = await postData('adds/', {
                    name: this.formData.name,
                    sourceId: this.$route.params.id
                }, this, true)
                await loadFromLocalStorage('adds', 'adds', this);
                this.adds.push(response)
                await setDataInStore('adds', this.adds)
                this.$emit('add-adds:value', {adds: this.adds, add: response})
            },
        }
    }
</script>