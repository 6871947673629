<template>
    <BasePage>
        <PageTitle :title="`Agregar Servicio ✨`" />
      
        <div class="max-w mx-auto w-full px-4 py-8">
            <form @submit.prevent="add">
                <FormComponent :key="componentKey"
                :can-edit="true"
                :products="this.products"
                :is-loading="this.isLoading"
                :init-categories-value="0"
                :categories_="this.categories"
                :form-data_="this.formData"
                @update:model="updateFormData"
                />
                <div class="grid grid-cols-1 sm:grid-cols-6 gap-2">
                    <div class="sm:col-span-3" > 
                        <FeesTable 
                        :title="'Comisiones'"
                        :title-modal="'Agregar Subcategoria de comision'"
                        :is-loading="isLoading" @update:model="updateFeesList"/>
                    </div>
                    <div class="sm:col-span-3" > 
                        <FeesTable 
                        :title="'Gastos'"
                        :title-modal="'Agregar Subcategoria de gasto'"
                        :is-loading="isLoading" @update:model="updateExpensesList"/>
                    </div>
                </div>
                <SubmitMainButton :is-loading="isLoading" />
            </form>
        </div>
    </BasePage>
  </template>
  
<script>
    import { postData } from '@/utils/postData.js';
    import { fetchData } from '@/utils/fetchData.js';
    import { loadFromLocalStorage, setDataInStore } from '@/utils/loadFromLocalStorage.js';
    
    import Swal from 'sweetalert2';
    import BasePage from '@/components/BasePage.vue';
    import PageTitle from '@/components/PageTitle.vue';
    import FeesTable from '@/partials/products/FeesTable.vue';
    import FormComponent from '@/partials/products/FormComponent.vue';
    import SubmitMainButton from '@/components/buttons/SubmitMainButton.vue'
  
  export default {
    name: 'CreateProductsPage',
    components: {
        BasePage,
        PageTitle,
        FeesTable,
        FormComponent,
        SubmitMainButton
    },
    data() {
        return {
            products: [],
            componentKey: 0,
            categories: [],
            isLoading: false,
            formData: {
                name: '',
                description: '',
                maximumAge: null,
                maximumAgeNA: true,
                minimumInfonavitPoints: null,
                minimumInfonavitPointsNA: true,
                maximumInfonavitPoints: null,
                maximumInfonavitPointsNA: true,
                minimumDiscountedWeeks: null,
                minimumDiscountedWeeksNA: true,
                maximumDiscountedWeeks: null,
                maximumDiscountedWeeksNA: true,
                maximumDaysInactive: null,
                maximumDaysInactiveNA: true,
                fractionMaximumQuotedWeeksInactive: null,
                fractionMaximumQuotedWeeksInactiveNA: true,
                maximumLastSalary: null,
                maximumLastSalaryNA: true,
                activeBefore1982: null,
                canVisualizeQuotedWeeksReport: null,
                priority: null,
                precandidateProductId: null,
                precandidate: false,
                minimumAge: null,
                minimumAgeNA: true,
                minimumBalance: null,
                minimumBalanceNA: true,
                maximumBalance: null,
                maximumBalanceNA: true,
                canHaveCredit: null,
                creditMustNotBeActive: null,
                mustHaveCredit: null,
                isActive: null,
                minimumWeeks: null,
                minimumWeeksNA: true,
                maximumWeeks: null,
                maximumWeeksNA: true,
                isLaw73: null,
                minimumDaysInactive: null,
                minimumDaysInactiveNA: true,
                category: null,
                canHaveCredit: null,
                excludeFromProspection: false,
                fee: [],
                expenses: []
            },
            
        };
    },
    methods: {
        updateFormData(updated){
            Object.assign(this.formData, updated);
        },
        updateFeesList(updated){
            this.formData.fee = updated;
        },
        updateExpensesList(updated){
            this.formData.expenses = updated;
        },
        async getData() {
            await fetchData(`categories`, 'categories', 'categories', this);
            if(this.categories.length > 0){
                this.formData.category = this.categories[0].name;
            }
            this.componentKey++
        },
        async add(product) {
            const response = await postData('products/', this.formData, this, true)
            if (!response) {
                return;
            };
            Swal.fire({
                icon: 'success',
                title: 'Exito!',
                text: 'El producto se a creado exitosamente.',
            });
            this.products.push(response)
            await setDataInStore('products', this.products)
            this.$router.push('/products');
        },
    },
    async created() {
        Promise.all([
            loadFromLocalStorage('products', 'products', this),
            loadFromLocalStorage('categories', 'categories', this),
        ]).then((results) =>{
            if(this.categories.length > 0){
                this.formData.category = this.categories[0].name;
            }

            this.getData();
            this.formData.fee = [{'name': 'consultor', 'fees': [{'name': 'Contado', 'amount': 10000, 'variable': false}]}, {'name': 'marketing', 'fees': [{'name': 'Contado', 'amount': 5000, 'variable': false}]}];
            this.formData.expenses = [{'name': 'consultor', 'fees': [{'name': 'Gasolina', 'amount': 200, 'variable': false}]}, {'name': 'marketing', 'fees': [{'name': 'Gasolina', 'amount': 100, 'variable': false}]}];
        })
    },
  }
  </script>