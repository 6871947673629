<template>
  <div class="space-y-8">
    <!-- Alert -->
    
    <!-- White box -->
    <div class="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 p-5 min-w-60">
      <div class="grid md:grid-cols-2 xl:grid-cols-1 gap-6">
        <!-- Group 1 -->
        <div>
          <div class="text-sm text-slate-800 dark:text-slate-100 font-semibold mb-3">Buscar</div>
          <form class="relative">
            <input id="action-search" class="form-input pl-9 bg-white dark:bg-slate-800" type="text" v-model="emailFilter" @input="updateEmail"/>
            <button class="absolute inset-0 right-auto group" type="submit" aria-label="Search">
            </button>
          </form>
        </div>
        <div>
          <div class="text-sm text-slate-800 dark:text-slate-100 font-semibold mb-3">Roles</div>
          <ul class="space-y-2">
            <label class="flex items-center">
              <input type="checkbox" :checked="selectAll" class="form-checkbox" @change="updateAll" />
              <span class="text-sm font-medium ml-2">Seleccionar todos</span>
            </label>
            <li>
              <label v-for="role in roles" :key="role" class="flex items-center">
                <input type="checkbox" :checked="model[role]" class="form-checkbox" @change="updateModel(role)" />
                <span class="text-sm text-slate-600 dark:text-slate-300 font-medium ml-2">{{role}}</span>
              </label>
            </li>
          </ul>
        </div>
        <!-- Group 2 -->
        
        
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'UsersSidebar',
  props: {
    roles: Array,
    model: Object,
  },
  data(){
    var o = this.roles
    return {
      roles_: o.sort(),
      selectAll: false,
      emailFilter: ''
    }
  },
  methods: {
    updateModel(option) {
      this.$emit('update:model', { ...this.model, [option]: !this.model[option] });
    },
    updateEmail(value) {
      this.$emit('update:email', this.emailFilter);
    },
    updateAll(){
      this.selectAll = !this.selectAll;
      let updated = this.model;
      for (let key in updated) {
        if (updated.hasOwnProperty(key)) {
          updated[key] = this.selectAll;
        }
        this.$emit('update:model', updated)
      }
    }
  },
}
</script>