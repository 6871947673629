<template>
    <BasePage>
      <span v-if="isLoading">
        <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
      </span>
      <span v-else>
        <div class="sm:flex sm:justify-between sm:items-center mb-8">
          <PageTitle :title="'Anuncios ✨'" />
          <div class="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2 items-center">
            <AddMainButton :title="'Agregar anuncio'" :clickHandler="() => {modalOpen = true}" />
          </div>
        </div>
        <GenericTable
            title="Anuncios"
            :items="adds"
            :columns="columns"
            :itemsPerPage="adds.length"
          >
          <template v-slot:table-rows="{ items }">
            <tr v-for="element in items" :key="element.id">
                <td class="px-2 first:pl-5 last:pr-5 py-3">{{ element.id }}</td>
              <td class="px-2 first:pl-5 last:pr-5 py-3">{{ element.name }}</td>
              <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center">
                <DeleteButton :clickHandler="() => deleteElement(element.id)" />
              </td>
            </tr>
          </template>
        </GenericTable>
        <ModalBasic id="modal" :modalOpen="modalOpen" @close-modal="modalOpen = false" title="Agregar Anuncio">
          <AddsForm :adds_="this.adds" @add-adds:value="add($event)" />
        </ModalBasic>
      </span>
    </BasePage>
</template>

<script>
    import { fetchData } from '@/utils/fetchData.js';
    import { deleteData } from '@/utils/deleteData.js';
    import { loadFromLocalStorage, setDataInStore } from '@/utils/loadFromLocalStorage.js';

    import BasePage from '@/components/BasePage.vue';
    import PageTitle from '@/components/PageTitle.vue';
    import ModalBasic from '@/components/ModalBasic.vue'
    import AddsForm from '@/partials/sources/AddsForm.vue'
    import GenericTable from '@/components/GenericTable.vue'
    import DeleteButton from '@/components/buttons/DeleteButton.vue'
    import AddMainButton from '@/components/buttons/AddMainButton.vue'
    export default {
        name: 'SourcesDetailPage',   
        components: {
            BasePage,
            AddsForm,
            PageTitle,
            ModalBasic,
            GenericTable,
            DeleteButton,
            AddMainButton,
        },
        data(){
            return {
                isLoading: false,
                modalOpen: false,
                adds: [],
                columns: [
                    { label: 'Id', key: 'id' },
                    { label: 'Nombre', key: 'name' },
                    { label: 'Actions', key: 'actions' }
                ],
            }
        },
        methods: {
            async deleteElement(id){
                const response = await deleteData('adds', id, this, 'El anuncio se a eliminado exitosamente.', true, true)
                if(!response){
                return
                }
                const updated = this.adds.filter((c) => c.id !== id);
                await setDataInStore('adds', updated)
                this.adds = this.adds.filter((c) => c.id !== id)
            },
            async add(element){
                this.adds = element.adds
                this.modalOpen = false
                await setDataInStore('adds', this.adds)
            }
        },
        async created(){
            await loadFromLocalStorage('adds', 'adds', this);
            fetchData(`adds`, 'adds', 'adds', this);
        }
    }
</script>