<template>
    <div class="space-y-4 grid gap-x-6 grid-cols-1 mb-4">
        <div v-for="(value, key) in inputConfig" :key="inputConfig[key]?.label" :class="inputConfig[key]?.show || false ? inputConfig[key].class : 'hidden'">
            <span v-if="inputConfig[key]?.show || false">
                <label class="block text-sm font-medium mb-1">
                    {{ $t(inputConfig[key]?.label || key) }}:
                </label>
                <input v-if="inputConfig[key].type !== 'options'"
                    :type="inputConfig[key].type"
                    :step="inputConfig[key].step"
                    :min="0"
                    :placeholder="inputConfig[key].placeholder"
                    class="form-input w-full"
                    v-model="formData[key]" :disabled="inputConfig[key].disabled"
                />
                <DropdownFull v-else-if="inputConfig[key].options !== undefined"
                :disable-inputs="inputConfig[key].disabled"
                :propname="inputConfig[key]?.propname"
                :dropdownOptions="inputConfig[key].options" 
                :init-value="inputConfig[key].options.findIndex(c => c[inputConfig[key].searchKey] == formData[key])"
                :model="formData[key]" @update:model="updateDropDown($event, key, inputConfig[key].searchKey)" />
            </span>
        </div>
        <div class="sm:col-span-12 mt-4" ></div>
    </div>
</template>

<script>
    import {states} from '@/utils/states.js';
    import {civilStates} from '@/utils/civilStates.js';
    import { loadFromLocalStorage } from '@/utils/loadFromLocalStorage.js';

    import DropdownFull from '@/components/DropdownFull.vue';
    
    export default {
        name: 'FormComponent',
        components: {
            DropdownFull,
        },
        props:{
            prospects: Array,
            isLoading: Boolean,
            initCategoriesValue: Number,
            categories_: Array,
            formData_: {
                type: Object,
                default: function() {
                    return {
                        name: '',
                    };
                }
            },
            disableInputs: {
                type: Boolean,
                default: function(){
                    return false
                }
            },
            modifyAgent: { type: Boolean, default: function(){ return false } },
            modifySource: { type: Boolean, default: function(){ return false } },
        },
        data() {
            return {
                civilStates: civilStates,
                states: states,
                sources: [],
                users: [],
                adds: [],
                categories: this.categories_,
                formData: this.formData_,
                inputConfig: {
                    name: { type: 'text', step: null, placeholder: 'john', label: 'labels.name', show: true, class: 'sm:col-span-6 mt-4', options: null, propname: null, searchKey: null, disabled: this.disableInputs },
                    agentId: { type: 'options', step: null, placeholder: null, label: 'labels.seller', show: true, class: 'sm:col-span-6', options: this.users, propname: 'username', searchKey: 'id', disabled: this.disableInputs ? this.disableInputs : !this.modifyAgent },
                    lastName: { type: 'text', step: null, placeholder: 'doe', label: 'labels.lastName', show: true, class: 'sm:col-span-6 mt-4', options: null, propname: null, searchKey: null, disabled: this.disableInputs },
                    sourceId: { type: 'options', step: null, placeholder: null, label: 'labels.source', show: true, class: 'sm:col-span-6', options: this.sources, propname: 'name', searchKey: 'id', disabled: this.disableInputs ? this.disableInputs : !this.modifySource },
                    phone: { type: 'tel', step: null, placeholder: '3323130044', label: 'labels.telephone', show: true, class: 'sm:col-span-4 mt-4', options: null, propname: null, searchKey: null, disabled: this.disableInputs },
                    email: { type: 'email', step: null, placeholder: 'john@doe.com', label: 'labels.email', show: true, class: 'sm:col-span-4 mt-4', options: null, propname: null, searchKey: null, disabled: this.disableInputs },
                    birthState: { type: 'options', step: null, placeholder: null, label: 'labels.birthState', show: true, class: 'sm:col-span-4 mt-4', options: this.states, propname: 'name', searchKey: 'name', disabled: this.disableInputs },
                    addressStreet: { type: 'text', step: null, placeholder: 'Calle', label: 'labels.street', show: true, class: 'sm:col-span-4 mt-4', options: null, propname: null, searchKey: null, disabled: this.disableInputs },
                    addressNumber: { type: 'text', step: null, placeholder: 'Numero', label: 'labels.number', show: true, class: 'sm:col-span-4 mt-4', options: null, propname: null, searchKey: null, disabled: this.disableInputs },
                    addressNeighborhood: { type: 'text', step: null, placeholder: 'Colonia', label: 'labels.neighborghood', show: true, class: 'sm:col-span-4 mt-4', options: null, propname: null, searchKey: null, disabled: this.disableInputs },
                    addressZipCode: { type: 'number', step: 1, placeholder: 'C.P.', label: 'labels.zipCode', show: true, class: 'sm:col-span-4 mt-4', options: null, propname: null, searchKey: null, disabled: this.disableInputs },
                    addressState: { type: 'options', step: null, placeholder: null, label: 'labels.state', show: true, class: 'sm:col-span-4 mt-4', options: this.states, propname: 'name', searchKey: 'name', disabled: this.disableInputs },
                    civilState: { type: 'options', step: null, placeholder: null, label: 'labels.civilState', show: true, class: 'sm:col-span-4 mt-4', options: this.civilStates, propname: 'name', searchKey: 'name', disabled: this.disableInputs },
                    platform: { type: 'text', step: null, placeholder: 'Plataforma', label: 'labels.platform', show: true, class: 'sm:col-span-4 mt-4', options: null, propname: null, searchKey: null, disabled: this.disableInputs ? this.disableInputs : !this.modifySource },
                    desiredProduct: { type: 'text', step: null, placeholder: 'Producto deseado', label: 'labels.desiredProduct', show: true, class: 'sm:col-span-4 mt-4', options: null, propname: null, searchKey: null, disabled: this.disableInputs ? this.disableInputs : !this.modifySource },
                    adName: { type: 'text', step: null, placeholder: 'Anuncio', label: 'labels.advertisement', show: true, class: 'sm:col-span-4 mt-4', options: null, propname: null, searchKey: null, disabled: this.disableInputs ? this.disableInputs : !this.modifySource },
                }
            };
        },
        methods: {
            updateDropDown(updated, key, searchKey) {
                this.formData[key] = updated[searchKey];
                this.$emit('update:model', this.formData);
            },
        },
        created(){
            Promise.all([
                loadFromLocalStorage('users', 'users', this),
                loadFromLocalStorage('sources', 'sources', this),
                loadFromLocalStorage('adds', 'adds', this),
            ]).then((results) =>{
                this.inputConfig['agentId'].options = this.users
                this.inputConfig['sourceId'].options = this.sources
                this.inputConfig['birthState'].options = this.states
                this.inputConfig['addressState'].options = this.states
                this.inputConfig['civilState'].options = this.civilStates
            })
        }
    }
</script>